<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <button type="button" class="btn btn-primary" @click="addNewTarget">Add New Template</button>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            <template #cell(image)="row">
              <img :src="userPhoto(row.item.image)" alt="" class="img-fluid user-image">
            </template>
            <template #cell(is_active)="row">
              <b-badge v-if="row.item.is_active==1" variant="success">Active</b-badge>
              <b-badge v-else variant="danger">In active</b-badge>

            </template>
            <template #cell(action)="row">
              <div>
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <div>
                    <b-dropdown-item href="#" @click="editAction(row.item)">
                      <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <b-modal
          id="addTarget"
          ref="modal"
          :title="`${this.form.id ? 'Update' : 'Add new'} Template`"
          @hidden="resetModal"
          @ok="handleOk"
          size="lg">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="6" class="my-5">
              <label>Title</label>
              <b-form-input v-model="form.title"></b-form-input>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Order</label>
              <b-form-input v-model="form.order"></b-form-input>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Select status</label>
              <b-form-select v-model="form.is_active" :options="status"></b-form-select>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Upload image</label>
              <b-form-group class="" invalid-feedback="Bank image is required">
                <b-form-file
                    class=""
                    ref="image"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    required
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <label>URL</label>
              <b-form-input v-model="form.url"></b-form-input>
            </b-col>
            <b-col cols="6">
              <label>Template type</label>
              <b-form-select v-model="form.type" :options="types"></b-form-select>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "CenterTemplate",
  title: 'Center Template',
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'image',
          label: 'Image',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'order',
          label: 'Order',
        },
        {
          key: 'url',
          label: 'URL',
        },
        {
          key: 'is_active',
          label: 'Is Active',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      loading: false,
      form: {
        id: '',
        is_active: '',
        order: '',
        title: '',
        url: '',
        template_id: '',
        type: '',
      },
      status: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'In active',
          value: 0,
        },
      ],
      types: [
        {
          text: 'About',
          value: 'about',
        },
        {
          text: 'Physio',
          value: 'physio',
        },
        {
          text: 'CF',
          value: 'cf',
        },
        {
          text: 'Both',
          value: 'both',
        },
      ],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "OCC Center Template", route: "/occ/center/template"},
    ]);
  },
  created() {
    this.getItems();
    localStorage.setItem('tabIndex', 7)
    this.$root.$emit('call-active-menu', 7);
  },
  methods: {
    getItems() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/orko/center/templates?api_token=${localStorage.getItem('api_token')}`).then(res => {
        this.items = res.data.data;
        this.loading = false;
      })
    },
    addNewTarget() {
      this.$bvModal.show('addTarget')
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return '../../../public/media/users/blank.png'
      }
    },
    editAction(item) {
      console.log('template-id', item)
      this.form = item;
      this.form.template_id = item.id;
      this.$bvModal.show('addTarget')
    },
    resetModal() {
      this.form = {
        id: '',
        is_active: 1,
        order: '',
        title: '',
        template_id: '',
        url: '',
      };
      this.getItems();
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      // let sales_user_name = this.sales_users.find(item => item.id == this.form.user_id);
      // this.form.user_name = sales_user_name.name;
      let image = this.$refs.image.files[0];
      let form = new FormData();

      form.append('title', this.form.title ? this.form.title : '');
      form.append('is_active', this.form.is_active);
      form.append('url', this.form.url ? this.form.url : '');
      form.append('type', this.form.type ? this.form.type : '');
      if (image) {
        form.append('file', image);
      }

      form.append('order', this.form.order);
      if (this.form.template_id) {
        form.append('template_id', this.form.template_id)
      }
      this.$nextTick(() => {
        let url = `${process.env.VUE_APP_API_URL}/api/v2/orko/center/template/manipulate?api_token=${localStorage.getItem('api_token')}`;

        axios.post(url, form).then(res => {
          if (res.data.status_code == 200) {
            this.getItems();
            this.$bvModal.hide('addTarget')
            this.$snotify.success('Successfully saved');
          } else {
            this.$snotify.error(res.data.message);
          }
        })
      })
    },
  }
}
</script>

<style scoped>
.user-image {
  height: 100px;
}
</style>
