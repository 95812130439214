<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <div class="row mt-5 align-items-center justify-content-between">
        <div class="col-md-2">
          <label for="">User Type</label>
          <select class="form-control" v-model="filter.user_type" @change="getItems()">
            <option value="">All</option>
            <option value="doctor">Doctor</option>
            <option value="employee">Employee</option>
          </select>
        </div>
        <div class="col-md-3">
          <label for="">Phone number</label>
          <input type="number" v-model="filter.phone_number" class="form-control">
        </div>
        <div class="col-md-2">
          <label for="">From</label>
          <input type="date" v-model="filter.start_date" class="form-control">
        </div>
        <div class="col-md-2">
          <label for="">To</label>
          <input type="date" v-model="filter.end_date" class="form-control">
        </div>
        <div class="col-md-3 mt-7">
          <button class="btn btn-success" @click="getItems()">Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            <template #cell(user)="row">
              {{ row.item.user ? row.item.user.fullname : '' }}
            </template>
            <template #cell(phone_number)="row">
              {{ row.item.user ? row.item.user.phone_number : '' }}
            </template>
            <template #cell(action)="row">
              <a :href="`/employee/${row.item.user_id}/referral-patients?${filter.start_date ? 'start_date='+filter.start_date : ''}${filter.end_date ? '&end_date=' + filter.end_date : ''}`"
                 target="_blank">View patients</a>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              class="my-0"
              @change="getItems"
          ></b-pagination>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "EmployeeReferralList",
  data() {
    return {
      loading: false,
      filter: {
        start_date: null,
        end_date: null,
        phone_number: '',
        user_type:''
      },
      items: [],
      fields: [
        {
          key: 'user',
          label: 'Fullname',
        },
        {
          key: 'total_patient',
          label: 'Total patient',
        },
        {
          key: 'total_referral_amount',
          label: 'Total referral amount',
        },
        {
          key: 'total_session',
          label: 'Total Session',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      total_count: 0,
      perPage: 20,
      currentPage: 1,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Employee wise referral list", route: "/employee-referral-list"},
    ]);
  },
  created() {
    this.getItems();
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
  },
  methods: {
    setQueryParams() {
      let params = {};
      if (this.filter.start_date) {
        params.start_date = this.filter.start_date;
      }
      if (this.filter.end_date) {
        params.end_date = this.filter.end_date;
      }
      return params;
    },
    getItems(value) {
      this.loading = true;
      if (Number(value)) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }


      // let url = `${process.env.VUE_APP_URL}/api/v2/sales/team/sales/target/list?api_token=${localStorage.getItem('api_token')}${this.tsm_id ? '&user_id='+this.tsm_id : ''}${this.month ? '&month='+this.month : ''}${this.year ? '&year='+this.year : ''}${this.doctor_type ? '&doctor_type='+this.doctor_type : ''}`;
      let url = `${process.env.VUE_APP_API_URL}/api/v2/employee/referrals?api_token=${localStorage.getItem('api_token')}
      ${this.filter.start_date ? '&start_date=' + this.filter.start_date : ''}
      ${this.filter.end_date ? '&end_date=' + this.filter.end_date : ''}
      ${this.filter.phone_number ? '&phone_number=' + this.filter.phone_number : ''}
      ${this.filter.user_type ? '&user_type=' + this.filter.user_type : ''}
      &limit=20&offset=${correctValue ? correctValue : 0}`
      this.$router.replace({
        query: this.setQueryParams()
      })
      axios.get(url).then(res => {
        this.items = res.data.data;
        this.total_count = res.data.total_count;
        this.loading = false;
      })
    },
    clear() {
      this.filter = {};
      this.getItems();
    }
  }
}
</script>

<style scoped>

</style>
