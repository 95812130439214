<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <div class="row mt-5 align-items-center justify-content-between">
        <div class="col-md-2">
          <label for="">Select center</label>
          <v-select
              :options="centers"
              :reduce="centers => centers.id"
              v-model="filter.institute_id"
              label="name"
              class="h-100"
              name="id"
              placeholder="Select center"
              required>
          </v-select>
        </div>
        <div class="col-md-2">
          <label for="">Phone number</label>
          <input type="number" v-model="filter.phone_number" class="form-control">
        </div>
        <div class="col-md-2">
          <label for="">Invoice ID</label>
          <input v-model="filter.uid" class="form-control">
        </div>
        <div class="col-md-2">
          <label for="">From</label>
          <input type="date" v-model="filter.start_date" class="form-control">
        </div>
        <div class="col-md-2">
          <label for="">To</label>
          <input type="date" v-model="filter.end_date" class="form-control">
        </div>
        <div class="col-md-2 mt-7">
          <button class="btn btn-success" @click="getItems()">Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            <template #cell(patient)="row">
              <div v-if="row.item.patient">
                <p class="mb-0 mt-0"> {{ row.item.patient.fullname }}</p>
                <p class="mb-0 mt-0"> {{ row.item.patient.phone_number }}</p>
              </div>
            </template>
            <template #cell(status)="row">
              <div>
                <b-badge variant="success" v-if="row.item.status== 'paid'">Paid</b-badge>
                <b-badge variant="danger" v-else>Unpaid</b-badge>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                          class="m-2">
                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <div>
                  <b-dropdown-item href="#" @click="deleteAction(row.item)">Delete Invoice</b-dropdown-item>
                </div>
              </b-dropdown>
            </template>

          </b-table>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              class="my-0"
              @change="getItems"
          ></b-pagination>
        </div>
      </div>
    </div>
    <b-modal id="deleteInvoice" size="lg" title="Delete Invoice" hide-footer>
      <b-form @submit="deletedInvoice" @reset="onReset">

        <b-form-group
            label="Delete Reasion">

          <b-form-textarea
              id="textarea"
              class="mb-2"
              v-model="form.deleted_reason"
              placeholder="Enter something..."
              rows="3"
          ></b-form-textarea>
          <span class="text-danger"><b>Note: </b>Provide a valid reason/comment here. No dummy or irrelevant content will be acceptable</span>
        </b-form-group>


        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export default {
  name: "InvoiceList",
  data() {
    return {
      loading: false,
      filter: {
        start_date: null,
        end_date: null,
        phone_number: '',
        uid: '',
        institute_id: '',
      },
      items: [],
      fields: [
        {
          key: 'patient',
          label: 'Patient',
        },
        {
          key: 'uid',
          label: 'Invoice No',
        },
        {
          key: 'product_type',
          label: 'Service Type',
        },
        {
          key: 'subtotal',
          label: 'Subtotal',
        },
        {
          key: 'discount_percentage',
          label: 'Discount',
        },
        {
          key: 'discount_reason',
          label: 'Discount Reason',
        },
        {
          key: 'total',
          label: 'Total',
        },
        {
          key: 'status',
          label: 'Payment Status',
        },
        {
          key: 'invoice_date',
          label: 'Invoice Date',
        },
        {
          key: 'actions',
          label: 'Action',
        },
      ],
      total_count: 0,
      perPage: 20,
      currentPage: 1,
      centers: [],
      form: {
        deleted_reason: '',
      },
      invoice_id: '',
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Invoice list", route: "/report/invoice/list"},
    ]);
  },
  created() {
    this.getItems();
    this.centerList();
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
  },
  methods: {
    getItems(value) {
      this.loading = true;
      if (Number(value)) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/invoices/backoffice?api_token=${localStorage.getItem('api_token')}${this.filter.start_date ? '&start_date=' + this.filter.start_date : ''}${this.filter.end_date ? '&end_date=' + this.filter.end_date : ''}${this.filter.phone_number ? '&phone_number=' + this.filter.phone_number : ''}${this.filter.uid ? '&uid=' + this.filter.uid : ''}${this.filter.institute_id ? '&institute_id=' + this.filter.institute_id : ''}&limit=20&offset=${correctValue ? correctValue : 0}`).then(res => {
        this.items = res.data.data;
        this.total_count = res.data.total_count;
        this.loading = false;
      })
    },
    clear() {
      this.filter = {};
      this.getItems();
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              // this.loading = false
              this.centers = response.data.data
            }
          })
    },

    deleteAction(item) {
      this.invoice_id = item.id;
      this.$bvModal.show('deleteInvoice')
    },
    onReset(event) {
      event.preventDefault()
      this.form = {};
      this.invoice_id = '';
    },
    deletedInvoice(event) {
      event.preventDefault()
      let form = new FormData();
      form.append('deleted_reason', this.form.deleted_reason);
      axios
          .post(`${process.env.VUE_APP_URL}/api/v2/invoices/${this.invoice_id}?api_token=${localStorage.getItem('api_token')}&_method=delete`, form)
          .then(response => {
            if (response.data.status_code == 400) {
              return this.$snotify.error('Something worng');
            }
            this.getItems();
            this.$bvModal.hide('deleteInvoice')
            this.form = '';
            this.$snotify.success('Invoice successfully detelted.');
            // this.getUser();

          })
    }
  },
}
</script>

<style scoped>

</style>
