<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-4">
        <label for="">Select center</label>
        <v-select
            :options="centers"
            :reduce="centers => centers.id"
            v-model="institute_id"
            label="name"
            class="h-100"
            name="id"
            placeholder="Select center"
            required>
        </v-select>
      </div>
      <div class="col-md-3">
        <label for="">From</label>
        <input type="date" v-model="start_date" class="form-control">
      </div>
      <div class="col-md-3">
        <label for="">To</label>
        <input type="date" v-model="end_date" class="form-control">
      </div>
      <div class="col-md-1 mt-8 text-center">
        <button type="button" class="btn btn-info" @click="getDashboardCount">Filter</button>
      </div>
      <div class="col-md-1 mt-8">
        <button type="button" class="btn btn-danger mx-5" @click="clear">Clear</button>
      </div>
    </div>
    <div class="row mt-10">
      <div class="col-md-12">
        <h3>Patient's Count</h3>
      </div>
      <div class="col-md-4 mb-3">
        <router-link
            :to="`/center-wise-patient-list?type=old_patient&institute_id=${institute_id ? institute_id : ''}&start_date=${start_date ? start_date : ''}&end_date=${end_date ? end_date : ''}`">
          <div class="card border-0">
            <div class="card-body text-center">
              <ValueWithSubTitle sub_title="Old Patient"/>
              <h1 class="text-center">{{ dashboard.total_old_patient }}</h1>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link
            :to="`/center-wise-patient-list?type=new_patient&institute_id=${institute_id ? institute_id : ''}&start_date=${start_date ?start_date : ''}&end_date=${end_date ? end_date : ''}`">
          <div class="card border-0">
            <div class="card-body text-center">
              <ValueWithSubTitle sub_title="New Patient"/>
              <h1 class="text-center">{{ dashboard.total_new_patient }}</h1>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link
            :to="`/center-wise-patient-list?type=cf_patient&institute_id=${institute_id ? institute_id : ''}&start_date=${start_date ? start_date : ''}&end_date=${end_date ? end_date : ''}`">
          <div class="card border-0">
            <div class="card-body text-center">
              <ValueWithSubTitle sub_title="Club Foot Patient"/>
              <h1 class="text-center">{{ dashboard.total_cf_patient }}</h1>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row mt-10">
      <div class="col-md-12">
        <h3>Service Wise Income</h3>
      </div>
      <div class="col-md-3 mb-3" v-for="(service,index) in dashboard.service_wise_count" :key="index">
        <router-link :to="`/service-wise-report?product_type=${service.product_type}&institute_id=${institute_id ? institute_id : ''}&start_date=${start_date ? start_date : ''}&end_date=${end_date ? end_date : ''}`">
          <div class="card border-0">
            <div class="card-body text-center">
              <ValueWithSubTitle class="text-transform-capitalize"
                                 :sub_title="`${underScoreToSpace(service.product_type == 'appointment' ? 'Consultancy' : service.product_type )}`"/>
              <h3>{{ numberFormat(service.total_income) }}</h3>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row mt-10">
      <div class="col-md-12">
        <h3>Service Wise Total Patient</h3>
      </div>
      <div class="col-md-3 mb-3" v-for="(service,index) in dashboard.service_wise_count" :key="index">
        <router-link :to="`/service-wise-report?product_type=${service.product_type}&institute_id=${institute_id ? institute_id : ''}&start_date=${start_date ? start_date : ''}&end_date=${end_date ? end_date : ''}`">
          <div class="card border-0">
            <div class="card-body text-center">
              <ValueWithSubTitle class="text-transform-capitalize"
                                 :sub_title="`${underScoreToSpace(service.product_type == 'appointment' ? 'Consultancy' : service.product_type)}`"/>
              <h3>{{ service.patient_count }}</h3>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import ValueWithSubTitle from "../reusable-component/ValueWithSubTitle";
import {underScoreToSpace} from "../../healper/nameFormating";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Dashboard",
  components: {
    ValueWithSubTitle,
  },
  data() {
    return {
      institute_id: '',
      start_date: null,
      end_date: null,
      centers: [],
      dashboard: {
        total_old_patient: 0,
        total_new_patient: 0,
        total_cf_patient: 0,
        service_wise_count: [],
      },
      loading: false,
    }
  },
  created() {
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
    this.centerList();
    this.getDashboardCount();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Center report", route: "/center-wise-dashboard"},
    ]);
  },
  methods: {
    underScoreToSpace,
    getDashboardCount() {
      this.loading = true;
      const url = `${process.env.VUE_APP_URL}/api/v2/report/orkocenter/dashboard/counts?api_token=${localStorage.getItem('api_token')}${this.institute_id ? '&institute_id=' + this.institute_id : ''}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`
      // this.$router.replace({
      //   query: this.setQueryParams()
      // })
      axios.get(url).then(res => {
        if (res.data.status_code == 200) {
          this.dashboard = res.data.data;
          this.loading = false;
        } else {
          this.$snotify.error(res.data.message);
        }
      })
    },
    numberFormat(num) {
      return num?(Math.round(num * 100) / 100).toFixed(2):0;
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.loading = false
              this.centers = response.data.data
            }
          })
    },
    clear() {
      this.institute_id = '';
      this.start_date = null;
      this.end_date = null;
      this.getDashboardCount();
    },
    setQueryParams() {
      let params = {};
      if (this.institute_id) {
        params.institute_id = this.institute_id;
      }
      if (this.start_date) {
        params.start_date = this.start_date;
      }
      if (this.end_date) {
        params.end_date = this.end_date;
      }
      return params;
    },
  }
}
</script>

<style scoped>
.text-transform-capitalize {
  text-transform: capitalize;
}

</style>
