<template>
  <div class="row mt-60">
    <loader-component v-if="loading"/>
    <div class="col-md-4">
      <div class="c-card" v-if="!is_show_patient">
        <div class="filter-section">
          <form>
            <ul class="filter-list">
              <li>
                <b-button @click="selectFilter('Month')" class="coll-btn"
                >{{ filter.quarter ? 'Q' + filter.quarter : filter.month ? filter.month : 'Month' }} {{ getYearLabel(filter.year) }} <i
                    class="fas fa-chevron-down"></i></b-button
                >

                <!-- Element to collapse -->
              </li>
              <li>
                <b-button @click="selectFilter('Center')" class="coll-btn"
                >{{ filter.center || 'All Center' }} <i class="fas fa-chevron-down"></i></b-button
                >
              </li>
            </ul>

            <b-collapse id="collapse-1" v-model="showMonthFilter">
              <ul class="month-selection-list">
                <li v-for="(item, key) in months" :key="key">
                  <a v-if="!['Q1', 'Q2', 'Q3', 'Q4'].includes(item.text)" href="javascript:void(0)"
                     :class="item.text == filter.month?'active':''"
                     @click="selectPeriod('monthly',item.text); showMonthFilter=false; filterReport()">{{ item.text }}</a>
                  <a v-else href="javascript:void(0)" :class="item.value == filter.quarter?'active':''"
                     @click="selectPeriod('quarterly',item.value); showMonthFilter=false; filterReport()">{{
                      item.text
                    }}</a>

                  <!-- <a href="javascript:void(0)" :class="item.text == filter.month?'active':''"
                     @click="filter.month = item.text; showMonthFilter=false; getReports()">{{ item.text }}</a> -->
                </li>
              </ul>
              <ul class="year-selection-list">
                <li>
                  <a
                    href="javascript:void(0)"
                    :class="filter.year == year ? 'active' : ''"
                    @click="
                      filter.year = year;
                      filterReport();
                    "
                    v-for="(year, index) in years"
                    :key="index"
                    >{{ year }}</a
                  >
                </li>
              </ul>
            </b-collapse>

            <b-collapse id="collapse-2" v-model="showCenterFilter">
              <ul class="center-selection-list">
                <li>
                  <a href="javascript:void(0)" :class="filter.center==''?'active':''"
                     @click="filter.center = ''; showCenterFilter=false; filterReport()">All</a>
                </li>
                <li v-for="(item, key) in centers" :key="key">
                  <a href="javascript:void(0)" :class="item.short_form == filter.center?'active':''"
                     @click="filter.center = item.short_form; showCenterFilter=false; filterReport()">{{
                      item.short_form
                    }}</a>
                </li>
              </ul>
            </b-collapse>

          </form>
        </div>
        <div class="target-content">
              <ul>
                <li>
                  <div>
                    <div class="l-label">
                      <label>Registration</label>
                    </div>
                    <div class="r-label">
                      <span class="achieved">{{ getRegTotalBySource() }}</span> /
                      <span class="target">{{ getTarget('patient_registration')}} </span>
                    </div>
                    <b-progress
                        :max="max"
                        height="25px"
                        :variant="calculateProgress(getRegTotalBySource(), getTarget('patient_registration'))>= 100?'success':'warning'"
                        :precision="0"
                        show-progress
                        class="mb-3 brd-15 ftb-15 w-100"
                    >
                      <b-progress-bar :value="calculateProgress(getRegTotalBySource(), getTarget('patient_registration'))">
                        <span>
                          <strong>{{ calculateProgress(getRegTotalBySource(), getTarget('patient_registration')) }}%</strong></span
                        >
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </li>
                <li>
                  <div>
                    <div class="l-label">
                      <label>Sources of Reg.</label>
                    </div>
                    <div class="r-label">
                      <span class="achieved">{{ getRegTotalBySource() }}</span>
                    </div>
                    <b-progress
                        :max="getRegTotalBySource()"
                        height="25px"
                        variant="warning"
                        :precision="0"
                        class="mb-3 brd-15 ftb-15 w-100"
                      show-value
                    >
                      <b-progress-bar :value="getRegCountBySource('Poster')" variant="warning"></b-progress-bar>
                      <b-progress-bar :value="getRegCountBySource('Social')" class="custom_market_activation_bg text-dark"></b-progress-bar>
                      <b-progress-bar :value="getRegCountBySource('Doctor Referral')" class="corporate_engangement_bg text-dark"></b-progress-bar>
                      <b-progress-bar :value="getRegCountBySource('Patient Referral')" class="pt_rf_bg text-dark"></b-progress-bar>
                      <b-progress-bar :value="getRegCountBySource('Employee Referral')" class="em_rf_bg text-dark"></b-progress-bar>
                      <b-progress-bar :value="getRegCountBySource('register')" class="app_bg"></b-progress-bar>
                      <b-progress-bar :value="getRegCountBySource('Relapse Patient')" class="re_pt_bg text-dark"></b-progress-bar>
                      <b-progress-bar :value="getRegCountBySource('Others')" class="others_bg"></b-progress-bar>
  
                    </b-progress>
                    
                    <div class="progress_bar_bullet">
                      <p><span class="doctor_bullet"></span>Sticker ({{ getRegCountBySource('Poster') }})</p>
                      <p><span class="market_activation_bullet"></span>Social ({{ getRegCountBySource('Social') }})</p>
                      <p><span class="corporate_engagement_bullet"></span>Doctor Refer ({{ getRegCountBySource('Doctor Referral') }})</p>
                      <p><span class="pt_rf_bullet"></span>Patient Refer ({{ getRegCountBySource('Patient Referral') }})</p>
                      <p><span class="em_rf_bullet"></span>Employee Refer ({{ getRegCountBySource('Employee Referral') }})</p>
                      <p><span class="app_bullet"></span>App ({{ getRegCountBySource('register') }})</p>
                      <p><span class="re_pt_bullet"></span>Relapse Pt. ({{ getRegCountBySource('Relapse Patient') }})</p>
                      <p><span class="others_bullet"></span>Others ({{ getRegCountBySource('Others') }})</p>
                      
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div class="l-label">
                      <label>Appointments</label>
                    </div>
                    <div class="r-label">
                      <span class="achieved">{{ patient_summery_report.total_appointment }}</span> /
                      <span class="target"> {{  getTargetclubfootAppointment('appointment') }} </span>
                    </div>
                    <b-progress
                        :max="max"
                        height="25px"
                        :variant="calculateProgress(patient_summery_report.total_appointment, getTargetclubfootAppointment('appointment'))>= 100?'success':'warning'"
                        :precision="0"
                        show-progress
                        class="mb-3 brd-15 ftb-15 w-100"
                    >
                      <b-progress-bar :value="calculateProgress(patient_summery_report.total_appointment, getTargetclubfootAppointment('appointment'))">
                        <span>
                          <strong>{{ calculateProgress(patient_summery_report.total_appointment, getTargetclubfootAppointment('appointment')) }}%</strong></span
                        >
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </li>
                <li>
                  <div>
                    <div class="l-label">
                      <label>Consultations</label>
                    </div>
                    <div class="r-label">
                      <span class="achieved">{{ patient_summery_report.total_consultation }}</span> /
                      <span class="target">{{ getTargetclubfootAppointment('consultation') }} </span>
                    </div>
                    <b-progress
                        :max="max"
                        height="25px"
                        :variant="calculateProgress(patient_summery_report.total_consultation, getTargetclubfootAppointment('consultation'))>= 100?'success':'warning'"
                        :precision="0"
                        show-progress
                        class="mb-3 brd-15 ftb-15 w-100"
                    >
                      <b-progress-bar :value="calculateProgress(patient_summery_report.total_consultation, getTargetclubfootAppointment('consultation'))">
                        <span>
                          <strong>{{ calculateProgress(patient_summery_report.total_consultation, getTargetclubfootAppointment('consultation')) }}%</strong></span
                        >
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </li>
                <li>
                  <div>
                    <div class="l-label">
                      <label>Sessions</label>
                    </div>
                    <div class="r-label">
                      <span class="achieved">{{ patient_summery_report.total_session }}</span> /
                      <span class="target">{{ getTarget('therapy_session')}} </span>
                    </div>
                    <b-progress
                        :max="max"
                        height="25px"
                        :variant="calculateProgress(patient_summery_report.total_session, getTarget('therapy_session'))>= 100?'success':'warning'"
                        :precision="0"
                        show-progress
                        class="mb-3 brd-15 ftb-15 w-100"
                    >
                      <b-progress-bar :value="calculateProgress(patient_summery_report.total_session, getTarget('therapy_session'))">
                        <span>
                          <strong>{{ calculateProgress(patient_summery_report.total_session, getTarget('therapy_session')) }}%</strong></span
                        >
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </li>
                <li>
                  <div>
                    <div class="l-label">
                      <label>Procedures</label>
                    </div>
                    <div class="r-label">
                      <span class="achieved">{{ patient_summery_report.total_procedure }}</span> /
                      <span class="target">{{ getTarget('Dental')}} </span>
                    </div>
                    <b-progress
                        :max="max"
                        height="25px"
                        :variant="calculateProgress(patient_summery_report.total_procedure, getTarget('Dental'))>= 100?'success':'warning'"
                        :precision="0"
                        show-progress
                        class="mb-3 brd-15 ftb-15 w-100"
                    >
                      <b-progress-bar :value="calculateProgress(patient_summery_report.total_procedure, getTarget('Dental'))">
                        <span>
                          <strong>{{ calculateProgress(patient_summery_report.total_procedure, getTarget('Dental')) }}%</strong></span
                        >
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </li>
              </ul>
              
              <!-- <h3 class="content-title">Conversion rate (consultation)</h3>
              
              <div class="row">
                <div class="col-6">
                  <p class="conversion_calculate">
                    <span> Reg. to <br/> consult </span>
                      <span class="reg_to_consult">
                        {{  patient_summery_report.total_therapy_registration?calculatePercentage( patient_summery_report.total_therapy_consultation, patient_summery_report.total_therapy_registration):0 }} %
                      </span>
                    </p>
                    <p>
                      ({{ patient_summery_report.total_therapy_registration }} - {{ patient_summery_report.total_therapy_consultation }})
                    </p>
                </div>
                <div class="col-6">
                  <p class="conversion_calculate">
                    <span>Consult <br/> to  Session</span>
                    <span class="reg_to_consult">{{  patient_summery_report.total_therapy_consultation?calculatePercentage(patient_summery_report.total_session, patient_summery_report.total_therapy_consultation):0 }}%</span>
                  </p>
                  <p>
                      ({{ patient_summery_report.total_therapy_consultation }} - {{ patient_summery_report.total_session }})
                    </p>
                </div>
              </div>
              
             
            
            <br/> -->
            <h3 class="content-title">PHT Unique Count</h3>
            <div class="row mb-5">
              <div class="col-12">
                  <p class="conversion_calculate">
                    <span>Consultation (Patient)</span>
                    <span class="reg_to_consult">{{ patient_summery_report.total_unique_therapy_consultation }}</span>
                    </p>
                </div>
                <div class="col-12">
                  <p class="conversion_calculate">
                    <span>Session (Patient)</span> 
                    <span class="reg_to_consult">{{  patient_summery_report.total_unique_session }}</span>
                  </p>
                </div>
            </div>

            <h3 class="content-title">PHT Conversion rate</h3>
              
              <div class="row">
                <div class="col-6">
                  <p class="conversion_calculate">
                    <span>Reg. to <br/> consult</span>
                    <span class="reg_to_consult">{{  patient_summery_report.total_therapy_registration?calculatePercentage( patient_summery_report.total_reg_to_therapy_consultation, patient_summery_report.total_therapy_registration):0 }} %</span>
                    </p>
                    <p>
                      ({{ patient_summery_report.total_therapy_registration }} - {{ patient_summery_report.total_reg_to_therapy_consultation }})
                    </p>
                </div>
                <div class="col-6">
                  <p class="conversion_calculate">
                    <span>Consult <br/> to  Session</span> 
                    <span class="reg_to_consult">{{  patient_summery_report.total_reg_to_therapy_consultation?calculatePercentage(patient_summery_report.total_therapy_consultation_to_session, patient_summery_report.total_reg_to_therapy_consultation):0 }}%</span>
                  </p>
                  <p>
                      ({{ patient_summery_report.total_reg_to_therapy_consultation }} - {{ patient_summery_report.total_therapy_consultation_to_session }})
                    </p>
                </div>
              </div>
            
        </div>
      </div>

      <div class="c-card" v-else>
        <!--        <div class="patient-remove">-->
        <!--          <b-button pill class="text-white" variant="secondary" @click="removePatient()"><i-->
        <!--              class="fas fa-times-circle"></i></b-button>-->
        <!--        </div>-->
        <div class="d-flex patient-profile justify-content-between">
          <img :src="userPhoto(patient_details.image)" alt="Orko" v-if="patient_details.image"
               class="patient_image">
          <!--          <img :src="require('@/assets/img/blank.png')" v-if="patient_details.patient.image" alt="Orko" class="patient_image">-->
          <img :src="require('@/assets/img/blank.png')" v-else alt="Orko" class="patient_image">
          <div class="patient-info" v-if="patient_details">
            
            <h4>{{ patient_details.fullname }}</h4>
            <p>Age: {{ patient_details.age  }}</p>
            <p>Phone no: {{ patient_details.phone_number }}</p>
            <p>Gender: {{ patient_details.gender }}</p>
            <p>Blood Group: {{ patient_details.blood_group }}</p>
            <p>Location: {{ patient_details.location }}</p>
            <p v-if="patient_details.chronic_diseases">known disease:</p>
            <p v-if="patient_details.chronic_diseases" v-for="(item, index) in patient_details.chronic_diseases" :key="index">{{  item.disease_title }}</p>
          </div>
        </div>
        <br>
        <div class="patient-tab">
          <b-tabs active-nav-item-class="custom-tab-design bg-white text-dark" v-model="tabIndex">
            <b-tab title="Overview" class="custom-tab" active>
              <p class="mt-5 text-center">Coming soon</p>
            </b-tab>
            <b-tab title="Prescription" @click="getOrkoPrescription('prescription')">
              <div v-if="orko_prescriptions.length" class="d-flex flex-wrap prescriptions">
                <p class="prescriptions-label">Orko Prescription</p>
              
                <div v-for="(orko_prescription,index) in orko_prescriptions"
                     :key="index">
                  <div class="d-flex mt-3">
                    <i class="fas fa-file-pdf mr-5 ml-4  color-red"></i>
                    <div class="text-left">
                      <a href="javascript:void(0)" class="" @click="downloadPrescription(orko_prescription.prescription_identifier)"
                      >Prescription_{{ orko_prescription.prescription_identifier }}.pdf</a>
                      <p class="prescriptions-content">{{ orko_prescription.created_date }},
                        {{ orko_prescription.created_at }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="prescriptions.length" class="d-flex flex-wrap prescriptions">
                <p class="prescriptions-label">Uploaded Prescription</p>
                <div class="content ml-3">
                  <img :src="userPhoto(prescription.profile_image)" alt="" class="image_size_responsive"
                       v-for="(prescription,index) in prescriptions" :key="index">
                </div>
              </div>
            </b-tab>
            <!-- Pirani Score  -->
            <b-tab title="Pirani Score" @click="getPirani()">
              <table class="custom_table my-10" v-if="piraniData.length">
                <tbody>
                  <tr>
                    <td style="padding: 8px 0" class="td_sticky">SL No</td>
                    <td style="padding: 8px 0; font-weight: 600;" v-for="index in Object.keys(dateWisePirani).length" :key="index">{{ index }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 8px 0" class="td_sticky">Date Of Evaluation</td>
                    <td style="padding: 8px 0" v-for="(piraniData, key) in dateWisePirani" :key="key">{{ key }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 8px 0" class="td_sticky">Foot</td>
                    <td v-for="(piraniData, key) in Object.keys(dateWisePirani).length" :key="key">
                      <table>
                        <tr>
                          <td style="font-weight: 600;">R</td>
                          <td style="font-weight: 600;">L</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr v-for="type in examinations_signs" :key="type.id">
                    <td style="padding: 8px 0" class="td_sticky">{{ type.title }}</td>
                    <td v-for="(piraniData, key) in dateWisePirani" :key="key">
                      <table>
                        <tr>
                          <td>{{ findPiraniScore('R', piraniData, type.id) }}</td>
                          <td>{{ findPiraniScore('L', piraniData, type.id) }}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 8px 0; background-color: #ddd;" class="td_sticky">Total</td>
                    <td v-for="(piraniData, key) in dateWisePirani" :key="key">
                      <table>
                        <tr>
                          <td><strong>{{ totalPiraniScore('R', piraniData) }}</strong></td>
                          <td><strong>{{ totalPiraniScore('L', piraniData) }}</strong></td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <span v-else class="p-5 d-block text-center">No Data Found</span>
            </b-tab>
            <b-tab title="Report" @click="getMedicalReports('report')">
              <div v-if="reports.length" class="prescriptions">
                <p class="prescriptions-label">Uploaded Report</p>
                <div class="content ml-3">
                  <img :src="userPhoto(report.profile_image)" alt="" class="image_size_responsive"
                       v-for="(report,index) in reports" :key="index">
                </div>
              </div>
            </b-tab>
            <b-tab title="Image" @click="getMedicalReports('image')">
              <div v-if="images.length" class="d-flex flex-wrap prescriptions">
                <p class="prescriptions-label">Uploaded Image</p>
                <div class="content ml-3">
                  <img :src="userPhoto(image.profile_image)" alt="" class="image_size_responsive"
                       v-for="(image,index) in images" :key="index">
                </div>
              </div>
            </b-tab>
            <b-tab title="Service"  @click="getService()">
              <div class="card-div">
                <div class="card mt-3 shadow custom-card" v-for="(service,index) in services" :key="index">
                  <div class="card-body custom-card-body">
                    <div class="d-flex justify-content-between align-items-center">
                    <p class="font-size-14"><b>{{ convertProductType(service.product_type) }}</b></p>
                    <div class="">
                      <p class="mb-0"><b>{{ invoiceDate(service.invoice_date) }}</b></p>
                      <p class="mb-0"><b>{{ numberFormat(service.total_paid) }} BDT</b></p>
                    </div>
                  </div>
                  </div>
                </div>
                </div>
            </b-tab>
            <b-tab title="Call logs"  @click="getCallLogs()">
              <div class="card-div" v-if="call_logs.length">
                <div class="card mt-3 shadow custom-card" v-for="(call,index) in call_logs" :key="index">
                  <div class="card-body custom-card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="item-content">
                        <div>
                          <span>Call type</span>
                          <p class="font-size-14"><b>{{ call.call_type }}</b></p>
                        </div>
                        <div>
                          <span>Call category</span>
                          <p class="font-size-14"><b>{{ call.call_category }}</b></p>
                        </div>
                        <div>
                        <span>Remarks</span>
                        <p class="font-size-14"><b>{{ call.remarks }}</b></p>
                      </div>
                      </div>
                      <div>
                        <div>
                          <span>Called at</span>
                          <p class="font-size-14"><b>{{ call.call_action_date }}</b></p>
                        </div>
                        <div>
                          <span>Call status</span>
                          <p class="font-size-14"><b>{{ call.call_action_status }}</b></p>
                        </div>
                        <div>
                        <span>Assigned to</span>
                        <p class="font-size-14"><b>{{ call.assigned_crm_name }}</b></p>
                      </div>
                      </div>
                  </div>
                  </div>
                </div>
              </div>
              <p v-else class="mt-5 text-center">No data found</p>
            </b-tab>
            <b-tab title="Message logs"  @click="getMessageList()">
              <div class="card-div" v-if="message_lists.length">
                <div class="card mt-3 shadow custom-card" v-for="(message,index) in message_lists" :key="index">
                  <div class="card-body custom-card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="item-content">
                        <div>
                            <p class="font-size-14"><b>{{ message.published_at }}</b></p>
                        </div>
                      </div>
                  </div>
                  <div>
                    <span>Message</span>
                    <TextMoreLess :text="message.body"/>  
                  </div>
                  </div>
                </div>
              </div>
              <p v-else class="mt-5 text-center">No data found</p>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="c-card p-7">
        <div class="row justify-content-end mb-5">
          <div class="col-md-6">
            <b-input-group class="mt-3">
              <b-form-input placeholder="Patient name/phone no" v-model="filter.q"></b-form-input>
              <b-input-group-append>
                <b-button variant="danger" @click="removeTableSearch()"><i class="fas fa-times"></i></b-button>
                <b-button variant="info" class="custom-btn-bg" @click="getReports()">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        
        <div class="patient-income-table tableFixHead">
          <table class="timeline-tbl" ref="table">
            <thead>
            <tr>
              <th>Patient</th>
              <th class="text-left" style="min-width: 125px;">
                  <b-button @click="selectFilter('Status')" class="coll-btn">{{ filter.status ? serviceStatue('',filter.status) : 'Status' }} <i class="fas fa-chevron-down"></i></b-button>
              </th>
              <th class="text-left">
                <b-button @click="selectFilter('reg_source')" class="coll-btn text-capitalize">{{ underScoreToSpace(filter.reg_source) || 'Reg. Source' }} <i class="fas fa-chevron-down"></i></b-button>
              </th>
              <th class="text-center">Source Name</th>
              <th class="text-left">
                <b-button @click="selectFilter('Service')" class="coll-btn text-capitalize">{{ underScoreToSpace(filter.service) || 'Service' }} <i class="fas fa-chevron-down"></i></b-button>
                </th>
              <th>No of service</th>
              <th>Follow up date</th>
              <th class="text-right">Payments</th>
              <th>Last visit</th>
            </tr>
            <tr>
                <td colspan="5">
                  <b-collapse id="collapse-3" v-model="showRegSource">
                  <ul class="center-selection-list-more-items">
                    <li>
                      <a href="javascript:void(0)" :class="filter.reg_source==''?'active':''"
                        @click="filter.reg_source = ''; showRegSource=false; getReports()">All</a>
                    </li>
                    <li v-for="(item, key) in reg_source_items" :key="key">
                      <a href="javascript:void(0)" :class="item.value == filter.reg_source?'active':''"
                        @click="filter.reg_source = item.value; showRegSource=false; getReports()">{{
                          item.text
                        }}</a>
                    </li>
                  </ul>
                </b-collapse>
                </td>
            </tr>
              <tr>
                <td colspan="5">
                  <b-collapse id="collapse-4" v-model="showStatusFilter">
                  <ul class="center-selection-list-more-items">
                    <li>
                      <a href="javascript:void(0)" :class="filter.status==''?'active':''"
                        @click="filter.status = ''; showStatusFilter=false; getReports()">All</a>
                    </li>
                    <li v-for="(item, key) in status_items" :key="key">
                      <a href="javascript:void(0)" :class="item.text == filter.status?'active':''"
                        @click="filter.status = item.value; showStatusFilter=false; getReports()">{{
                          item.text
                        }}</a>
                    </li>
                  </ul>
                </b-collapse>
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <b-collapse id="collapse-5" v-model="showServiceFilter">
                  <ul class="center-selection-list-more-items">
                    <li>
                      <a href="javascript:void(0)" :class="filter.service==''?'active':''"
                        @click="filter.service = ''; showServiceFilter=false; getReports()">All</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" :class="'Zero Clubfoot' == filter.service?'active':''"
                        @click="filter.service = 'Zero Clubfoot'; showServiceFilter=false; getReports()">Zero Clubfoot</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" :class="'Physiotherapy' == filter.service?'active':''"
                        @click="filter.service = 'Physiotherapy'; showServiceFilter=false; getReports()">Physiotherapy</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" :class="'Dental' == filter.service?'active':''"
                        @click="filter.service = 'Dental'; showServiceFilter=false; getReports()">Dental</a>
                    </li>
                  </ul>
                </b-collapse>
                </td>
              </tr>
            </thead>
            <tbody v-if="patients.length > 0 ">
              <tr v-for="(item,index) in patients" :key="index" @click="showPatient(item,index)"
                  :class="is_row_active == index ?  'row-active' : '' || item.status == 'Consultation Taken' && !item.follow_up_date ? 'bg-gray' : ''">
                
                  <td  style="min-width: 230px">
                  <div class="patient-card">
                    <img :src="userPhoto(item.image)" alt="Orko" v-if="item.image">
                    <img :src="require('@/assets/img/blank.png')" alt="Orko" v-else>
                    <div>
                      <p class="mb-0 mt-0"><b>{{ item.fullname }}</b></p>
                      <p class="mb-0 mt-0">Age: {{ item.age }}</p>
                    </div>
                  </div>
                </td>
                <td class="product_type_item text-left" style="min-width: 100px;padding-right: 30px;">
                  {{ serviceStatue(item.status, item.product_type) }}
                </td>
                <td  style="min-width: 130px;padding-right: 30px;text-transform: capitalize;" class="text-left">{{ item.source_of_reg.replace(/_/g," ") }}</td>
                <td style="min-width: 120px; padding-right: 25px;" class="text-center">{{ item.source_name }}</td>
                <td style="min-width: 100px; padding-right: 25px;" class="text-left">{{ item.service }}</td>
                <td  style="min-width: 70px">{{ item.service_count }}</td>
                <td  style="min-width: 150px">{{ item.follow_up_date }}</td>
                <td  style="min-width: 95px" class="text-right">{{ item.total_income ? numberFormat(item.total_income) : 0 }}</td>
                <td  style="min-width: 150px">{{ item.visit_date ? invoiceDate(item.visit_date) : '-' }}
                </td>
              </tr>
            </tbody>
              <tr v-else class="text-center">
                <td colspan="8">
                  <h4 class="text-center mt-3">No data</h4>  
                </td>
              </tr>
          </table>
        </div>
        <div class="row mt-3" v-if="total_count">
            <div class="col-md-12">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="total_count"
                  :per-page="perPage"
                  size="lg"
                  class="justify-content-center"
                  @change="getReports"
              ></b-pagination>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import {underScoreToSpace} from "../../../../healper/nameFormating";
import TextMoreLess from "../../../../components/reusable-component/TextMoreLess.vue";
export default {
  name: "Patient",
  components:{
    TextMoreLess
  }, 
  data() {
    let all_months = [
      {
        text: "Q1",
        value: "1"
      },
      {
        text: "Jan",
        value: "January",
      },
      {
        text: "Feb",
        value: "February",
      },
      {
        text: "Mar",
        value: "March",
      },
      {
        text: "Q2",
        value: "2"
      },
      {
        text: "Apr",
        value: "April",
      },
      {
        text: "May",
        value: "May",
      },
      {
        text: "Jun",
        value: "June",
      },
      {
        text: "Q3",
        value: "3"
      },
      {
        text: "Jul",
        value: "July",
      },
      {
        text: "Aug",
        value: "August",
      },
      {
        text: "Sep",
        value: "September",
      },
      {
        text: "Q4",
        value: "4"
      },
      {
        text: "Oct",
        value: "October",
      },
      {
        text: "Nov",
        value: "November",
      },
      {
        text: "Dec",
        value: "December",
      },
    ];
    return {
      loading: false,
      months: all_months,
      filter: {
        period: 'monthly',
        month: '',
        center: '',
        year: new Date().getFullYear(),
        quarter: '',
        q: '',
        reg_source: '',
        status: '',
        service: '',
      },
      month: all_months[new Date().getMonth()].value,
      start_year: 2023,
      year: new Date().getFullYear(),
      years: [],
      showMonthFilter: false,
      showCenterFilter: false,
      showPatientFilter: false,
      showRegSource: false,
      showStatusFilter: false,
      showServiceFilter: false,
      centers: [],
      patients: [],
      is_show_patient: false,
      patient_details: {},
      perPage: 10,
      currentPage: 1,
      total_count: 0,
      patient_summery_report: {
        source:{} 
      },
      prescriptions: [],
      images: [],
      reports: [],
      orko_prescriptions: [],
      is_row_active: '',
      tabIndex: 0,
      targets: [],
      max: 100,
      club_foot_appoinment_target: 0,
      reg_source_items: [
        {
          text: 'Sticker',
          value: 'Poster',
        },
        {
          text: 'Social',
          value: 'Social',
        },
        {
          text: 'Doctor Refer',
          value: 'Doctor Referral',
        },
        {
          text: 'Patient Refer',
          value: 'Patient Referral',
        },
        {
          text: 'Employee Refer',
          value: 'Employee Referral',
        },
        {
          text: 'App',
          value: 'App',
        },
        {
          text: 'Relapse Patient',
          value: 'Relapse Patient',
        },
        {
          text: 'Others',
          value: 'Others',
        }
      ],
      status_items: [
        {
          text: 'Newly Registered',
          value: 'Newly Registered',
        },
        {
          text: 'Consultation Taken',
          value: 'Consultation Taken',
        },
        {
          text: 'Booked Appointment',
          value: 'Booked Appointment',
        },
        {
          text: 'Session Taken',
          value: 'Session Taken',
        },
        {
          text: 'Session Booked',
          value: 'Session Booked',
        },
        {
          text: 'Clubfoot Registration',
          value: 'registration_fee',
        },
        {
          text: 'Casting done',
          value: 'Ponseti (Plaster)',
        },
        {
          text: 'Tenotomy done',
          value: 'Ponseti (Tenotomy)',
        },
        {
          text: 'Surgery done',
          value: 'Ponseti (Surgery)',
        },
      ],
      services:[],
      call_logs:[],
      message_lists:[],
      piraniData: [],
      examinations_signs: [],
      dateWisePirani: []
    }
  },

  created() {
    this.filter.month = moment().format('MMM');
    this.setYears();
    this.patientSummeryReport();
    this.getReports();
    this.centerList();
    this.getPirani();
    this.getMedicalExaminationItems();
  },
  methods: {
    underScoreToSpace,
    invoiceDate(date) {
      // moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format('D MMM YYYY');
    },
    numberFormat(num) {
      return num?(Math.round(num * 100) / 100).toFixed(2):0;
    },
    selectPeriod(type, value) {
      this.filter.period = type;
      if (type == 'monthly') {
        this.filter.quarter = '';
        this.filter.month = value;
      } else {
        this.filter.month = '';
        this.filter.quarter = value;
      }
    },
    getYearLabel(year) {
      return year ? String(year).substring(2, 4) : "";
    },
    setYears() {
      let cYear = new Date().getFullYear();
      while (this.start_year <= cYear) {
        this.years.push(this.start_year++);
      }
    },
    selectFilter(type) {
     
      if (type == "Month") {
        this.showCenterFilter=false;
        this.showStatusFilter=false;
        this.showRegSource = false;
        this.showServiceFilter = false;
       
        this.showMonthFilter = !this.showMonthFilter;
      } else if (type == "Center") {
        this.showMonthFilter = false;
        this.showStatusFilter=false;
        this.showServiceFilter = false;
        this.showCenterFilter = !this.showCenterFilter;
      } else if (type == 'reg_source') {
        
        this.showCenterFilter = false;
        this.showMonthFilter = false;
        this.showStatusFilter=false;
        this.showServiceFilter = false;
        this.showRegSource =  !this.showRegSource;
      } else if (type == 'Status') {
        this.showCenterFilter = false;
        this.showMonthFilter = false;
        this.showStatusFilter = !this.showStatusFilter;
        this.showRegSource =  false;
        this.showServiceFilter = false;
      } else if (type == 'Service') {
        this.showCenterFilter = false;
        this.showMonthFilter = false;
        this.showStatusFilter = false;
        this.showRegSource =  false;
        this.showServiceFilter = !this.showServiceFilter;
      }
    },
    resetPatientFilter(){
        this.filter.q = '';
        this.filter.status = '';
        this.filter.reg_source = '';
        this.filter.service = '';
    },
    getReports(value) {
      
      this.loading = true;
      if (Number(value)) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      let selectedCenter = null;
      if (this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }

      if(this.filter.reg_source) {
        this.filter.reg_source = (this.filter.reg_source == 'App')?'register':this.filter.reg_source;
      }
      axios
          .get(
              `${
                  process.env.VUE_APP_API_URL
              }/api/v2/report/orkocenter/occ/patient?api_token=${localStorage.getItem(
                  "api_token"
              )}${this.filter.month ? '&month=' + this.filter.month : ''}${selectedCenter ? '&institute_id=' + selectedCenter.id : ''}
              &period=${this.filter.period}&year=${this.filter.year}${this.filter.quarter ? '&quarter=' + this.filter.quarter : ''}${this.filter.q ? '&q='+this.filter.q : ''}${this.filter.reg_source ? '&reg_source='+this.filter.reg_source : ''}${this.filter.status ? '&status='+this.filter.status : ''}${this.filter.service ? '&service='+this.filter.service : ''}&limit=10&offset=${correctValue ? correctValue : 0}`
          )
          .then((res) => {
            this.patients = res.data.data;
            this.total_count = res.data.total_count;
            this.loading = false;

          });
    },

    calculateProgress(achieved, target) {
      if(target > 0) {
        return Math.floor((achieved*100)/target);
      }
      return 0;
    },

    filterReport() {
      this.patientSummeryReport();
      this.getReports();
    },
    patientSummeryReport() {
      this.resetPatientFilter();
      let selectedCenter = null;
      if (this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }
      axios.get(`${process.env.VUE_APP_URL}/api/v2/report/orkocenter/occ/patients/summary?api_token=${localStorage.getItem('api_token')}${this.filter.month ? '&month=' + this.filter.month : ''}${selectedCenter ? '&institute_id=' + selectedCenter.id : ''}
              &period=${this.filter.period}&year=${this.filter.year}${this.filter.quarter ? '&quarter=' + this.filter.quarter : ''}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              // this.loading = false
              this.patient_summery_report = response.data.data
              this.targets = response.data.data.targets;
              // this.getReports();
              
            }
          })
    },
    getOthersValue(){
      return ( this.patient_summery_report.total_registration- (this.patient_summery_report.source.doctor+this.patient_summery_report.source.market_activation+this.patient_summery_report.source.corporate_engagement));
    },
    getRegCountBySource(platform){
      let regSource = this.patient_summery_report.source.find((item) => item.platform == platform);
      if(regSource){
        return regSource.reg_count
      }else{
        return 0;
      }
    },
    getRegTotalBySource(){
      let sum = 0;
      this.patient_summery_report.source.forEach((item)=> {
        sum += parseFloat(item.reg_count);
      })
      return sum;
    },
    getTarget(target){
        const targetObj = this.targets.find(item => item.product_type == target);
        if(targetObj) {
          return targetObj.total_target
        }
        return 0;
    },

    getAppoimentTarge(){
      var sum = 0;
      this.targets.filter(item => {
        if(item.product_type == 'clubfoot_appointment' || item.product_type == 'therapy_appointment' || item.product_type == 'dental_appointment') {
          return sum += item.total_target;
        }
      })
    },

    getTargetclubfootAppointment(type){
        var total = 0;
        for(var i = 0; i < this.targets.length;i++){
          if(type== 'appointment') {
            if (this.targets[i].product_type == 'clubfoot_appointment' || 
                  this.targets[i].product_type == 'therapy_appointment' || this.targets[i].product_type == 'dental_appointment') {
              total += parseInt(this.targets[i].total_target)
            }
          }else {
            if (this.targets[i].product_type == 'clubfoot_consultation' || 
                  this.targets[i].product_type == 'therapy_consultation' || this.targets[i].product_type == 'dental_consultation') {
              total += parseInt(this.targets[i].total_target)
            }
          }
        }
        return total
    },

    // getTargetTherapyAppointment(){
    //     const findClubFootApp = this.targets.find(item => item.product_type == 'therapy_appointment');  
    //     return findClubFootApp.total_target+this.club_foot_appoinment_target;
    // },

    calculatePercentage(part, total) {
      if (part == 0 && total == 0) {
        return 0;
      }
      return ((part / total) * 100).toFixed(2);
    },
    
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              // this.loading = false
              this.centers = response.data.data
            }
          })
    },
    showPatient(item, index) {

      if(this.is_show_patient == item.id) {
          this.is_show_patient = false;
          return false;
        } 

        axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${item.id}?platform=backoffice&api_token=${localStorage.getItem('api_token')}`)
        .then(res => {
          this.patient_details = res.data.data;
          this.is_show_patient = this.patient_details.id ;
          this.is_row_active = index;
          this.tabIndex = 0;
        })

    },
    handleClickOutside(event) {
      // check if click target is outside of the table element
      if (!this.$refs.table.contains(event.target)) {
        // trigger your event here
        this.is_show_patient = false;
        this.$emit('outside-click');
      } else {
        this.is_show_patient = true;
      }
    },
    handleOutsideClick() {
      this.is_show_patient = false;
    },
    removePatient() {
      this.is_show_patient = false;
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
    },
    getMedicalReports() {
      this.prescriptions = [];
      this.reports = [];
      this.images = [];
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v1/patient/document/list?api_token=${localStorage.getItem('api_token')}&patient_id=${this.patient_details.id}`)
          .then(response => {
            response.data.data.map(item => {
              if (item.type == 'prescription') {
                this.prescriptions.push(item);
              } else if (item.type == 'image') {
                this.images.push(item);
              } else {
                this.reports.push(item)
              }
            });
            this.loading = false;
          });
    },
    getOrkoPrescription() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/agent/prescription/by-patient?api_token=${localStorage.getItem('api_token')}&patient_id=${this.patient_details.id}`)
          .then(res => {
            this.orko_prescriptions = res.data.data;
            this.getMedicalReports();
          });
    },
    prescriptionUrl(url) {
      if (url) {
        return `${process.env.VUE_APP_API_URL}/${url}`;
      } else {
        return ''
      }
    },
    downloadPrescription(identifier){
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/prescription/${identifier}/download?api_token=${localStorage.getItem('api_token')}`,
       { responseType: 'blob' }
      )
      .then((response) => {
          const filename = response.headers['content-disposition']
            .split('filename=')[1]
            .split('.')[0];
          // const extension = response.headers['content-disposition']
          //   .split('.')[1]
          //   .split(';')[0];
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    totalPiraniScore(side, dateWiseData) {
      let sum = 0;
      dateWiseData.forEach((item)=> {
        if(item.sides == side) {
          sum += parseFloat(item.term);
        }
      })
      return sum;
    },
    findPiraniScore(side, dateWiseData, typeId) {
      let piraniScore = dateWiseData.find((item)=> {
        return item.type_id == typeId && item.sides == side;
      })
      return piraniScore?piraniScore.term:' - ';
    },
    getPirani() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/appointment/${this.patient_details.id}/pirani-scores?api_token=${localStorage.getItem('api_token')}`)
        .then((res) => {
          this.piraniData = res.data.data;
          this.dateWisePirani = this.groupBy(res.data.data, 'evaluation_date');
          this.loading = false;
        });
    },
    getMedicalExaminationItems() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/medical-general-examination/list?type=pirani&api_token=${localStorage.getItem('api_token')}`)
        .then((res) => {
          this.examinations_signs = res.data.data;
        });
    },
    groupBy(arrr, key) {
      return arrr.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    serviceStatue(status = null, type = null) {
      
      if(status) {
        return status;
      }

      if(type == 'therapy_session'){
        return "Session taken";
      } else if(type == 'Ponseti (Tenotomy)') {
        return "Tenotomy done"
      } else if(type == 'registration_fee') {
        return "Clubfoot Registration"
      } else if(type == 'Ponseti (Plaster)') {
        return "Casting done"
      } else if (type == 'Ponseti (Surgery)') {
        return "Surgery done"
      }

      return type
      
    },
    convertProductType(type = null){
      if(type == 'therapy_session'){
        return "Session";
      } else if(type == 'Ponseti (Tenotomy)') {
        return "Tenotomy"
      } else if(type == 'registration_fee') {
        return "Registration"
      } else if(type == 'Ponseti (Plaster)') {
        return "Casting"
      } else if (type == 'Ponseti (Surgery)') {
        return "Surgery"
      }else if (type == 'dental_appointment') {
        return "Dental Consultation"
      }else if (type == 'therapy_appointment') {
        return "Therapy Consultation"
      }else if (type == 'clubfoot_appointment') {
        return "Clubfoot Consultation";
      }

      return type
    },

    getService(){
      this.loading = true;
      let selectedCenter = null;
      if (this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/report/orkocenter/occ/patients/${this.patient_details.id}/services?api_token=${localStorage.getItem("api_token")}${this.filter.month ? '&month=' + this.filter.month : ''}${selectedCenter ? '&institute_id=' + selectedCenter.id : ''}
              &period=${this.filter.period}&year=${this.filter.year}${this.filter.quarter ? '&quarter=' + this.filter.quarter : ''}`)
      .then(res => {
        this.loading = false;
        this.services = res.data.data;
      })
    },
    getCallLogs(){
      this.loading = true;
      let selectedCenter = null;
      if (this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/agent/call-logs/patients/${this.patient_details.id}?limit=20&api_token=${localStorage.getItem("api_token")}${this.filter.month ? '&month=' + this.filter.month : ''}${selectedCenter ? '&institute_id=' + selectedCenter.id : ''}
              &period=${this.filter.period}&year=${this.filter.year}${this.filter.quarter ? '&quarter=' + this.filter.quarter : ''}`)
      .then(res => {
        this.loading = false;
        this.call_logs = res.data.data;
      })
    },

    getMessageList(){
      this.loading = true;
      let selectedCenter = null;
      if (this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/agent/messages/patients/${this.patient_details.id}?limit=20&api_token=${localStorage.getItem("api_token")}${this.filter.month ? '&month=' + this.filter.month : ''}${selectedCenter ? '&institute_id=' + selectedCenter.id : ''}
              &period=${this.filter.period}&year=${this.filter.year}${this.filter.quarter ? '&quarter=' + this.filter.quarter : ''}`).then(res => {
          this.message_lists = res.data.data;
          this.loading = false;
      })
    },
    removeTableSearch() {
      this.filter.q = '';
      this.getReports();
    }
  },
  watch: {
    "showPatient": function(newVal,oldVal) {
      console.log('nw', newVal, oldVal);
    }
  }
}
</script>

<style lang="scss" scoped>
.c-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #fff;
  width: 100%;
  float: left;

  .filter-list {
    width: 100%;
    float: left;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      width: 50%;
      float: left;
      padding: 0px 10px 0;
      border-right: 2px solid #002170;
      text-align: center;
      list-style: none;

      &:last-of-type {
        border-right: none;
        padding: 0;
      }

      select {
        border: none;
        outline: none;
        scroll-behavior: smooth;
        -webkit-appearance: listbox !important;

        color: #002170;
        font-size: 15px;
        font-weight: 600;
        font-family: Arial;
        padding: 0 12px 0 8px;
        height: 22px;
      }
    }
  }
.content-title {
  color: #FFA800 !important;
  font-size: 20x;
    font-family: Arial;
    font-weight: 700;
}
  .target-content {
    width: 100%;
    float: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #00579b;
    padding: 25px;

    ul {
      list-style-type: none;
      margin-bottom: 20px;
      width: 100%;
      float: left;
      list-style: none;

      li {
        width: 100%;
        float: left;

        .l-label {
          width: 45%;
          float: left;
        }

        .r-label {
          text-align: right;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          font-family: Arial;

          .achieved {
            color: #f9a91a;
          }
        }

        label {
          color: #fff;
          font-size: 18px;
          font-family: Arial;
          font-weight: 600;
        }
      }
    }
  }

  .timeline-tbl {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    float: left;

    .expanded-row {
      box-shadow: 0 0 0 1px #AAA;
      border-radius: 20px;
    }
    tr {
      th {
        color: #002147;
        border-bottom: 1px solid #a0a0a1;
        padding-bottom: 10px;
        text-align: center;
        font-size: 15px;
      }

      td {
        vertical-align: top !important;
      }
    }

    tbody {
      tr {
        &.expanded-total-row {
          border-top: 1px solid #AAA;

          td {
            ul {
              margin: 6px 0;
              list-style-type: none;

              li {
                line-height: 1.6;

                &:last-of-type {
                  font-size: 13px;

                  span {
                    font-size: 13px;

                    i {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }

        td {
          text-align: center;
          font-size: 16px;
          line-height: 1.5;
          color: #212120;
        }

        &:first-of-type {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .summary-wrapper {
    width: 100%;
    float: left;
    background-color: #00579b;
    padding: 8px 30px;
    border-radius: 20px;
    margin-top: 30px;

    .summary-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;

      tr {
        th {
          color: #fff;
          font-size: 17px;
          padding-bottom: 5px;
        }

        td {
          font-size: 14px;
          color: #fff;
          font-weight: 600;
          vertical-align: top !important;
        }
      }
    }
  }
}

table{
  &.custom_table{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    overflow-x: scroll;
    display: block;
    border-radius: 10px;
    th, td {
      text-align: center;
      padding: 0;
      border: 1px solid #ddd;
      border-collapse: collapse;
    }
    tr{
      display: flex;
      th{
        width: 100%;
      }
      td{
        width: 100px;
        font-size: 10px;
        &.td_sticky{
          position: sticky;
          left: 0;
          background: #fff;
          top: 0;
          width: 130px;
          font-weight: 500;
          font-size: 10px;
        }
      }
      table{
        width: 100%;
        tr{
          display: table-row;
          th, td{
            padding: 8px;
            width: 50%;
            font-weight: 500;
            font-size: 10px;
            border: none;
            &:first-child{
              border-right: 1px solid #ccc;
            }
          }
        }
      }
    }
  }
}

.brd-15 {
  border-radius: 15px;
}

.ftb-15 {
  font-size: 15px;
  font-weight: 600;
}

.up-text {
  color: #00cd32;
  font-size: 15px;
  font-weight: 600;

  i {
    color: #00cd32;
    font-size: 13px;
  }
}

.down-text {
  color: red;
  font-size: 15px;
  font-weight: 600;

  i {
    color: red;
    font-size: 13px;
  }
}

.coll-btn {
  color: #002170;
  font-size: 15px;
  font-weight: 600;
  font-family: Arial;
  padding: 0 12px 0 8px;
  // height: 22px;

  background-color: transparent;
  border: none;

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.month-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;

        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}

.year-selection-list {
  width: 100%;

  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  li {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      background-color: #d9d4d4;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;
      margin: 5px;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9),
    &:nth-child(13) {
      a {
        background-color: #aaaaaa;
        color: #fff;
        &.active {
          background-color: #f9a81a;
          font-weight: 600;
        }
      }
    }
  }
}

.center-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}
.center-selection-list-more-items {
  width: 100%;
  display: flex;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  li {
  
    text-align: center;
    margin: 10px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}

.patient-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 50%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 8px 30px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}

.patient-income-table {
  height: 500px;
  overflow: scroll;
  // padding: 2px;
}

.target-content {
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;

  p {
    margin: 5px 0px;
  }

  ul {
    list-style-type: none !important;
    // display: flex;
    margin-left: 0px;
    font-weight: bold;
    font-size: 17px;
  }
}

.patient_image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.patient-profile {
  width: 100%;
  float: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px 15px 3px 0px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #00579b;
  padding: 25px;
  color: #ffffff;

  .patient-info {
    padding-left: 20px;

    p {
      font-size: 14px;
      font-weight: 500;
      margin: 5px 0px;
    }
  }

}

.patient-tab {
  width: 100%;
  float: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  padding: 25px;
}

.patient-remove {
  position: absolute;
  right: 25px;
  top: 10px;
}

.male-female-icon {
  height: 70px;
  margin-right: 20px;
}

.patient-card {
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
  padding-right: 30px;
}

.patient-card div {
  margin-left: 15px;
  margin-bottom: 10px;
}

.patient-card div p {
  line-height: 20px;
}

.patient-card img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.patient-tab {
  background: #fff;
  margin-top: -15px;
}

.custom-tab-design {
  color: #000000 !important;
  background-color: #fff !important;
  /* border-color: #E4E6EF #E4E6EF #ffffff; */
  border-bottom: 2px solid #9b9999 !important;
  box-shadow: none !important;
}

.image_size_responsive {
  //height: 100px;
  width: 100px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 2px;
  margin-right: 2px;
}

.prescriptions {
  border: 1px solid #ddd;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px;
  position: relative;
}

.prescriptions .content {
  margin-top: 10px;
  //margin-left: 10px;
  //text-align: center !important;
}

.prescriptions-label {
  position: absolute;
  top: -11px;
  background: #ffff;
  padding: 0px 10px;
}

.color-red {
  color: red;
  font-size: 35px;
}

.row-active {
  background: #f6f6f6;
  border-radius: 15px;
}

.prescriptions-content {
  line-height: 15px;
}
.marget_activation {
  background: red;
}
.progressbar-subtitle {
  font-size: 10px;
  font-weight:500;
}
.custom_variant_yellow {
  background-color: #ffd37f !important;
}
.custom_market_activation_bg {
  background-color: #FFD500 !important;
}
.corporate_engangement_bg {
  background-color: #66F755 !important;
}
.default_progressbar_bg {
  background-color: #FFFFFF !important;
}
.pt_rf_bg {
  background-color: #f554cf !important;
}
.em_rf_bg {
  background-color: #00aa9b !important;
}
.app_bg {
  background-color: #bb4010 !important;
}
.re_pt_bg {
  background-color: #855aea !important;
}
.others_bg {
  background-color: #b91010 !important;
}

.reg_to_consult {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  margin-left: 10px;
}
.conversion_calculate {
  font-size:14px;
  font-weight: bold;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.progress_bar_bullet {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  p {
    margin-left: 10px;
  }
  span {
    height: 11px;
    width: 11px;
    display: inline-block;
    margin-right: 5px;
  }
  .doctor_bullet {
    background: #FFA800;
  }
  .market_activation_bullet {
    background: #FFD500;
  }
  .corporate_engagement_bullet {
    background: #66F755;
  }
  .other_bullet {
    background: #FFFFFF;
  }
  .pt_rf_bullet {
    background-color: #f554cf !important;
  }
  .em_rf_bullet {
    background-color: #00aa9b !important;
  }
  .app_bullet {
    background-color: #bb4010 !important;
  }
  .re_pt_bullet {
    background-color: #855aea !important;
  }
  .others_bullet {
    background-color: #b91010 !important;
  }
}
.custom-card-body {
  padding: 10px 10px !important;
}
.card-div {
  height: 400px;
  overflow: scroll;
}
.font-size-14 {
  font-size: 15px;
}
.custom-btn-bg {
  background-color: #00579b !important;
}
.text-capitalize {
  text-transform: capitalize;
}
.bg-gray {
  background-color: #dfe1e5 !important;
}
.item-content {
  line-height: 20px;
}

.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

</style>
<style>
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #000;
  border-color: #ffffff #ffffff #000000;
}
.patient-tab .nav-tabs {
  flex-wrap: initial !important;
  overflow: scroll;
}
.patient-tab .nav-tabs .nav-item a {
  text-wrap: nowrap;
}
</style>
