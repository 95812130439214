<template>
     <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <button type="button" class="btn btn-primary" @click="addNewTarget">Add New Donor</button>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            
          </b-table>
        </div>
      </div>
      <b-modal
          id="addTarget"
          ref="modal"
          :title="`${this.form.id ? 'Update' : 'Add new'} Donor`"
          @hidden="resetModal"
          @ok="handleOk"
          size="lg">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="6" class="my-5">
              <label>Donor name</label>
              <b-form-input v-model="form.name"></b-form-input>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Address</label>
              <b-form-input v-model="form.address"></b-form-input>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Commited Amount</label>
              <b-form-input v-model="form.committed_amount" type="number"></b-form-input>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export default {
    name: 'Donors',
    title: 'Donors',
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'address',
          label: 'address',
        },
        {
          key: 'committed_amount',
          label: 'Cmmitted amount',
        },
    
      ],
      loading: false,
      form: {
        id: '',
        name: '',
        address: '',
        committed_amount: '',
        
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Donors", route: "/donors"},
    ]);
  },
  created() {
    this.getItems();
    localStorage.setItem('tabIndex', 7)
    this.$root.$emit('call-active-menu', 7);
  },
  methods: {
    getItems() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/donors?api_token=${localStorage.getItem('api_token')}`).then(res => {
        this.items = res.data.data;
        this.loading = false;
      })
    },
    addNewTarget() {
      this.$bvModal.show('addTarget')
    },
    
    resetModal() {
      this.form = {};
      this.getItems();
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      // let sales_user_name = this.sales_users.find(item => item.id == this.form.user_id);
      // this.form.user_name = sales_user_name.name;
      
      this.$nextTick(() => {
        let url = `${process.env.VUE_APP_API_URL}/api/v2/donors?api_token=${localStorage.getItem('api_token')}`;
        axios.post(url, this.form).then(res => {
          if (res.data.status_code == 200) {
            this.getItems();
            this.$bvModal.hide('addTarget')
            this.$snotify.success('Successfully saved');
          } else {
            this.$snotify.error(res.data.message);
          }
        })
      })
    },
  }
}
</script>