<template>
    <div>
       <div class="card">
        <div class="card-body">
            <h1>Blog</h1>
            {{  $route.params.slug }}
            <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="6" class="my-5">
              <label>Title</label>
              <b-form-input v-model="form.title"></b-form-input>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Select category</label>
              <b-form-select
                v-model="form.conditions_we_treat_id"
                :options="categories"
                value-field="id"
                text-field="name">
            </b-form-select>
            </b-col>
            <b-col cols="12" class="my-5">
              <label>Description</label>
              <input type="file" ref="fileInput" style="display: none" @change="handleFileChange">

            
              <!-- <ckeditor :editor="editor" v-model="form.description" :config="editorConfig" id="editor"></ckeditor> -->
              <!-- <ckeditor :editor="editor" v-model="form.description" :config="editorConfig"></ckeditor> -->

              <vue-editor
                id="editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                @image-removed="handleImageRemoved"
                v-model="form.description"
                :editorOptions="editorSettings"
                >
                </vue-editor>


              <!-- <b-form-input v-model="form.description"></b-form-input> -->
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Select status</label>
              <b-form-select v-model="form.is_published" :options="status"></b-form-select>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Upload image</label>
              <b-form-group>
                <b-form-file
                    class=""
                    ref="image"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <label>Author name</label>
              <!-- <b-form-input v-model="form.author_name"></b-form-input> -->
              <div>
                  <vue-autosuggest
                    :suggestions="[{data:users}]"
                    v-model="form.selected_user_id"
                    :input-props="{id:'autosuggest__input', placeholder:'Enter phone number'}"
                    @input="onInputChange"
                    
                    @selected="selectHandler"
                    :get-suggestion-value="getSuggestionValue"
                    
                >  
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{suggestion.item.fullname}}</span>
                  </template>
                </vue-autosuggest>

                <div v-if="pushedUsers.length" class="position-relative">
                  <b-badge pill variant="info" class="m-3" v-for="(pushed_user, index) in pushedUsers" :key="index">{{ pushed_user.fullname }} <span @click="removePushedUser(index)" class="remove_user">X</span></b-badge>
                </div>
              </div> 
            </b-col>
          </b-row>
          <br>
          <button type="submit" class="btn btn-info btn-lg">Submit</button>
        </form>
        </div>
       </div>
    <vue-snotify></vue-snotify>
    </div>
</template>
<script>
import axios from 'axios';
import {VueAutosuggest} from 'vue-autosuggest';
import { VueEditor, Quill } from "vue2-editor";

// import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module';

//   Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

export default {
    name: 'CreateEditPost',
    data(){
        return {
            form: {
                title: '',
                description: null,
                author_name: '',
                is_published: 1,
                category_id: null,
                conditions_we_treat_id: null,
                user_id: '',
                selected_user_id: '',
            },
            editorSettings: {
          modules: {
            // imageDrop: true,
            imageResize: {}
          }
        },
            status: [
                {
                text: 'Published',
                value: 1,
                },
                {
                text: 'Unpublished',
                value: 0,
                },
            ],
            categories: [],
            users: [],
            pushedUsers: [],
            // editor: ClassicEditor,
            // editorConfig: {
            //             toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo' ],
            //             table: {
            //                 toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
            //             },
            //             extraPlugins: [this.uploader],
            //             language: 'nl',
            //         },

                    
        }
    },
    components: {
        // ckeditor: CKEditor.component,
        VueAutosuggest,
        VueEditor
        
    },
    created(){
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.getCategory();
        if(this.$route.params.slug) {
            this.getItem();
        }
    },
    
    methods: {
        handleTextChanged(delta, oldDelta, source) {
            console.log(delta, oldDelta, source, 'delta, oldDelta, source');
        },
        handleSubmit() {
            let image = this.$refs.image.files[0];
            let form = new FormData();

            form.append('title', this.form.title ? this.form.title : '');
            form.append('is_published', this.form.is_published);
            form.append('description', this.form.description ? this.form.description : '');
            form.append('user_id', this.form.user_id ? this.form.user_id : '');
            form.append('conditions_we_treat_id', this.form.conditions_we_treat_id);
            if (image) {
                form.append('image', image);
            }
            this.$nextTick(() => {
                let url = '';
                if(this.$route.params.slug){
                    url = `${process.env.VUE_APP_API_URL}/api/v2/blogs/${this.form.id}?api_token=${localStorage.getItem('api_token')}`;
                }else {
                    url = `${process.env.VUE_APP_API_URL}/api/v2/blogs?api_token=${localStorage.getItem('api_token')}`;
                }
                
                axios.post(url, form).then(res => {
                if (res.data.status_code == 200) {
                    this.$router.push('/blogs/posts')
                    this.$snotify.success('Successfully saved');
                } else {
                    this.$snotify.error(res.data.message);
                }
                })
            })
        },
        getCategory() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/v2/blogs/categories?api_token=${localStorage.getItem('api_token')}`).then(res => {
                this.categories = res.data.data;
            })
        },
        getItem(){
            axios.get(`${process.env.VUE_APP_API_URL}/api/v2/blogs/${this.$route.params.slug}?api_token=${localStorage.getItem('api_token')}`).then(res => {
                this.form = res.data.data
            })
        },
        // vue suggestion
        onInputChange(val){
            axios.get(`${process.env.VUE_APP_URL}/api/v2/user/search?api_token=${localStorage.getItem('api_token')}&user_type=doctor&q=${val}`)
                .then(response => {
                    this.users = response.data.data
                })
        },

        selectHandler(item){
           
                this.form.user_id =  item.item.id
                
            // this.pushedUsers.push(item.item);
        },

        clickHandler(item){
            this.form.user_id =  item.item.id
        },

        getSuggestionValue(suggestion) {
            return suggestion.item.fullname;
        },

        removePushedUser(index){
            this.pushedUsers.splice(index, 1);
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            this.uploadImage(file);
        },

        handleEditorInput(value) {
            console.log('dd', value);
            this.editorContent = value;
        },
        // uploader(editor)
        //         {
        //             editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        //                 return new UploadAdapter( loader );
        //             };
        //         },

        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

        var formData = new FormData();
        formData.append("image", file);

        axios.post(`${process.env.VUE_APP_API_URL}/api/v2/blogs/image/upload?api_token=${localStorage.getItem('api_token')}`, formData)
            .then(result => {
                const url = result.data.data.url; // Get url from response
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
                this.$snotify.success('Image Successfully Uploaded');
            })
            .catch(err => {
            console.log(err);
            });
        },
        handleImageRemoved(file, Editor, cursorLocation,) {
            let imageName = file.substring(file.lastIndexOf('/') + 1);
            axios.delete(`${process.env.VUE_APP_API_URL}/api/v2/blogs/image/delete?api_token=${localStorage.getItem('api_token')}&image=${imageName}`)
            .then(result => {
                this.$snotify.success('Image Successfully Deleted');
            })
            .catch(err => {
            console.log(err);
            });
        }
    },
  
}
</script>