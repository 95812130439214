<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <button type="button" class="btn btn-primary" @click="addNewTarget">Add New Condition we treat</button>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white" 
              show-empty
              class="mt-5">
            <template #cell(image)="row">
              <img :src="userPhoto(row.item.image)" alt="" class="img-fluid user-image">
            </template>
            <template #cell(icon)="row">
              <img :src="userPhoto(row.item.icon)" alt="" class="img-fluid user-image">
            </template>
            <template #cell(is_active)="row">
              <b-badge v-if="row.item.is_active==1" variant="success">Active</b-badge>
              <b-badge v-else variant="danger">In active</b-badge>

            </template>
            <template #cell(action)="row">
              <div>
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <div>
                    <b-dropdown-item href="#" @click="editAction(row.item)">
                      <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <b-modal
          id="addTarget"
          ref="modal"
          :title="`${this.form.id ? 'Update' : 'Add new'} Template`"
          @hidden="resetModal"
          @ok="handleOk"
          size="lg">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="6" class="my-5">
              <label>Name</label>
              <b-form-input v-model="form.name"></b-form-input>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Position</label>
              <b-form-input v-model="form.position"></b-form-input>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Select status</label>
              <b-form-select v-model="form.is_active" :options="status"></b-form-select>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Upload image</label>
              <b-form-group class="">
                <b-form-file
                    class=""
                    ref="image"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <label>Template type</label>
              <b-form-select v-model="form.type" :options="types"></b-form-select>
            </b-col>

            <b-col cols="6">
              <label>Upload icon</label>
              <b-form-group class="">
                <b-form-file
                    class=""
                    ref="icon"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                ></b-form-file>
              </b-form-group>
            </b-col>
            </b-row>
            <h4><b>Upload Banner (1925px * 525px)</b></h4>
            <hr>
            <b-row v-for="(banner,index) in banners" :key="index" align-content="center">
            <b-col cols="6">
              
              <b-form-group class="">
                <b-form-file
                    class=""
                    @change="onFileChange($event,index)"
                    ref="banner"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                ></b-form-file>
               
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-btn @click="removeFromDatabase(banner, index)" variant="danger" class="mt-5"  v-show="index != 0"  v-if="banner.id">Del</b-btn>
              <b-btn @click="remove(index)" variant="danger" class="mt-5"  v-show="index != 0" v-else>Del</b-btn>
            </b-col>
            <b-col cols="4" v-if="banner.id">
              <img :src="userPhoto(banner.image_url)" alt="" style="height: 100px;">
            </b-col>
          </b-row>
          <b-btn  @click="addMore()" size="sm" variant="info">Add More Banners</b-btn>
        </form>
      </b-modal>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ConditionWeTreat",
  title: 'Condition we treat',
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'image',
          label: 'Image',
        },
        {
          key: 'icon',
          label: 'Icon',
        },
    
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'position',
          label: 'Position',
        },
        {
          key: 'is_active',
          label: 'Is Active',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      loading: false,
      form: {
        id: '',
        is_active: '',
        position: '',
        name: '',
        type: '',
      },
      status: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'In active',
          value: 0,
        },
      ],
      types: [
        {
          text: 'pht',
          value: 'pht',
        },
        {
          text: 'zcf',
          value: 'zcf',
        },
        {
          text: 'Dental',
          value: 'dental',
        },
      ],
      banners: [
        {
          id: '',
          image: '',
        }
      ],
      condition_we_treat_details: {},
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Condition we treat", route: "/condition-we-treat"},
    ]);
  },
  created() {
    this.getItems();
    localStorage.setItem('tabIndex', 7)
    this.$root.$emit('call-active-menu', 7);
  },
  methods: {
    getItems() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/conditions-we-treat?api_token=${localStorage.getItem('api_token')}&platform=backoffice`).then(res => {
        this.items = res.data.data;
        this.loading = false;
      })
    },
    
    addNewTarget() {
      this.$bvModal.show('addTarget')
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return '../../../public/media/users/blank.png'
      }
    },
    editAction(item) {
      console.log('template-id', item)
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/conditions-we-treat/${item.id}?api_token=${localStorage.getItem('api_token')}`).then(res => {
        this.banners = res.data.data.banners;
        this.loading = false;
        this.form = item;
        this.form.template_id = item.id;
        this.$bvModal.show('addTarget')
      })
      
    },
    resetModal() {
      this.form = {
        id: '',
        is_active: 1,
        name: '',
        position: '',
      };
      this.getItems();
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      // let sales_user_name = this.sales_users.find(item => item.id == this.form.user_id);
      // this.form.user_name = sales_user_name.name;
      let image = this.$refs.image.files[0];
      let icon = this.$refs.icon.files[0];
      // let banner = this.$refs.banner.files[0];
      let form = new FormData();

      form.append('name', this.form.name ? this.form.name : '');
      form.append('is_active', this.form.is_active);
      form.append('position', this.form.position ? this.form.position : '');
      form.append('type', this.form.type ? this.form.type : '');
      if (image) {
        form.append('image', image);
      }
      if (icon) {
        form.append('icon', icon);
      }
      // if (banner) {
      //   form.append('banner', banner);
      // }
      if (this.banners) {
        this.banners.forEach((item, index) => {
          form.append(`banners[${index}][id]`, item.id)
          form.append(`banners[${index}][image]`, item.image ? item.image : '')
        })
      }

      form.append('order', this.form.order ? this.form.order : '');

      this.$nextTick(() => {
        let url = '';
        if(this.form.id){
           url = `${process.env.VUE_APP_API_URL}/api/v2/conditions-we-treat/${this.form.id}?api_token=${localStorage.getItem('api_token')}`;
        }else {

           url = `${process.env.VUE_APP_API_URL}/api/v2/conditions-we-treat?api_token=${localStorage.getItem('api_token')}`;
        }


        axios.post(url, form).then(res => {
          if (res.data.status_code == 200) {
            this.getItems();
            this.$bvModal.hide('addTarget')
            this.$snotify.success('Successfully saved');
          } else {
            this.$snotify.error(res.data.message);
          }
        })
      })
    },
    addMore() {
      this.banners.push({
        id: '',
        image: '',
      });
    },
    remove(index) {
      this.banners.splice(index, 1);
    },
    onFileChange(e, index) {
      if (e.target.files[0]) {
        this.banners[index].image = e.target.files[0];
      }
    },
    removeFromDatabase(banner,index) {
      axios.post(`${process.env.VUE_APP_API_URL}/api/v2/conditions-we-treat/banners/${banner.id}?api_token=${localStorage.getItem('api_token')}&_method=delete`)
      .then(res => {
        if (res.data.status_code == 200) {
            
          this.banners.splice(index, 1);
            
            this.$snotify.success('Successfully delete');
          } else {
            this.$snotify.error(res.data.message);
          }
      })
    },
  }
}
</script>

<style scoped>
.user-image {
  height: 100px;
}
</style>
