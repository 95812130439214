<template>
      <div class="mt-60">
        <div class="row">
            <div class="col-md-4">
                <div class="c-card">
                    <div class="filter-section">
                        <form>
                            <ul class="filter-list">
                            <li>
                                <b-button @click="selectFilter('Month')" class="coll-btn">{{ filter.month ?  filter.month : 'Month' }} {{ filter.year }} <i class="fas fa-chevron-down"></i></b-button>
                            </li>
                            <li>
                                <b-button @click="selectFilter('Center')" class="coll-btn"
                                >{{ filter.center || 'All Center' }} <i class="fas fa-chevron-down"></i></b-button
                                >
                            </li>
                            </ul>

                            <b-collapse id="collapse-1" v-model="showMonthFilter">
                              <ul class="month-selection-list">
                                <li v-for="(item, key) in months" :key="key">
                                  <a v-if="!['Q1', 'Q2', 'Q3', 'Q4'].includes(item.text)" href="javascript:void(0)" :class="item.text == filter.month?'active':''" @click="selectPeriod('monthly',item.text); showMonthFilter=false; getItems()">{{ item.text }}  </a>
                                  <a v-else href="javascript:void(0)" :class="item.value == filter.quarter?'active':''" @click="selectPeriod('quarterly',item.value); showMonthFilter=false; getItems()">{{ item.text }} </a>
                                </li>
                                
                              </ul>
                              <ul class="year-selection-list">
                                <li>
                                  <a href="javascript:void(0)" :class="filter.year == year ?'active':''" @click="filter.year = year; getItems()" v-for="(year, index) in years"  :key="index">{{  year }}</a>
                                </li>
                              </ul>
                            </b-collapse>

                            <b-collapse id="collapse-2" v-model="showCenterFilter">
                                <ul class="center-selection-list">
                                    <li>
                                    <a href="javascript:void(0)" :class="filter.center==''?'active':''"
                                        @click="filter.center = ''; showCenterFilter=false; getItems()">All</a>
                                    </li>
                                    <li v-for="(item, key) in centers" :key="key">
                                    <a href="javascript:void(0)" :class="item.short_form == filter.center?'active':''"
                                        @click="filter.center = item.short_form; showCenterFilter=false; getItems()">{{
                                        item.short_form
                                        }}</a>
                                    </li>
                                </ul>
                            </b-collapse>

                        </form>
                        </div>
                    <div class="target-content">
                        <div>
                          <ul>
                          <li class="mt-3">
                            <div>
                              <div class="l-label">
                                <label>Total Reports</label>
                              </div>
                              <div class="r-label">
                                <span class="target">{{ total_count }}</span>
                              </div>
                            </div>
                          </li>
                        </ul>    
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="c-card p-7">
                
                    <div class="patient-income-table tableFixHead">
                    <table class="timeline-tbl" ref="table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Center</th>
                            <th>Designation</th>
                            <th>Department</th>
                            <th>Submission date</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody v-if="items.length > 0 ">
                            <tr v-for="(item,index) in items" :key="index">
                                <td>{{  item.name }}</td>
                                <td>{{  item.center }}</td>
                                <td>{{  item.designation }}</td>
                                
                                <td>{{  item.department }}</td>
                                <td>{{  item.submission_date }}</td>
                                <td>
                                    <b-btn variant="info" size="small" @click="$router.push('/occ/quality-control/'+item.id)" class="btn-sm">
                                      <i class="fa fa-eye"></i>
                                    </b-btn>
                                </td>
                            </tr>
                        </tbody>
                        
                        
                    </table>
                    </div>
                    <div class="row mt-3" v-if="total_count">
                        <div class="col-md-12">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="total_count"
                            :per-page="perPage"
                            size="lg"
                            @change="getItems"
                            class="justify-content-center"
                        ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from "moment";
export default {
    name: 'QuilityControlReport',
    data(){
      let all_months = [
      {
          text: "Q1",
          value: "1"
        },
        {
          text: "Jan",
          value: "January",
        },
        {
          text: "Feb",
          value: "February",
        },
        {
          text: "Mar",
          value: "March",
        },
        {
          text: "Q2",
          value: "2"
        },
        {
          text: "Apr",
          value: "April",
        },
        {
          text: "May",
          value: "May",
        },
        {
          text: "Jun",
          value: "June",
        },
        {
          text: "Q3",
          value: "3"
        },
        {
          text: "Jul",
          value: "July",
        },
        {
          text: "Aug",
          value: "August",
        },
        {
          text: "Sep",
          value: "September",
        },
        {
          text: "Q4",
          value: "4"
        },
        {
          text: "Oct",
          value: "October",
        },
        {
          text: "Nov",
          value: "November",
        },
        {
          text: "Dec",
          value: "December",
        },
      ];
        return  {
            currentPage: 1,
            total_count: 0,
            perPage: 20,
            patients: [],
            showCenterFilter: false,
            showMonthFilter: false,
            filter: {
                platform: '',
                period: 'monthly',
                month: '',
                center: '',
                year: '',
                quarter: '',
            },
            months: all_months,
            loading: false,
            start_year: 2018,
            month: all_months[new Date().getMonth()].value,
            year: new Date().getFullYear(),
            years: [],
            items: [],
            centers: [],
        }
    },
    created() {
      this.filter.month = moment().format('MMM'),
      this.filter.year = moment().format('Y'),
        this.getItems();
        this.setYears(8);
        this.centerList();
    },
    methods: {
        selectPeriod(type, value) {
            this.filter.period = type;
            if (type == 'monthly') {
              this.filter.quarter = '';
              this.filter.month = value;
            } else {
              this.filter.month = '';
              this.filter.quarter = value;
            }
        },
        selectFilter(type) {
            if (type == "Center") {
                this.showMonthFilter = false;
                this.showCenterFilter = !this.showCenterFilter;
            } else if (type == "Month") {
              this.showCenterFilter = false;
              this.showMonthFilter = !this.showMonthFilter;
            }
        },
        getItems(value) {
            
            this.loading = true;
            if (Number(value)) {
                this.currentPage = value;
                var offset = value - 1;
                var correctValue = offset * this.perPage;
            }
            let selectedCenter = null;
            if (this.filter.center) {
                selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
            }
            
        
            let url = `${process.env.VUE_APP_URL}/api/v2/orko/center/audit/reports?period=${this.filter.period}${selectedCenter ? '&institute_id=' + selectedCenter.id : ''}&api_token=${localStorage.getItem('api_token')}${this.filter.month ? '&month=' +  this.filter.month : ''}&limit=${this.perPage}${correctValue ? '&offset=' + correctValue : ''}&year=${this.filter.year}${this.filter.quarter?'&quarter='+this.filter.quarter:''}`;
            axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                    this.$router.push('/access-denied')
                    } else {
                    this.items = response.data.data;
                    this.total_count = response.data.total_count;
                    this.loading = false;
                    }
                })
        },
        dashesToSpace(str) {
            return  str.replace(/-/g, " ");
        },
        centerList() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
            .then(response => {
                if (response.data.status_code == '500') {
                this.$router.push('/access-denied')
                } else {
                // this.loading = false
                this.centers = response.data.data
                }
            })
        },
        setYears(num) {
          for (let i = 0; i < num; i++) {
            this.years.push(this.start_year + i);
          }
        },
    }
}
</script>
<style lang="scss" scoped>
.c-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #fff;
  width: 100%;
  float: left;

  .filter-list {
    width: 100%;
    float: left;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      width: 50%;
      float: left;
      padding: 0px 10px 0;
      border-right: 2px solid #002170;
      text-align: center;
      list-style: none;

      &:last-of-type {
        border-right: none;
        padding: 0;
      }

      select {
        border: none;
        outline: none;
        scroll-behavior: smooth;
        -webkit-appearance: listbox !important;

        color: #002170;
        font-size: 15px;
        font-weight: 600;
        font-family: Arial;
        padding: 0 12px 0 8px;
        height: 22px;
      }
    }
  }

  .year-selection-list {
  width: 100%;
 
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  li {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      background-color: #d9d4d4;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;
      margin: 5px;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}
  .month-selection-list {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin-top: 20px;

    li {
      width: 25%;
      float: left;
      text-align: center;
      margin-bottom: 20px;

      a {
        background-color: #EBEBEB;
        padding: 7px 25px;
        border-radius: 20px;
        color: #000;
        font-family: Arial;

        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }

      &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
        a {
          background-color: #AAAAAA;
          color: #fff;

          &.active {
            background-color: #F9A81A;
            font-weight: 600;
          }
        }
      }
    }
  }
// .content-title {
//   color: #FFA800 !important;
//   font-size: 20x;
//     font-family: Arial;
//     font-weight: 700;
// }

.target-content {
    width: 100%;
    float: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #00579b;
    padding: 25px;

    h2 {
      color: #f9a91a;
      font-family: Arial;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: none;
      margin-bottom: 20px;
      width: 100%;
      float: left;

      li {
        width: 100%;
        float: left;

        .l-label {
          width: 45%;
          float: left;
        }

        .r-label {
          text-align: right;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          font-family: Arial;

          .achieved {
            color: #f9a91a;
          }
        }

        label {
          color: #fff;
          font-size: 18px;
          font-family: Arial;
          font-weight: 600;
        }
      }
    }
  }

  .timeline-tbl {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    float: left;

    .expanded-row {
      box-shadow: 0 0 0 1px #AAA;
      border-radius: 20px;
    }
    thead {
      
    }

    tr {
      th {
        color: #002147;
        border-bottom: 1px solid #a0a0a1;
        padding-bottom: 10px;
        // text-align: center;
        font-size: 15px;
      }

      td {
        vertical-align: top !important;
      }
    }

    tbody {
      tr {
        // margin-bottom: 5px;
        &.expanded-total-row {
          border-top: 1px solid #AAA;

          td {
            padding-bottom: 5px;
            ul {
              margin: 6px 0;
              list-style-type: none;

              li {
                line-height: 1.6;

                &:last-of-type {
                  font-size: 13px;

                  span {
                    font-size: 13px;

                    i {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }

        td {
        //   text-align: center;
        padding-bottom: 5px;
          font-size: 16px;
          line-height: 1.5;
          color: #212120;
        }

        &:first-of-type {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .summary-wrapper {
    width: 100%;
    float: left;
    background-color: #00579b;
    padding: 8px 30px;
    border-radius: 20px;
    margin-top: 30px;

    .summary-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;

      tr {
        th {
          color: #fff;
          font-size: 17px;
          padding-bottom: 5px;
        }

        td {
          font-size: 14px;
          color: #fff;
          font-weight: 600;
          vertical-align: top !important;
        }
      }
    }
  }
}

.brd-15 {
  border-radius: 15px;
}

.ftb-15 {
  font-size: 15px;
  font-weight: 600;
}

.up-text {
  color: #00cd32;
  font-size: 15px;
  font-weight: 600;

  i {
    color: #00cd32;
    font-size: 13px;
  }
}

.down-text {
  color: red;
  font-size: 15px;
  font-weight: 600;

  i {
    color: red;
    font-size: 13px;
  }
}

.coll-btn {
  color: #002170;
  font-size: 15px;
  font-weight: 600;
  font-family: Arial;
  padding: 0 12px 0 8px;
  // height: 22px;

  background-color: transparent;
  border: none;

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.platform-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 33%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;

        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}

.center-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 33%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}
.center-selection-list-more-items {
  width: 100%;
  display: flex;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  li {
  
    text-align: center;
    margin: 10px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}

.patient-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 50%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 8px 30px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}

.patient-income-table {
  height: 500px;
  overflow: scroll;
  // padding: 2px;
}

.target-content {
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;

  p {
    margin: 5px 0px;
  }

  ul {
    list-style-type: none !important;
    // display: flex;
    margin-left: 0px;
    font-weight: bold;
    font-size: 17px;
  }
}

.patient_image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.patient-profile {
  width: 100%;
  float: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px 15px 3px 0px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #00579b;
  padding: 25px;
  color: #ffffff;

  .patient-info {
    padding-left: 20px;

    p {
      font-size: 14px;
      font-weight: 500;
      margin: 5px 0px;
    }
  }

}

.patient-tab {
  width: 100%;
  float: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  padding: 25px;
}

.patient-remove {
  position: absolute;
  right: 25px;
  top: 10px;
}

.male-female-icon {
  height: 70px;
  margin-right: 20px;
}

.patient-card {
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
  padding-right: 30px;
}

.patient-card div {
  margin-left: 15px;
  margin-bottom: 10px;
}

.patient-card div p {
  line-height: 20px;
}

.patient-card img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.patient-tab {
  background: #fff;
  margin-top: -15px;
}

.custom-tab-design {
  color: #000000 !important;
  background-color: #fff !important;
  /* border-color: #E4E6EF #E4E6EF #ffffff; */
  border-bottom: 2px solid #9b9999 !important;
  box-shadow: none !important;
}

.image_size_responsive {
  //height: 100px;
  width: 100px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 2px;
  margin-right: 2px;
}

.prescriptions {
  border: 1px solid #ddd;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px;
  position: relative;
}

.prescriptions .content {
  margin-top: 10px;
  //margin-left: 10px;
  //text-align: center !important;
}

.prescriptions-label {
  position: absolute;
  top: -11px;
  background: #ffff;
  padding: 0px 10px;
}

.color-red {
  color: red;
  font-size: 35px;
}

.row-active {
  background: #f6f6f6;
  border-radius: 15px;
}

.prescriptions-content {
  line-height: 15px;
}
.marget_activation {
  background: red;
}
.progressbar-subtitle {
  font-size: 10px;
  font-weight:500;
}
.custom_variant_yellow {
  background-color: #ffd37f !important;
}
.custom_market_activation_bg {
  background-color: #FFD500 !important;
}
.corporate_engangement_bg {
  background-color: #66F755 !important;
}
.default_progressbar_bg {
  background-color: #FFFFFF !important;
}
.reg_to_consult {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  margin-left: 10px;
}
.conversion_calculate {
  font-size:14px;
  font-weight: bold;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.progress_bar_bullet {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  p {
    margin-left: 10px;
  }
  span {
    height: 11px;
    width: 11px;
    display: inline-block;
    margin-right: 5px;
  }
  .doctor_bullet {
    background: #FFA800;
  }
  .market_activation_bullet {
    background: #FFD500;
  }
  .corporate_engagement_bullet {
    background: #66F755;
  }
  .other_bullet {
    background: #FFFFFF;
  }
}
.custom-card-body {
  padding: 10px 10px !important;
  color: #000 !important;
}
.card-div {
  height: 500px;
  overflow-x: scroll;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.card-div ::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.card-div ::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.card-div ::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.font-size-14 {
  font-size: 15px;
}
.custom-btn-bg {
  background-color: #00579b !important;
}
.text-capitalize {
  text-transform: capitalize;
}
.bg-gray {
  background-color: #dfe1e5 !important;
}
.item-content {
  line-height: 20px;
}

.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}
.route-name {
    font-size: 10px;
}
</style>
<style>
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #000;
  border-color: #ffffff #ffffff #000000;
}
.patient-tab .nav-tabs {
  flex-wrap: initial !important;
  overflow: scroll;
}
.patient-tab .nav-tabs .nav-item a {
  text-wrap: nowrap;
}
.details-pagination {
    color: #000 !important;
}
</style>
