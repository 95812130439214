<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <button type="button" class="btn btn-primary" @click="addNewTarget">{{ is_edit ? 'Edit' : 'Add new' }} Target</button>
      <div class="row mt-5 align-items-center justify-content-between">
        <div class="col-md-3">
          <label for="">Select Center</label>
          <b-form-select
              id="doctor_type"
              v-model="filter.institute_id"
              :options="centers"
              text-field="name"
              value-field="id">
          </b-form-select>
        </div>
        <div class="col-md-2">
          <label for="monnth">Month</label>
          <b-form-select id="month" v-model="filter.start_date" :options="months"
                             ></b-form-select>
        </div>
        <div class="col-md-2">
          <label for="year">Year</label>
          <b-form-select
            id="year"
            v-model="filter.end_date"
            :options="years">
          </b-form-select>
        </div>
        <div class="col-md-2">
          <label for="">Select Service</label>
          <b-form-select
              id="doctor_type"
              value-field="id"
              text-field="name"
              v-model="filter.product_type"
              :options="service_types">
          </b-form-select>
        </div>
        <div class="col-md-2 mt-7">
          <button class="btn btn-success" @click="getItems()" v-if="year && month">Filter</button>
          <button class="btn btn-success" disabled v-else>Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
                        <template #cell(month)="row">
                          <div>
                            {{ convertMonth(row.item.month) }}
                          </div>
                        </template>

                        <template #cell(service_type)="row">
                            {{ (row.item.service_type?serviceConv[row.item.service_type]:'')}}
                        </template>
            <template #cell(action)="row">
              <div>
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <div>
                    <b-dropdown-item href="#" @click="editAction(row.item)">
                      <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="viewAction(row.item)">
                      <span class="pr-4"><i class="fas fa-eye"></i></span>view
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </div>
            </template>
          </b-table>

          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              @change="getItems"
              class="my-0"
              aria-controls="Targets"
          ></b-pagination>
        </div>
      </div>
      <b-modal
          id="addTarget"
          ref="modal"
          :title="`${this.form.id ? 'Update' : 'Add new'} target`"
          @hidden="resetModal"
          @ok="handleOk"
          size="lg">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="6" class="mb-3">
              <label for="doctor_type">Select Center</label>
              <b-form-select
                  id="doctor_type"
                  v-model="form.institute_id"
                  :options="centers"
                  text-field="name"
                  value-field="id"
                  :disabled="is_edit">
              </b-form-select>
            </b-col>
            <b-col cols="6" class="mb-3">
              <label for="doctor_type">Select service</label>
              <b-form-select
                  @change="changeServiceType()"
                  v-model="form.service_type"
                  :options="service_types"
                  text-field="name"
                  value-field="id"
                  :disabled="is_edit"
                  ></b-form-select>
            </b-col>
            <b-col cols="6">
              <label for="doctor_type">Month</label>
              <b-form-select id="doctor_type" v-model="form.month" :options="months"
                             :disabled="this.form.id ? true : false"></b-form-select>
            </b-col>
            <b-col cols="6">
              <label for="year">Year</label>
              <b-form-select
                  id="doctor_type"
                  v-model="form.year"
                  :options="years" :disabled="is_edit">
              </b-form-select>
            </b-col>
          </b-row>
          <br>
          <b-row v-if="form.services_new.length" style="margin-bottom: 25px">
            <b-col cols="12">
              <h6 style="font-weight: 600; text-decoration: underline; color: #000; margin-bottom: 10px;">New Targets:</h6>
              <table style="width: 100%;">
                <thead>
                  <th>Service</th>
                  <th>Target (Patient)</th>
                  <th>Target Income (Tk.)</th>
                </thead>
                <tbody>
                  <tr v-for="(service, index) in form.services_new" :key="index">
                    <td>
                      <b-form-input class="custom-height" v-model="service.name" disabled></b-form-input>
                    </td>
                    <td>
                      <b-form-input class="custom-height" v-model="service.target"></b-form-input>
                    </td>
                    <td>
                      <b-form-input class="custom-height" v-model="service.target_income"></b-form-input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row v-if="form.services.length">
            <b-col cols="12">
              <h6 style="font-weight: 600; text-decoration: underline; color: #000; margin-bottom: 10px;">Old Targets:</h6>
              <table style="width: 100%;">
                <thead>
                  <th>Service</th>
                  <th>Target</th>
                  <th>Target Income (Tk.)</th>
                </thead>
                <tbody>
                  <tr v-for="(service, index) in form.services" :key="index">
                    <td>
                      <b-form-input class="custom-height" v-model="service.name" disabled></b-form-input>
                    </td>
                    <td>
                      <b-form-input class="custom-height" v-model="service.target" @input="updateConsultation(service, 'target')"></b-form-input>
                    </td>
                    <td>
                      <b-form-input class="custom-height" v-model="service.target_income" @input="updateConsultation(service, 'target_income')"></b-form-input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </form>
      </b-modal>
      <b-modal
          id="viewTarget"
          ref="modal"
          :title="`${this.form.id ? 'View' : 'Add new'} target`"
          hide-footer
          size="lg">
          <b-row class="mb-4">
            <b-col cols="6" class="mb-3">
              <h5 class="font-weight-light">Center:</h5>
              <h6 class="font-weight-bold">{{ form.institute_name  }}</h6>
            </b-col>
            <b-col cols="6">
              <h5 class="font-weight-light">Service:</h5>
              <h6 class="font-weight-bold">{{ form.service_type  }}</h6>
            </b-col>
            <b-col cols="6">
              <h5 class="font-weight-light">Periods</h5>
              <h6 class="font-weight-bold">{{ form.month }} - {{ form.year }}</h6>
            </b-col>
          </b-row>

          <b-row v-if="form.services_new.length" style="margin-bottom: 25px;">
            <b-col cols="12">
              <h6 style="font-weight: 600; text-decoration: underline; color: #000; margin-bottom: 10px;">New Targets</h6>
              <table style="width: 100%;">
                <thead>
                  <th>Service</th>
                  <th>Target (Patient)</th>
                  <th>Target Income (Tk.)</th>
                </thead>
                <tbody class="pt-4">
                  <tr v-for="(service, index) in form.services_new" :key="index">
                    <td class="p-3 text-center">
                      {{ service.name }}
                    </td>
                    <td class="p-3 text-center">
                      {{ service.target }}
                    </td>
                    <td class="p-3 text-center">
                      {{ service.target_income }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>

          <b-row v-if="form.services.length">
            <b-col cols="12">
              <h6 style="font-weight: 600; text-decoration: underline; color: #000; margin-bottom: 10px;">Old Targets</h6>
              <table style="width: 100%;">
                <thead>
                  <th>Service</th>
                  <th>Target</th>
                  <th>Target Income (Tk.)</th>
                </thead>
                <tbody class="pt-4">
                  <tr v-for="(service, index) in form.services" :key="index">
                    <td class="p-3 text-center">
                      {{ service.name }}
                    </td>
                    <td class="p-3 text-center">
                      {{ service.target }}
                    </td>
                    <td class="p-3 text-center">
                      {{ service.target_income }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
      </b-modal>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';

export default {
  name: "OrkoCenterTarget",
  data() {
    let all_months = [
      {
        id: 1,
        text: 'January',
        value: 'January'
      },
      {
        id: 2,
        text: 'February',
        value: 'February'
      },
      {
        id: 3,
        text: 'March',
        value: 'March'
      },
      {
        id: 4,
        text: 'April',
        value: 'April'
      },
      {
        id: 5,
        text: 'May',
        value: 'May'
      },
      {
        id: 6,
        text: 'June',
        value: 'June'
      },
      {
        id: 7,
        text: 'July',
        value: 'July'
      },
      {
        id: 8,
        text: 'August',
        value: 'August'
      },
      {
        id: 9,
        text: 'September',
        value: 'September'
      },
      {
        id: 10,
        text: 'October',
        value: 'October'
      },
      {
        id: 11,
        text: 'November',
        value: 'November'
      },
      {
        id: 12,
        text: 'December',
        value: 'December'
      },
    ];
    return {
      items: [],
      fields: [
        {
          key: 'institute_name',
          label: 'Center',
        },
        {
          key: 'service_type',
          label: 'Service Type',
        },
        {
          key: 'month',
          label: 'Month',
        },
        {
          key: 'year',
          label: 'Year',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      loading: false,
      form: {
        id: '',
        institute_id: '',
        product_type: '',
        month: '',
        year: '',
        target: '',
        target_income: '',
        services: [],
        services_new: [],
      },
      filter: {
        institute_id: '',
        product_type: '',
        month: '',
        year: '',
        target: '',
        start_date: null,
        end_date: null,
      },
      months: all_months,
      current_year: new Date,
      start_year: 2022,
      month: all_months[new Date().getMonth()].value,
      year: new Date().getFullYear(),
      years: [],
      centers: [],
      serviceConv: { general: 'General', zero_club_foot: 'Zero Clubfoot', physio_therapy: 'Physiotherapy', dental: 'Dental', cdu: 'CDU'},
      service_types:[
        {id:'general', name:'General'},
        {id:'zero_club_foot', name: 'Zero Clubfoot'},
        {id:'physio_therapy', name: 'Physiotherapy'},
        {id:'dental', name: 'Dental'},
        {id:'cdu', name: 'CDU'}
      ],
      services: [],
      services_new: [],
      prodouct_types:
        {
            "general": [
              {
                name: 'Patient registration',
                product_type: 'patient_registration',
              }
            ],
            "zero_club_foot": [
                {
                  name: 'brace Pkg',
                  product_type: 'brace Pkg',
                },
                {
                  name: 'Brace',
                  product_type: 'brace',
                },
                {
                  name: 'Ponseti (Plaster) Pkg',
                  product_type: 'Ponseti (Plaster) Pkg'
                },
                {
                  name: 'Ponseti (Plaster)',
                  product_type: 'Ponseti (Plaster)'
                },
                {
                  name: 'Ponseti (Surgery)',
                  product_type: 'Ponseti (Surgery)'
                },
                {
                  name: 'Ponseti (Tenotomy) Pkg',
                  product_type: 'Ponseti (Tenotomy) Pkg'
                },
                {
                  name: 'Ponseti (Tenotomy)',
                  product_type: 'Ponseti (Tenotomy)'
                },
                {
                  name: 'Registration fee',
                  product_type: 'registration_fee'
                },
                {
                  name: 'ZCF OP',
                  product_type: 'zcf_op'
                },
                {
                  name: 'Clubfoot appointment',
                  product_type: 'clubfoot_appointment'
                },
                {
                  name: 'Clubfoot consultation',
                  product_type: 'clubfoot_consultation'
                },
            ],
            "zero_club_foot_new": [
                {
                  name: 'Treatment',
                  product_type: 'Treatment',
                },
                {
                  name: 'Followup',
                  product_type: 'Followup',
                }
            ],
            "physio_therapy":[
                {
                  name: 'Physio NP',
                  product_type: 'physio_np'
                },
                {
                  name: 'Therapy appointment',
                  product_type: 'therapy_appointment'
                },
                {
                  name: 'Therapy consultation',
                  product_type: 'therapy_consultation'
                },
                {
                  name: 'Therapy session',
                  product_type: 'therapy_session'
                },
                {
                  name: 'Assistive device',
                  product_type: 'assistive_device'
                },
            ],
            "physio_therapy_new": [
                {
                  name: 'Treatment',
                  product_type: 'Treatment',
                },
                {
                  name: 'Followup',
                  product_type: 'Followup',
                }
            ],
            "dental":[
                {
                  name: 'Dental appointment',
                  product_type: 'dental_appointment'
                },
                {
                  name: 'Dental consultation',
                  product_type: 'dental_consultation'
                },
                {
                  name: 'Procedure',
                  product_type: 'Dental'
                },
            ],
            "dental_new": [
                {
                  name: 'Treatment',
                  product_type: 'Treatment',
                },
                {
                  name: 'Followup',
                  product_type: 'Followup',
                }
            ],
            "cdu":[
                {
                  name: 'CDU appointment',
                  product_type: 'cdu_appointment'
                },
                {
                  name: 'CDU consultation',
                  product_type: 'cdu_consultation'
                }
            ],
            "cdu_new": [
                {
                  name: 'Treatment',
                  product_type: 'Treatment',
                },
                {
                  name: 'Followup',
                  product_type: 'Followup',
                }
            ],
        },
      targeted_services: [],
      is_edit: false,
      total_count: 0,
      currentPage: 1,
      perPage: 10,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "OCC Target", route: "/occ/target"},
    ]);
  },
  created() {
    this.getItems();
    this.getServices();
    this.centerList();
    this.setYears(15);
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
  },

  methods: {
    updateConsultation(service, field) {
      if(service.product_type == 'clubfoot_appointment') {
        this.form.services = this.form.services.map((item) => {
          if(item.product_type == 'clubfoot_consultation') {
            item[field] = service[field]?Math.floor(service[field]/2):0
          }
          return item;
        })  
      } else if(service.product_type == 'therapy_appointment') {
        this.form.services = this.form.services.map((item) => {
          if(item.product_type == 'therapy_consultation') {
            item[field] = service[field]?Math.floor(service[field]/2):0
          }
          return item;
        })  
      } else if(service.product_type == 'dental_appointment') {
        this.form.services = this.form.services.map((item) => {
          if(item.product_type == 'dental_consultation') {
            item[field] = service[field]?Math.floor(service[field]/2):0
          }
          return item;
        })  
      } else if(service.product_type == 'cdu_appointment') {
        this.form.services = this.form.services.map((item) => {
          if(item.product_type == 'cdu_consultation') {
            item[field] = service[field]?Math.floor(service[field]/2):0
          }
          return item;
        })  
      }

    },
    getItems(value) {
      this.loading = true;
      if (value) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      let productType = this.filter.product_type;
      if (productType == 'Brace') {
        productType = 'brace';
      } else if (productType == 'Registration Fee') {
        productType = 'registration_fee';
      } else if (productType == 'Appointment') {
        productType = 'appointment';
      } else if (productType == 'Therapy Appointment') {
        productType = 'therapy_appointment';
      } else if (productType == 'Therapy Session') {
        productType = 'therapy_session';
      } else if (productType == 'Clubfoot Appointment') {
        productType = 'clubfoot_appointment';
      } else if (productType == 'Assistive Device') {
        productType = 'assistive_device';
      }
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/report/orkocenter/targets?api_token=${localStorage.getItem('api_token')}
      ${this.filter.institute_id ? '&institute_id=' + this.filter.institute_id : ''}${this.filter.product_type ? '&service_type=' + this.filter.product_type : ''}
      ${this.filter.start_date ? '&month=' + this.filter.start_date : ''}${this.filter.end_date ? '&year=' + this.filter.end_date : ''}&limit=${this.perPage}${correctValue ? '&offset=' + correctValue : ''}`).then(res => {
        this.items = res.data.data;
        this.total_count = res.data.total_count;
        this.loading = false;
      })
    },
    getServices() {
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/orko-services?api_token=${localStorage.getItem('api_token')}`)
          .then((res) => {
            this.services = res.data.data;
            this.services.push({
              group_name: 'appointment',
              name: 'appointment',
            });
            this.services.push({
              group_name: 'clubfoot_appointment',
              name: 'Clubfoot Appointment',
            });
            this.services.push({
              group_name: 'therapy_appointment',
              name: 'Therapy Appointment',
            });

            this.services.push({
              group_name: 'therapy_session',
              name: 'Therapy Session',
            });

            this.services.push({
              group_name: 'assistive_device',
              name: 'Assistive Device',
            });

            this.services.push({
              group_name: 'patient_registration',
              name: 'Patient Registration',
            });
            this.services.push({
              group_name: 'patient_appointment',
              name: 'Patient Appointment',
            });
            this.services.push({
              group_name: 'patient_consultation',
              name: 'Patient Consultation',
            });

            if (res.data.status_code != 200) {
              this.$notify({
                title: 'Failed',
                message: res.data.message,
                type: 'error',
                duration: 2000,
              });
            }
          });
    },

    changeServiceType(){
      this.form.services = this.prodouct_types[this.form.service_type];
      this.form.services_new = this.prodouct_types[this.form.service_type+'_new'];
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.loading = false
              this.centers = response.data.data
            }
          })
    },
    addNewTarget() {
      this.$bvModal.show('addTarget')
     
    },

    convertMonth(month) {
      const mn = this.months.find(ite => ite.id == month);
      return mn.text;
    },

    editAction(item) {
      this.$bvModal.show('addTarget')
      this.is_edit = false;
      this.form.id = item.id;
      this.form.institute_id = item.institute_id;
      this.form.year = item.year;
      this.form.service_type = item.service_type;
      const mn = this.months.find(ite => ite.id == parseInt(item.month));
      this.form.month = mn.text;
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/report/orkocenter/target/items?api_token=${localStorage.getItem('api_token')}&institute_id=${item.institute_id}&month=${item.month}&year=${item.year}&service_type=${item.service_type}&type=orko`)
      .then(res => {
        
          this.form.services = res.data.data.filter(item => {
            if(item.product_type != 'Treatment' && item.product_type != 'Followup') {
              return item;
            }
          })
          this.form.services_new = res.data.data.filter(item => {
            if(item.product_type == 'Treatment' || item.product_type == 'Followup') {
              return item;
            }
          })
          
          this.form.services.forEach((item3,index) => {
              const  findName = this.prodouct_types[item.service_type].find(findName => findName.product_type == item3.product_type);
              if(findName) {
                this.form.services[index].name = findName.name;
              }
          })

          this.form.services_new.forEach((item3,index) => {
              const  findNameNew = this.prodouct_types[item.service_type+'_new'].find(findNameNew => findNameNew.product_type == item3.product_type);
              if(findNameNew) {
               this.form.services_new[index].name = findNameNew.name;
              }
          })

          this.prodouct_types[item.service_type].forEach(product => {
            const  checkService = this.form.services.find(serv => serv.product_type == product.product_type);  
            if(!checkService) {
              this.form.services.push({id:'', name: product.name, product_type: product.product_type, target: 0, target_income: 0});
            }
          })

          this.prodouct_types[item.service_type+'_new'].forEach(product => {
            const  checkServiceNew = this.form.services_new.find(serv => serv.product_type == product.product_type);  
            if(!checkServiceNew) {
              this.form.services_new.push({id:'', name: product.name, product_type: product.product_type, target: 0, target_income: 0});
            }
          })
        // console.log('dsd',this.prodouct_types[item.service_type], 'name', this.form.services['name']);
          this.loading = false;
      })
    },
    viewAction(item) {
      this.$bvModal.show('viewTarget')
      this.is_edit = false;
      this.form.id = item.id;
      this.form.institute_id = item.institute_id;
      this.form.institute_name = item.institute_name
      this.form.year = item.year;
      this.form.service_type = item.service_type;
      const mn = this.months.find(ite => ite.id == parseInt(item.month));
      this.form.month = mn.text;
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/report/orkocenter/target/items?api_token=${localStorage.getItem('api_token')}&institute_id=${item.institute_id}&month=${item.month}&year=${item.year}&service_type=${item.service_type}&type=orko`)
      .then(res => {
        
        //   this.form.services = res.data.data
          
        //   res.data.data.forEach((item3,index) => {
        //     const  findName = this.prodouct_types[item.service_type].find(findName => findName.product_type == item3.product_type);
        //     this.form.services[index].name = findName.name;
        // })

        this.form.services = res.data.data.filter(item => {
            if(item.product_type != 'Treatment' && item.product_type != 'Followup') {
              return item;
            }
          })
          this.form.services_new = res.data.data.filter(item => {
            if(item.product_type == 'Treatment' || item.product_type == 'Followup') {
              return item;
            }
          })
          
          this.form.services.forEach((item3,index) => {
              const  findName = this.prodouct_types[item.service_type].find(findName => findName.product_type == item3.product_type);
              if(findName) {
                this.form.services[index].name = findName.name;
              }
          })

          this.form.services_new.forEach((item3,index) => {
              const  findNameNew = this.prodouct_types[item.service_type+'_new'].find(findNameNew => findNameNew.product_type == item3.product_type);
              if(findNameNew) {
               this.form.services_new[index].name = findNameNew.name;
              }
          })

        // console.log('dsd',this.prodouct_types[item.service_type], 'name', this.form.services['name']);
          this.loading = false;
      })
    },

    setYears(num) {
      for (let i = 0; i < num; i++) {
        this.years.push(this.start_year + i);
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.form = {services:[], services_new: []};
      this.is_edit = false;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      if (this.form.product_type == 'Brace') {
        this.form.product_type = 'brace';
      } else if (this.form.product_type == 'Registration Fee') {
        this.form.product_type = 'registration_fee';
      } else if (this.form.product_type == 'Appointment') {
        this.form.product_type = 'appointment';
      } else if (this.form.product_type == 'Therapy Appointment') {
        this.form.product_type = 'therapy_appointment';
      } else if (this.form.product_type == 'Therapy Session') {
        this.form.product_type = 'therapy_session';
      } else if (this.form.product_type == 'Clubfoot Appointment') {
        this.form.product_type = 'clubfoot_appointment';
      } else if (this.form.product_type == 'Assistive Device') {
        this.form.product_type = 'assistive_device';
      } else if (this.form.product_type == 'Patient Registration') {
        this.form.product_type = 'patient_registration';
      } else if (this.form.product_type == 'Patient Appointment') {
        this.form.product_type = 'patient_appointment';
      } else if (this.form.product_type == 'Patient Consultation') {
        this.form.product_type = 'patient_consultation';
      }
      this.$nextTick(() => {
        let url = '';
        if (this.form.id) {
          url = `${process.env.VUE_APP_API_URL}/api/v2/report/orkocenter/targets/update?api_token=${localStorage.getItem('api_token')}&_method=put`;
        } else {
          url = `${process.env.VUE_APP_API_URL}/api/v2/report/orkocenter/targets?api_token=${localStorage.getItem('api_token')}`;
        }
        axios.post(url, this.form).then(res => {
          if (res.data.status_code == 200) {
            this.getItems();
            this.$bvModal.hide('addTarget')
            this.$snotify.success('Successfully saved');
          } else {
            this.$snotify.error(res.data.message);
          }
        })
      })
    },
    clear() {
      this.filter = {};
      this.getItems();
    }
  }

}
</script>

<style scoped>
table  th {
    color: #002147;
    border-bottom: 1px solid #a0a0a1;
    padding-bottom: 10px;
    text-align: center;
    font-size: 15px;
}
table td {
  padding: 5px;
}
.custom-height {
  height: 30px !important;
}
</style>
