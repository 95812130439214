<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <div class="row mt-5 align-items-center justify-content-between">

        <div class="col-md-3">
          <label for="">From</label>
          <input type="date" v-model="filter.start_date" class="form-control">
        </div>
        <div class="col-md-3">
          <label for="">To</label>
          <input type="date" v-model="filter.end_date" class="form-control">
        </div>
        <div class="col-md-2">
          <b-form-checkbox
            v-model="filter.is_called"
            name="checkbox-1"
            :value="1"
            :unchecked-value="0"
            @change="getItems()"
          >
            Is Called?
          </b-form-checkbox>
        </div>
        <div class="col-md-2 mt-7">
          <button class="btn btn-success" @click="filterData()">Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
        <div class="col-md-1 mt-7">
          <button class="btn btn-primary" @click="exportData()">Export</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              tbody-tr-class="rowClass"
              class="mt-5"
              @row-clicked="therapySurvery"
          >
              <template #cell(otp_verified)="row">
              <b-badge :variant=" row.item.otp_verified == 1 ? 'success' : 'warning'">
                {{ row.item.otp_verified == 1 ? 'Yes' : 'No' }}
              </b-badge>
            </template>
            <template #cell(is_called)="row">
              <b-badge :variant=" row.item.is_called == 1 ? 'success' : 'warning'">
                {{ row.item.is_called == 1 ? 'Yes' : 'No' }}
              </b-badge>
            </template>
            <template #cell(age)="row">
              <div>
                {{ row.item.age ? row.item.age.y +'y ' + row.item.age.m + 'm ' + row.item.age.d+ 'd' : '' }}
              </div>
            </template>

            <template #cell(called_at)="row">
              <div v-if="row.item.called_at">
                {{ createdAtFormat(row.item.called_at) }}
              </div>
            </template>
            
            <template #cell(created_at)="row">
              <div>
                {{ createdAtFormat(row.item.created_at) }}
              </div>
            </template>

            <template #row-details="row">
              <b-card>
                <div v-if="survey_data">
                  <h5><b>Comment: </b>{{  survey_data ? survey_data.comment : 'n/a' }}</h5>
                  <b-table
                      :fields="survey_fields"
                      striped hover responsive
                      :items="survey_data.survey_data"
                      show-empty
                      class="mt-5">
                      <template #cell(value)="row">
                    <div>
                      {{ row.item.value == 1 ? "Yes" : "No" }}
                    </div>
                  </template>
                  </b-table>
                </div>
                <div v-else>
                  <p class="text-center mb-0">Survery data not available</p>
                </div>
              </b-card>
            </template>

            <template #cell(actions)="row">
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" boundary="viewport"
                            variant="primary"
                            class="m-2">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item href="#" @click="callHistory(row.item)" v-b-modal.modal-call>
                    <span class="pr-4"><i class="fas fa-phone-volume"></i></span> Call log
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="therapySurvery(row.item)">
                    Survey details
                  </b-dropdown-item>
                  </b-dropdown>
              </template>
          </b-table>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination v-if="total_count> 0"
              v-model="filter.currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              class="my-0"
              @change="pageChanged"
          ></b-pagination>
        </div>
      </div>
    </div>
    <b-modal id="modal-call" size="xl" :title="`Add call log`" hide-footer>
      <b-form @submit.prevent="saveCallHisory" @reset="onReset">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Response *</label>
              <select class="form-control" v-model="call_history.call_response">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="call_type">Call Type *</label>
              <select name="" id="call_type" class="form-control" v-model="call_history.call_type">
                <option value="inbound">Inbound</option>
                <option value="outbound">Outbound</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Status *</label>
              <v-select
                  :options="call_category"
                  :reduce="call_category => call_category.id"
                  v-model="call_history.call_category"
                  class="h-100"
                  label="title"
                  name="title">
              </v-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Remarks: *</label>
              <b-form-textarea
                  id="textarea"
                  v-model="call_history.remarks"
                  placeholder="Remarks:*"
                  rows="1"
              ></b-form-textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-checkbox
                id="checkbox-1"
                v-model="call_history.is_reminder"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
            >
              Want a reminder
            </b-form-checkbox>

            <div v-if="call_history.is_reminder ==1">
              <b-form-datepicker id="example-datepicker" v-model="call_history.reminder_date"
                                 class="mb-2"></b-form-datepicker>
            </div>

          </div>
        </div>

        <div class="row mt-10">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {DateFormat} from "../../../../mixins/core/DateFormat";
import {ExportMixin} from "../../../../mixins/core/ExportMixin";

export default {
  name: "AgentWisePatient",
  mixins: [DateFormat, ExportMixin],
  data() {
    return {
      loading: false,
      filter: {
        start_date: this.$route.query.start_date ? this.$route.query.start_date : null,
        end_date: this.$route.query.end_date ? this.$route.query.end_date : null,
        currentPage: 1,
        is_called: 0
      },
      items: [],
      fields: [
        {
          key: 'fullname',
          label: 'Fullname',
        },
        {
          key: 'phone_number',
          label: 'Phone number',
        },

        {
          key: 'age',
          label: 'Age',
        },
        {
          key: 'gender',
          label: 'Gender',
        },
        {
          key: 'otp_verified',
          label: 'OTP Verified?',
        },
        {
          key: 'is_called',
          label: 'Is called?',
        },
        {
          key: 'called_at',
          label: 'Called at',
        },
        {
          key: 'created_at',
          label: 'Create date',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      survey_fields: [
        {
          key: 'question',
          label: 'Question',
        },
        {
          key: 'value',
          label: 'Answer'
        }
      ],
      total_count: 0,
      perPage: 20,
      call_category: [],
      call_history: {
        call_response: '',
        call_type: '',
        remarks: '',
        call_category: '',
        reminder_date: '',
        is_reminder: '',
      },
      callHistories: [],
      user: {
        user_name: '',
        user_id: ''
      },
      survey_data:{},
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Agent wise patient list", route: "/occ/agent-wise-patient-list"},
    ]);
    this.setSearchParams();
    this.getItems();
  },
  created() {
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
  },
  
  methods: {
    setSearchParams() {
      this.filter.currentPage = this.$route.query.currentPage
        ? parseInt(this.$route.query.currentPage)
        : 1;
      this.filter.start_date = this.$route.query.start_date
        ? this.$route.query.start_date
        : "";
        this.filter.end_date = this.$route.query.end_date
        ? this.$route.query.end_date
        : "";
        this.filter.is_called = this.$route.query.is_called
        ? parseInt(this.$route.query.is_called)
        :0;
    },

    pageChanged(val) {
      this.filter.currentPage = val;
      this.getItems();
    },
    filterData() {
      this.filter.currentPage = 1;
      this.getItems();
    },
    getItems() {
      this.loading = true;
      this.$router.replace({ query: this.filter }).catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }
      })
      let offset = this.perPage * (this.filter.currentPage - 1);
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/agent-pharmacy/${this.$route.params.id}/patient-list?api_token=${localStorage.getItem('api_token')}${this.filter.start_date ? '&start_date=' + this.filter.start_date : ''}${this.filter.end_date ? '&end_date=' + this.filter.end_date : ''}${this.filter.is_called ? '&is_called=' + this.filter.is_called : ''}&limit=20&offset=${offset ? offset : 0}`).then(res => {
        this.items = res.data.data;
        this.total_count = res.data.total_count;
        this.loading = false;
      })
    },
    clear() {
      this.filter = {currentPage: 1};
      this.getItems();
    },
    exportData(){
      var url = `${process.env.VUE_APP_URL}/api/v2/agent-pharmacy/${this.$route.params.id}/patient-list/export?api_token=${localStorage.getItem('api_token')}${this.filter.start_date ? '&start_date=' + this.filter.start_date : ''}${this.filter.end_date ? '&end_date=' + this.filter.end_date : ''}`;
      axios.get(url, {responseType: 'blob'})
          .then(response => {
            this.exportLink(response)
          })
    },
    callHistory(item) {
            this.call_log = item;
            this.user.user_name = item.name;
            this.user.user_id = item.id;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs?user_id=${item.id}&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.callHistories = response.data.data;
                });
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/category/list?call_type=outbound&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.call_category = response.data.data
                });
        },

    saveCallHisory() {
        let form = new FormData();
        form.append('user_id', this.user.user_id);
        form.append('call_category_id', this.call_history.call_category);
        form.append('call_action_status_id', 1);
        form.append('call_type', this.call_history.call_type);
        form.append('call_response', this.call_history.call_response);
        if (this.call_history.is_reminder == 1) {
            form.append('has_reminder', this.call_history.is_reminder);
            form.append('reminder_date', this.call_history.reminder_date);
        }
        form.append('remarks', this.call_history.remarks);
        form.append('agent_username', '');
        axios.post(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs/store?api_token=${localStorage.getItem('api_token')}`, form)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                if (response.data.status_code == 400) {
                    return this.$snotify.error(response.data.message);
                } else {
                  this.getItems();
                    this.$bvModal.hide('modal-call')
                    this.call_history = [];
                    this.callHistory(this.call_log);
                    this.$snotify.success('Call log added Successfully.');
                }
            })
    },

    onReset(event) {
        event.preventDefault()
        this.call_log = {};
    },
    
    therapySurvery(row){
      const { detailsRow } = this
      if (detailsRow && detailsRow !== row) {
        detailsRow._showDetails = false;
      }
      this.$set(row, "_showDetails", !row._showDetails);
      this.detailsRow = row;
      this.survey_data = {};
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/sales/team/${row.id}/therapy-survey?api_token=${localStorage.getItem('api_token')}`).then(res => {
        this.survey_data = res.data.data;
      })
    }
  },
}
</script>

<style>
.rowClass {
  cursor: pointer;
}
</style>
