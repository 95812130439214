<template>
    <div class="card">
    <loader-component v-if="loading"/>
    
    <div class="card-body">
        <router-link to="/blogs/posts/make" class="btn btn-primary">Add New Post</router-link>
      <div class="row mt-5 align-items-center justify-content-between">
        <div class="col-md-4">
          <label for="">From</label>
          <input type="date" v-model="filter.start_date" class="form-control">
        </div>
        <div class="col-md-4">
          <label for="">To</label>
          <input type="date" v-model="filter.end_date" class="form-control">
        </div>
        <div class="col-md-2 mt-7">
          <button class="btn btn-success" @click="getItems()">Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5"
          >
            <template #cell(conditions_we_treat_id)="row">
              <div v-if="row.item.conditions_we_treat_id">
                {{  row.item.category ? row.item.category.name : '' }}
              </div>
            </template>
            
            <template #cell(is_published)="row">
              <b-badge :variant="row.item.is_published == 1 ? 'success' : 'danger'">{{ row.item.is_published == 1 ? 'Published' : 'Unpublished'}}</b-badge>

            </template>

            <template #cell(image)="row">
              <div v-if="row.item.image">
                <b-img :src="userPhoto(row.item.image)" class="user_image" :alt="row.item.image"
                           v-if="row.item.image"></b-img>
              </div>
            </template>

            <template #cell(actions)="row">
              <div>
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <div>
                    <b-dropdown-item :to="`/blog/post/edit/${row.item.slug}`">
                      <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                    </b-dropdown-item>
                    <b-dropdown-item  v-if="row.item.is_published != 1" href="#" @click="deleteAction(row.item)">
                      <span class="pr-4"><i class="fas fa-trash"></i></span>Delete
                    </b-dropdown-item>
                    <b-dropdown-item  v-if="row.item.is_published != 1" href="#" @click="publishAction(row.item)">
                      <span class="pr-4"><i class="fas fa-eye"></i></span>Published
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              class="my-0"
              @change="getItems"
          ></b-pagination>
        </div>
      </div>
    </div>
   
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>
import axios from 'axios'
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
    name: 'BlogPosts',
    data(){
        return {
            items: [],
            fields: [
                {
                    key: 'image',
                    label: 'Image'
                },
                {
                    key: 'title',
                    label: 'Title'
                },
                {
                    key: 'conditions_we_treat_id',
                    label: 'Category'
                },
                {
                    key: 'author_name',
                    label: 'Author'
                },
                
                {
                    key: 'is_published',
                    label: 'Published'
                },

                {
                    key: 'published_at',
                    label: 'Published At'
                },
                
                
                {
                    key: 'actions',
                    label: 'Actions'
                }
            ],
            filter: {
                start_date: null,
                end_date: null,
                q: '',
            },
            currentPage: 1,
            perPage: 20,
            total_count: 0,
            loading: false,
            
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Blog posts", route: "/blogs/posts"},
        ]);
    },
    created(){
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.getItems();
    },
    methods: {
        
        getItems(value) {
          if (Number(value)) {
            this.currentPage = value;
            var offset = value - 1;
            var correctValue = offset * this.perPage;
          }
            this.loading = true;
            axios.get(`${process.env.VUE_APP_API_URL}/api/v2/blogs?api_token=${localStorage.getItem('api_token')}${this.filter.start_date ? '&start_date='+this.filter.start_date : ''}${this.filter.end_date ? '&end_date='+this.filter.end_date : ''}${this.filter.q ? '&q='+this.filter.q : ''}&limit=20&offset=${correctValue ? correctValue : 0}&platform=backoffice`).then(res => {
                this.items = res.data.data;
                this.total_count = res.data.total_count;
                this.loading = false;
            })
        },
        clear(){
            this.filter = {};
            this.getItems();
        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        editAction(row) {
          console.log('row',row);
        },
        deleteAction(item) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v2/blogs/${item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getItems();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        publishAction(item) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to published",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .get(`${process.env.VUE_APP_URL}/api/v2/blogs/${item.id}/publish?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully Published",
                                            "Success"
                                        );
                                        this.getItems();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        
    }
}
</script>