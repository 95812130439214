<template>
  <div class="mt-60">
    <div class="row">
      <div class="col-md-4">
        <div class="c-card">
          <div class="filter-section">
            <form>
              <ul class="filter-list" v-if="!is_filter">
                <li>
                  <b-button
                    @click="getActivity()"
                    class="coll-btn"
                    :class="is_service ? 'inactive-menu' : 'active-menu'"
                    >Activities
                  </b-button>
                </li>
                <li>
                  <b-button
                    @click="getServices()"
                    class="coll-btn"
                    :class="is_service ? 'active-menu' : 'inactive-menu'"
                    >Show services
                  </b-button>
                </li>
              </ul>
              <ul class="filter-list" v-else>
                <li>
                  <b-button @click="selectFilter('Month')" class="coll-btn"
                    >{{ filter.month ? filter.month : "Month" }}
                    <i class="fas fa-chevron-down"></i
                  ></b-button>
                </li>
                <li>
                  <b-button @click="selectFilter('Platform')" class="coll-btn"
                    >{{ filter.platform ? filter.platform : "Platform" }}
                    <i class="fas fa-chevron-down"></i
                  ></b-button>
                </li>
              </ul>

              <b-collapse id="collapse-1" v-model="showMonthFilter">
                <ul class="month-selection-list">
                  <li v-for="(item, key) in months" :key="key">
                    <a
                      href="javascript:void(0)"
                      :class="item.value == filter.month ? 'active' : ''"
                      @click="
                        selectPeriod('monthly', item.value);
                        showMonthFilter = false;
                        getItems();
                      "
                      >{{ item.text }}</a
                    >
                  </li>
                </ul>
              </b-collapse>
              <b-collapse id="collapse-1" v-model="showPlatformFilter">
                <ul class="platform-selection-list">
                  <li>
                    <a
                      href="javascript:void(0)"
                      :class="filter.platform == '' ? 'active' : ''"
                      @click="
                        filter.platform = '';
                        showPlatformFilter = false;
                        getItems();
                      "
                      >All</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      :class="filter.platform == 'All app' ? 'active' : ''"
                      @click="
                        filter.platform = 'All app';
                        showPlatformFilter = false;
                        getItems();
                      "
                      >All App</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      :class="filter.platform == 'Pro app' ? 'active' : ''"
                      @click="
                        filter.platform = 'Pro app';
                        showPlatformFilter = false;
                        getItems();
                      "
                      >Pro App</a
                    >
                  </li>
                </ul>
              </b-collapse>
            </form>
          </div>
          <div class="target-content">
            <!-- <h2 class="content-title">Outbound calls</h2> -->
            <div
              class="card-div"
              v-if="details.length > 0 || services.length > 0"
            >
              <div v-if="is_service">
                <div v-if="service_type == 'invoice'">
                  <div
                    class="card mt-3 shadow custom-card"
                    v-for="(item, index) in services"
                    :key="index"
                  >
                    <div class="card-body custom-card-body">
                      <div>
                        <div class="d-flex justify-content-between">
                          <div>
                            <span class="route-name">Service type</span>
                            <p class="font-size-14 mt-0">
                              <b>{{ underScoreToSpace(item.product_type) }}</b>
                            </p>
                          </div>
                          <div class="text-right">
                            <span class="route-name">Invoice date</span>
                            <p>{{ item.invoice_date }}</p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div>
                            <span class="route-name">Total Amount</span>
                            <p class="font-size-14 mt-0">
                              <b>{{ item.total }}</b>
                            </p>
                          </div>
                          <div class="text-right">
                            <span class="route-name">Invoice ID</span>
                            <p>{{ item.uid }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="card mt-3 shadow custom-card"
                    v-for="(item, index) in services"
                    :key="index"
                  >
                    <div class="card-body custom-card-body">
                      <div>
                        <div class="d-flex justify-content-between">
                          <div>
                            <span class="route-name">Service type</span>
                            <p class="font-size-14 mt-0">
                              <b>{{
                                underScoreToSpace(item.appointment_type)
                              }}</b>
                            </p>
                          </div>
                          <div class="text-right">
                            <span class="route-name">Schedule date</span>
                            <p>{{ item.schedule_date }}</p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div>
                            <span class="route-name">Schedule started </span>
                            <p class="font-size-14 mt-0">
                              <b>{{ item.schedule_started_at }}</b>
                            </p>
                          </div>
                          <div class="text-right">
                            <span class="route-name">Status</span>
                            <p>{{ item.status }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <b-spinner label="Spinning" v-if="loading"></b-spinner>

                <div
                  class="card mt-3 shadow custom-card"
                  v-for="(service, index) in details"
                  :key="index"
                  v-else
                >
                  <div class="card-body custom-card-body">
                    <div>
                      <div class="d-flex justify-content-between">
                        <div>
                          <span class="route-name">Route name</span>
                          <p class="font-size-14 mt-0">
                            <b>{{ dashesToSpace(service.route_name) }}</b>
                          </p>
                        </div>
                        <div>
                          <span class="route-name">Visit Time</span>
                          <p>{{ service.created_at }}</p>
                        </div>
                      </div>
                      <div class="">
                        <!-- <p class="mb-0">{{  service.app_name }}</p> -->
                        <b-badge
                          variant="primary"
                          v-if="service.app_name == 'all'"
                          >All App</b-badge
                        >
                        <b-badge variant="warning" v-else>Pro App</b-badge>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                
                <b-pagination
                  v-if="total_details_count"
                  v-model="currentPageDetails"
                  :total-rows="total_details_count"
                  :per-page="perPage"
                  size="sm"
                  @change="getDetails(user_details, $event)"
                  class="my-0 details-pagination"
                  aria-controls="doctorList"
                ></b-pagination>
              </div>
            </div>
            <div v-else>
              <div class="card">
                <div class="card-body d-flex justify-content-between">
                  <h5 class="text-dark">Active Users</h5>
                  <h3 class="text-dark">{{ total_count }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="c-card p-7">
          <div class="row justify-content-end">
            <div class="col-md-6">
              <b-form-group>
                <input
                  type="text"
                  v-model="filter.q"
                  placeholder="Search Doctor/patient by name"
                  class="form-control"
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <button class="btn btn-success" @click="getItems">Filter</button>
              <button class="btn btn-danger mx-2" @click="clear">Clear</button>
            </div>

            <div class="col-md-2">
              <button class="btn btn-primary" @click="exportData()">
                Export
              </button>
            </div>
          </div>
          <div class="patient-income-table tableFixHead">
            <table class="timeline-tbl" ref="table">
              <thead>
                <tr>
                  <th style="min-width: 180px">User</th>
                  <th style="min-width: 150px">Phone number</th>
                  <th style="min-width: 110px" class="text-center" v-if="filter.platform == 'Pro app'">Confirmed?</th>
                  <th style="min-width: 170px" class="text-center" v-if="filter.platform == 'Pro app'">Nature of Service</th>
                  <th style="min-width: 110px" class="text-center">App</th>
                  <th style="min-width: 150px" class="text-center">Activity Point</th>
                  <th style="min-width: 140px" class="text-center">Visit Time</th>
                  <th style="min-width: 140px" class="text-center">Action</th>
                </tr>
              </thead>
              <tbody v-if="items.length > 0">
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                  :class="selectedUserId == item.user_id ? 'active-row' : ''"
                >
                  <td>{{ item.user_name }}</td>
                  <td>{{ item.phone_number }}</td>
                  <td class="text-center" v-if="filter.platform == 'Pro app'">
                    <b-badge variant="success" v-if="item.is_confirmed"
                      > Yes </b-badge
                    >
                    <b-badge variant="danger" v-else> No </b-badge>
                  </td>
                  <td class="text-center" v-if="filter.platform == 'Pro app'">{{ item.nature_of_service }}</td>
                  <td class="text-center">
                    <b-badge variant="primary" v-if="item.app_name == 'all'"
                      >All app</b-badge
                    >
                    <b-badge variant="warning" v-else>Pro app</b-badge>
                  </td>
                  <td class="text-center">{{ item.total_point }}</td>
                  <td class="text-center">{{ item.created_at }}</td>
                  <td class="text-center">
                    <b-btn variant="info" size="sm" @click="getDetails(item)"
                      >Details</b-btn
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mt-3" v-if="total_count">
            <div class="col-md-12">
              <b-pagination
                v-model="currentPage"
                :total-rows="total_count"
                :per-page="perPage"
                size="lg"
                @change="getItems"
                class="justify-content-center"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { dateFormate } from "../../../../healper/dateTimeFormet";
import moment from "moment";
import { ExportMixin } from "../../../../mixins/core/ExportMixin";
export default {
  name: "InappActivity",
  mixins: [ExportMixin],
  data() {
    let all_months = [
      {
        text: "Jan",
        value: "January",
      },
      {
        text: "Feb",
        value: "February",
      },
      {
        text: "Mar",
        value: "March",
      },

      {
        text: "Apr",
        value: "April",
      },
      {
        text: "May",
        value: "May",
      },
      {
        text: "Jun",
        value: "June",
      },

      {
        text: "Jul",
        value: "July",
      },
      {
        text: "Aug",
        value: "August",
      },
      {
        text: "Sep",
        value: "September",
      },

      {
        text: "Oct",
        value: "October",
      },
      {
        text: "Nov",
        value: "November",
      },
      {
        text: "Dec",
        value: "December",
      },
    ];
    return {
      currentPage: 1,
      currentPageDetails: 1,
      total_count: 0,
      total_details_count: 0,
      perPage: 20,
      patients: [],
      showFormFilter: false,
      showPlatformFilter: false,
      showMonthFilter: false,
      filter: {
        from_date: null,
        platform: "",
        q: "",
        period: "monthly",
        month: "",
        center: "",
        year: 2023,
        quarter: "",
      },
      months: all_months,
      loading: false,
      items: [],
      details: [],
      user_details: {},
      services: [],
      is_service: false,
      is_filter: true,
      service_type: "",
      selectedUserId: "",
    };
  },
  created() {
    this.filter.month = moment().format("MMM");
    this.filter.year = moment().format("YYYY");
    this.getItems();
  },
  methods: {
    selectPeriod(type, value) {
      this.filter.period = type;
      if (type == "monthly") {
        this.filter.quarter = "";
        this.filter.month = value;
      } else {
        this.filter.month = "";
        this.filter.quarter = value;
      }
    },
    selectFilter(type) {
      if (type == "From") {
        this.showMonthFilter = false;
        this.showFormFilter = !this.showFormFilter;
      } else if (type == "Platform") {
        this.showMonthFilter = false;
        this.showPlatformFilter = !this.showPlatformFilter;
      } else if (type == "Month") {
        this.showPlatformFilter = false;
        this.showMonthFilter = !this.showMonthFilter;
      }
    },

    getItems(value) {
      this.loading = true;
      var offset = value - 1;
      var correctValue = offset * this.perPage;
      var date_range = "";
      if (this.filter.from_date == "Weekly") {
        date_range = dateFormate().weekly_first_day;
      } else if (this.filter.from_date == "Biweekly") {
        date_range = dateFormate().biweekly_first_day;
      } else {
        date_range = dateFormate().monthlay_first_day;
      }
      let url = `${
        process.env.VUE_APP_URL
      }/api/v2/administration/doctors/app/engagement?period=${
        this.filter.period
      }&year=2023&api_token=${localStorage.getItem("api_token")}${
        this.filter.month ? "&month=" + this.filter.month : ""
      }${
        this.filter.platform
          ? this.filter.platform == "All app"
            ? "&platform=all"
            : "&platform=pro"
          : ""
      }&limit=${this.perPage}${correctValue ? "&offset=" + correctValue : ""}${
        this.filter.q ? "&q=" + this.filter.q : ""
      }`;
      axios.get(url).then((response) => {
        if (response.data.status_code == "500") {
          this.$router.push("/access-denied");
        } else {
          this.items = response.data.data;
          this.details = [];
          this.total_count = response.data.total_count;
          this.loading = false;
        }
      });
    },

    exportData() {
      let url = `${
        process.env.VUE_APP_URL
      }/api/v2/administration/doctors/app/engagement/export?period=${
        this.filter.period
      }&year=2023&api_token=${localStorage.getItem("api_token")}${
        this.filter.month ? "&month=" + this.filter.month : ""
      }${
        this.filter.platform
          ? this.filter.platform == "All app"
            ? "&platform=all"
            : "&platform=pro"
          : ""
      }${this.filter.q ? "&q=" + this.filter.q : ""}`;
      axios.get(url, { responseType: "blob" }).then((response) => {
        this.exportLink(response);
      });
    },

    getDetails(item, value) {
      this.services = [];
      this.details = {};
      this.total_details_count = 0;
      if (this.selectedUserId == item.user_id && !value) {
        this.selectedUserId = "";
        this.is_filter = true;
        return false;
      }

      this.selectedUserId = item.user_id;
      this.is_filter = false;
      this.is_service = false;
      this.user_details = item;
      this.loading = true;
      var offset = value - 1;
      var correctValue = offset * this.perPage;
      if(value) {
        this.currentPageDetails = parseInt(value);
      }
      var date_range = "";
      if (this.from_date == "weekly") {
        date_range = dateFormate().weekly_first_day;
      } else if (this.from_date == "biweekly") {
        date_range = dateFormate().biweekly_first_day;
      } else {
        date_range = dateFormate().monthlay_first_day;
      }
      let url = `${
        process.env.VUE_APP_URL
      }/api/v2/administration/doctors/app/engagement/${
        this.user_details.user_id
      }?api_token=${localStorage.getItem("api_token")}${
        this.from_date ? "&from_date=" + date_range : ""
      }&limit=${this.perPage}${correctValue ? "&offset=" + correctValue : ""}${
        this.user_id ? "&user_id=" + this.user_id : ""
      }${
        this.filter.month ? "&month=" + this.filter.month : ""
      }`;
      axios.get(url).then((response) => {
        if (response.data.status_code == "500") {
          this.$router.push("/access-denied");
        } else {
          this.details = response.data.data;
          this.total_details_count = response.data.total_count;
          this.loading = false;
        }
      });
    },

    dashesToSpace(str) {
      return str.replace(/-/g, " ");
    },

    underScoreToSpace(str) {
      return str.replace(/_/g, " ");
    },

    clear() {
      this.filter.q = "";
      this.getItems();
    },

    getServices() {
      this.is_service = true;
      axios
        .get(
          `${
            process.env.VUE_APP_URL
          }/api/v2/administration/doctors/app/engagement/${
            this.user_details.user_id
          }/services?api_token=${localStorage.getItem("api_token")}&month=${
            this.filter.month
          }&year=2023`
        )
        .then((res) => {
          this.services = res.data.data;
          this.service_type = res.data.service_type;
        });
    },
    getActivity() {
      this.is_service = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.active-row {
  background-color: #ccc;
  td {
    padding: 5px 6px;
    &:first-child {
      padding: 5px 8px 5px 15px;
    }
  }
}
.c-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #fff;
  width: 100%;
  float: left;

  .filter-list {
    width: 100%;
    float: left;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      width: 50%;
      float: left;
      padding: 0px 10px 0;
      border-right: 2px solid #002170;
      text-align: center;
      list-style: none;

      &:last-of-type {
        border-right: none;
        padding: 0;
      }

      select {
        border: none;
        outline: none;
        scroll-behavior: smooth;
        -webkit-appearance: listbox !important;

        color: #002170;
        font-size: 15px;
        font-weight: 600;
        font-family: Arial;
        padding: 0 12px 0 8px;
        height: 22px;
      }
    }
  }

  .month-selection-list {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin-top: 20px;

    li {
      width: 25%;
      float: left;
      text-align: center;
      margin-bottom: 20px;

      a {
        background-color: #ebebeb;
        padding: 7px 25px;
        border-radius: 20px;
        color: #000;
        font-family: Arial;

        &.active {
          background-color: #f9a81a;
          font-weight: 600;
        }
      }

      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(9),
      &:nth-child(13) {
        a {
          background-color: #aaaaaa;
          color: #fff;

          &.active {
            background-color: #f9a81a;
            font-weight: 600;
          }
        }
      }
    }
  }
  // .content-title {
  //   color: #FFA800 !important;
  //   font-size: 20x;
  //     font-family: Arial;
  //     font-weight: 700;
  // }
  .target-content {
    width: 100%;
    float: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #ffffff;
    // padding: 25px;
    height: 550px;
    h2 {
      color: #44b3e6;
      font-family: Arial;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 15px;
    }
    ul {
      list-style-type: none;
      margin-bottom: 20px;
      width: 100%;
      float: left;
      list-style: none;

      li {
        width: 100%;
        float: left;

        .l-label {
          width: 45%;
          float: left;
        }

        .r-label {
          text-align: right;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          font-family: Arial;

          .achieved {
            color: #f9a91a;
          }
        }

        label {
          color: #fff;
          font-size: 18px;
          font-family: Arial;
          font-weight: 600;
        }
      }
    }
  }

  .timeline-tbl {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    float: left;

    .expanded-row {
      box-shadow: 0 0 0 1px #aaa;
      border-radius: 20px;
    }
    thead {
    }

    tr {
      th {
        color: #002147;
        border-bottom: 1px solid #a0a0a1;
        padding-bottom: 10px;
        // text-align: center;
        font-size: 15px;
      }

      td {
        vertical-align: top !important;
      }
    }

    tbody {
      tr {
        // margin-bottom: 5px;
        &.expanded-total-row {
          border-top: 1px solid #aaa;

          td {
            padding-bottom: 5px;
            ul {
              margin: 6px 0;
              list-style-type: none;

              li {
                line-height: 1.6;

                &:last-of-type {
                  font-size: 13px;

                  span {
                    font-size: 13px;

                    i {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }

        td {
          //   text-align: center;
          padding-bottom: 5px;
          font-size: 16px;
          line-height: 1.5;
          color: #212120;
        }

        &:first-of-type {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .summary-wrapper {
    width: 100%;
    float: left;
    background-color: #00579b;
    padding: 8px 30px;
    border-radius: 20px;
    margin-top: 30px;

    .summary-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;

      tr {
        th {
          color: #fff;
          font-size: 17px;
          padding-bottom: 5px;
        }

        td {
          font-size: 14px;
          color: #fff;
          font-weight: 600;
          vertical-align: top !important;
        }
      }
    }
  }
}

.brd-15 {
  border-radius: 15px;
}

.ftb-15 {
  font-size: 15px;
  font-weight: 600;
}

.up-text {
  color: #00cd32;
  font-size: 15px;
  font-weight: 600;

  i {
    color: #00cd32;
    font-size: 13px;
  }
}

.down-text {
  color: red;
  font-size: 15px;
  font-weight: 600;

  i {
    color: red;
    font-size: 13px;
  }
}

.coll-btn {
  color: #002170;
  font-size: 15px;
  font-weight: 600;
  font-family: Arial;
  padding: 0 12px 0 8px;
  // height: 22px;

  background-color: transparent;
  border: none;

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.platform-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 33%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9),
    &:nth-child(13) {
      a {
        background-color: #aaaaaa;
        color: #fff;

        &.active {
          background-color: #f9a81a;
          font-weight: 600;
        }
      }
    }
  }
}

.center-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 33%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }
  }
}
.center-selection-list-more-items {
  width: 100%;
  display: flex;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  li {
    text-align: center;
    margin: 10px;

    a {
      background-color: #ebebeb;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }
  }
}

.patient-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 50%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 8px 30px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }
  }
}

.patient-income-table {
  height: 500px;
  overflow: scroll;
  // padding: 2px;
}

.target-content {
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;

  p {
    margin: 5px 0px;
  }

  ul {
    list-style-type: none !important;
    // display: flex;
    margin-left: 0px;
    font-weight: bold;
    font-size: 17px;
  }
}

.patient_image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.patient-profile {
  width: 100%;
  float: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px 15px 3px 0px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #00579b;
  padding: 25px;
  color: #ffffff;

  .patient-info {
    padding-left: 20px;

    p {
      font-size: 14px;
      font-weight: 500;
      margin: 5px 0px;
    }
  }
}

.patient-tab {
  width: 100%;
  float: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  padding: 25px;
}

.patient-remove {
  position: absolute;
  right: 25px;
  top: 10px;
}

.male-female-icon {
  height: 70px;
  margin-right: 20px;
}

.patient-card {
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
  padding-right: 30px;
}

.patient-card div {
  margin-left: 15px;
  margin-bottom: 10px;
}

.patient-card div p {
  line-height: 20px;
}

.patient-card img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.patient-tab {
  background: #fff;
  margin-top: -15px;
}

.custom-tab-design {
  color: #000000 !important;
  background-color: #fff !important;
  /* border-color: #E4E6EF #E4E6EF #ffffff; */
  border-bottom: 2px solid #9b9999 !important;
  box-shadow: none !important;
}

.image_size_responsive {
  //height: 100px;
  width: 100px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 2px;
  margin-right: 2px;
}

.prescriptions {
  border: 1px solid #ddd;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px;
  position: relative;
}

.prescriptions .content {
  margin-top: 10px;
  //margin-left: 10px;
  //text-align: center !important;
}

.prescriptions-label {
  position: absolute;
  top: -11px;
  background: #ffff;
  padding: 0px 10px;
}

.color-red {
  color: red;
  font-size: 35px;
}

.row-active {
  background: #f6f6f6;
  border-radius: 15px;
}

.prescriptions-content {
  line-height: 15px;
}
.marget_activation {
  background: red;
}
.progressbar-subtitle {
  font-size: 10px;
  font-weight: 500;
}
.custom_variant_yellow {
  background-color: #ffd37f !important;
}
.custom_market_activation_bg {
  background-color: #ffd500 !important;
}
.corporate_engangement_bg {
  background-color: #66f755 !important;
}
.default_progressbar_bg {
  background-color: #ffffff !important;
}
.reg_to_consult {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  margin-left: 10px;
}
.conversion_calculate {
  font-size: 14px;
  font-weight: bold;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.progress_bar_bullet {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  p {
    margin-left: 10px;
  }
  span {
    height: 11px;
    width: 11px;
    display: inline-block;
    margin-right: 5px;
  }
  .doctor_bullet {
    background: #ffa800;
  }
  .market_activation_bullet {
    background: #ffd500;
  }
  .corporate_engagement_bullet {
    background: #66f755;
  }
  .other_bullet {
    background: #ffffff;
  }
}
.custom-card-body {
  padding: 10px 10px !important;
  color: #000 !important;
}
.card-div {
  height: 500px;
  overflow-x: scroll;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.card-div ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.card-div ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.card-div ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.font-size-14 {
  font-size: 15px;
}
.custom-btn-bg {
  background-color: #00579b !important;
}
.text-capitalize {
  text-transform: capitalize;
}
.bg-gray {
  background-color: #dfe1e5 !important;
}
.item-content {
  line-height: 20px;
}

.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}
.route-name {
  font-size: 10px;
}
</style>

<style scoped>
.active-menu {
  font-weight: 900 !important;
  color: blue;
}
</style>
<style>
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #000;
  border-color: #ffffff #ffffff #000000;
}
.patient-tab .nav-tabs {
  flex-wrap: initial !important;
  overflow: scroll;
}
.patient-tab .nav-tabs .nav-item a {
  text-wrap: nowrap;
}
.details-pagination {
  color: #000 !important;
}
</style>
