<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-2">
            <label for="">Select center</label>
            <v-select
                :options="centers"
                :reduce="centers => centers.id"
                v-model="institute_id"
                label="name"
                class="h-100"
                name="id"
                placeholder="Select center"
                required>
            </v-select>
          </div>
          <div class="col-md-2">
            <label for="">Select type</label>
            <b-form-select v-model="type" :options="types"></b-form-select>
          </div>
          <div class="col-md-3">
            <label for="">From</label>
            <input type="date" v-model="start_date" class="form-control">
          </div>
          <div class="col-md-3">
            <label for="">To</label>
            <input type="date" v-model="end_date" class="form-control">
          </div>
          <div class="col-md-1 mt-8 text-center">
            <button type="button" class="btn btn-info" @click="getList">Filter</button>
          </div>
          <div class="col-md-1 mt-8">
            <button type="button" class="btn btn-danger mx-5" @click="clear">Clear</button>
          </div>
        </div>
        <b-table striped hover :items="items" :fields="fields" class="mt-5">
          <template #cell(image)="row">
            <div class="is_online">
              <img :src="userPhoto(row.item.image)" alt="image"
                   class="user_image" v-if="row.item.image">
              <img :src="require('@/assets/img/blank.png')" alt="" class="user_image" v-else>
            </div>
          </template>
          <template #cell(created_at)="row">
            <div>
              {{ createdAtFormat(row.item.created_at) }}
            </div>
          </template>
        </b-table>
        <b-pagination
            v-model="currentPage"
            :total-rows="total_count"
            :per-page="perPage"
            size="lg"
            @change="getList"
            class="my-0"
            aria-controls="doctorList"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {DateFormat} from "../../mixins/core/DateFormat";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "CenterWisePatientList",
  mixins: [DateFormat],
  data() {
    return {
      institute_id: '',
      start_date: this.$route.query.start_date ? this.$route.query.start_date : null,
      end_date: this.$route.query.end_date ? this.$route.query.end_date : null,
      centers: [],
      items: [],
      fields: [
        {
          label: 'Image',
          key: 'image',
        },
        {
          label: 'Fullname',
          key: 'fullname',
        },
        {
          label: 'Phone number',
          key: 'phone_number',
        },
        {
          label: 'Age',
          key: 'age',
        },
        {
          label: 'Gender',
          key: 'user_gender',
        },
        {
          label: 'Created date',
          key: 'created_at',
        },
      ],
      currentPage: 1,
      perPage: 20,
      total_count: 0,
      types: [
        {
          text: 'Old patient',
          value: 'old_patient'
        },
        {
          text: 'New patient',
          value: 'new_patient'
        },
        {
          text: 'CF patient',
          value: 'cf_patient'
        },
      ],
      type: '',
    }
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    if (this.$route.query.institute_id) {
      this.institute_id = parseInt(this.$route.query.institute_id);
    }
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
    this.centerList();
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Center wise patient list", route: "/center-wise-dashboard"},
    ]);
  },
  methods: {
    getList(value) {
      if (value) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      const url = `${process.env.VUE_APP_URL}/api/v2/report/orkocenter/type-wise/patients?api_token=${localStorage.getItem('api_token')}${this.institute_id ? '&institute_id=' + this.institute_id : ''}${this.type ? '&type=' + this.type : ''}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}&limit=${this.perPage}&offset=${correctValue ? correctValue : ''}`
      axios.get(url).then(res => {
        if (res.data.status_code == 200) {
          this.items = res.data.data;
          this.total_count = res.data.total_count;
        } else {
          this.$snotify.error(res.data.message);
        }
      })
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.loading = false
              this.centers = response.data.data
            }
          })
    },
    clear() {
      this.institute_id = '';
      this.start_date = null;
      this.end_date = null;
      this.getList();
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
  }
}
</script>

<style scoped>

</style>
