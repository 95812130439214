<template>
  <div class="card">
    <loader-component v-if="loading" />
    <div class="card-body">
      <div class="row mt-5 align-items-center justify-content-between">
        <div class="col-md-3">
          <label for="">Select center</label>
          <v-select
            :options="centers"
            :reduce="(centers) => centers.id"
            v-model="filter.institute_id"
            label="name"
            class="h-100"
            name="id"
            placeholder="Select center"
            required
          >
          </v-select>
        </div>
        <div class="col-md-3">
          <label for="">From</label>
          <input type="date" v-model="filter.start_date" class="form-control" />
        </div>
        <div class="col-md-3">
          <label for="">To</label>
          <input type="date" v-model="filter.end_date" class="form-control" />
        </div>
        <div class="col-md-3 mt-7">
          <button class="btn btn-success" @click="getItems()">Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
            striped
            hover
            responsive
            :items="items"
            :fields="fields"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-5"
          >
            <template #cell(service_taken)="row">
              <div>
                <b-badge variant="success" v-if="row.item.service_taken == 1"
                  >Yes</b-badge
                >
                <b-badge variant="danger" v-else>Not Yet</b-badge>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
            v-model="currentPage"
            :total-rows="total_count"
            :per-page="perPage"
            size="lg"
            class="my-0"
            @change="getItems"
          ></b-pagination>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export default {
  name: "DoctorReferral",
  data() {
    return {
      loading: false,
      filter: {
        start_date: null,
        end_date: null,
        institute_id: "",
      },
      items: [],
      fields: [
        {
          key: "institute_name",
          label: "Institute Name",
        },
        {
          key: "doctor_name",
          label: "Doctor's Name",
        },
        {
          key: "referral_type",
          label: "Referral Type",
        },
        {
          key: "patient_name",
          label: "Patient's Name",
        },
        {
          key: "service_name",
          label: "Service Name",
        },
        {
          key: "created_at",
          label: "Referred At",
        },
        {
          key: "service_taken",
          label: "Service Taken?",
        },
      ],
      total_count: 0,
      perPage: 20,
      currentPage: 1,
      centers: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Doctor's Referral list",
        route: "/report/doctor-referral/list",
      },
    ]);
  },
  created() {
    this.getItems();
    this.centerList();
    localStorage.setItem("tabIndex", 4);
    this.$root.$emit("call-active-menu", 5);
  },
  methods: {
    getItems(value) {
      this.loading = true;
      if (Number(value)) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v2/doctor/referrals?api_token=${localStorage.getItem(
            "api_token"
          )}${
            this.filter.start_date
              ? "&start_date=" + this.filter.start_date
              : ""
          }${this.filter.end_date ? "&end_date=" + this.filter.end_date : ""}${
            this.filter.institute_id
              ? "&institute_id=" + this.filter.institute_id
              : ""
          }&limit=20&offset=${correctValue ? correctValue : 0}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.total_count = res.data.total_count;
          this.loading = false;
        });
    },
    clear() {
      this.filter = {};
      this.getItems();
    },
    centerList() {
      axios
        .get(
          `${
            process.env.VUE_APP_URL
          }/api/v1/institute/list?type=orko&api_token=${localStorage.getItem(
            "api_token"
          )}`
        )
        .then((response) => {
          if (response.data.status_code == "500") {
            this.$router.push("/access-denied");
          } else {
            this.centers = response.data.data;
          }
        });
    },
  },
};
</script>

<style scoped></style>
