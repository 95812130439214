<template>
  <div class="row mt-60">
    <loader-component v-if="loading"/>
    <div class="col-md-4">
      <div class="c-card">
        <div class="filter-section">
          <form>
            <ul class="filter-list">
              <li>
                <b-button @click="selectFilter('Month')" class="coll-btn"
                >{{ filter.quarter?'Q'+filter.quarter:filter.month?filter.month :'Month' }} {{ getYearLabel(filter.year) }} <i class="fas fa-chevron-down"></i></b-button
                >

                <!-- Element to collapse -->
              </li>
              <li>
                <b-button @click="selectFilter('Center')" class="coll-btn"
                >{{ filter.center || 'All Center' }} <i class="fas fa-chevron-down"></i></b-button
                >
              </li>
              <li>
                <b-button @click="selectFilter('Patient')" class="coll-btn"
                >{{ filter.patientType }} <i class="fas fa-chevron-down"></i></b-button
                >
              </li>
            </ul>

            <b-collapse id="collapse-1" v-model="showMonthFilter">
              <ul class="month-selection-list">
                <li v-for="(item, key) in months" :key="key"> 
                  <a v-if="!['Q1', 'Q2', 'Q3', 'Q4'].includes(item.text)" href="javascript:void(0)" :class="item.text == filter.month?'active':''" @click="selectPeriod('monthly',item.text); showMonthFilter=false; getReports()">{{ item.text }}</a>
                  <a v-else href="javascript:void(0)" :class="item.value == filter.quarter?'active':''" @click="selectPeriod('quarterly',item.value); showMonthFilter=false; getReports()">{{ item.text }}</a>
                </li>
              </ul>
              <ul class="year-selection-list">
                <li>
                  <a href="javascript:void(0)" :class="filter.year == year ?'active':''" @click="filter.year = year; getReports()" v-for="(year, index) in years"  :key="index">{{  year }}</a>
                </li>
              </ul>
            </b-collapse>

            <b-collapse id="collapse-2" v-model="showCenterFilter">
              <ul class="center-selection-list">
                <li>
                  <a href="javascript:void(0)" :class="filter.center==''?'active':''" @click="filter.center = ''; showCenterFilter=false; getReports()">All</a>
                </li>
                <li v-for="(item, key) in centers" :key="key">
                  <a href="javascript:void(0)" :class="item.short_form == filter.center?'active':''" @click="filter.center = item.short_form; showCenterFilter=false; getReports()">{{ item.short_form }}</a>
                </li>
              </ul>
            </b-collapse>

            <b-collapse id="collapse-3" v-model="showPatientFilter">
              <ul class="patient-selection-list">
                <li>
                  <a href="javascript:void(0)" :class="filter.patientType == 'Zero Clubfoot'?'active':''" @click="filter.patientType = 'Zero Clubfoot'; showPatientFilter=false; getReports()">Zero Clubfoot</a>
                </li>
                <li>
                  <a href="javascript:void(0)" :class="filter.patientType == 'Physiotherapy'?'active':''" @click="filter.patientType = 'Physiotherapy'; showPatientFilter=false; getReports()">Physiotherapy</a>
                </li>
                <li>
                  <a href="javascript:void(0)" :class="filter.patientType == 'Dental'?'active':''" @click="filter.patientType = 'Dental'; showPatientFilter=false; getReports()">Dental</a>
                </li>
                <li>
                  <a href="javascript:void(0)" :class="filter.patientType == 'CDU'?'active':''" @click="filter.patientType = 'CDU'; showPatientFilter=false; getReports()">CDU</a>
                </li>
                <li>
                  <a href="javascript:void(0)" :class="filter.patientType == 'Plaster Material'?'active':''" @click="filter.patientType = 'Plaster Material'; showPatientFilter=false; getReports()">Plaster Material</a>
                </li>
              </ul>
            </b-collapse>
          </form>

          <!-- <b-dropdown id="dropdown-1" :text="month" class="m-md-2 w-100">
            <b-row>
              <b-col cols="4">
                <div>
                  <b-button pill class="my-2" size="sm">Q1</b-button>
                </div>
                <div>
                  <b-button pill class="my-2" size="sm">Q2</b-button>
                </div>
                <div>
                  <b-button pill class="my-2" size="sm">Q3</b-button>
                </div>
                <div>
                  <b-button pill class="my-2" size="sm">Q4</b-button>
                </div>
              </b-col>
              <b-col cols="8">
                <div>
                  <b-button pill v-for="(month,index) in months" :key="index" class="m-2" size="sm">{{
                      month.text
                    }}
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </b-dropdown>
          <form class="text-center">
            <b-tabs content-class="mt-3" align="center">
              <b-tab :title="month">
                <b-row>
                  <b-col cols="3">
                    <b-button pill class="my-2" size="sm">Q1</b-button>
                    <b-button pill class="my-2" size="sm">Q2</b-button>
                    <b-button pill class="my-2" size="sm">Q3</b-button>
                    <b-button pill class="my-2" size="sm">Q4</b-button>

                  </b-col>
                  <b-col cols="9">
                    <div>
                      <b-button pill v-for="(month,index) in months" :key="index" class="m-2" size="sm">{{
                          month.text
                        }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="All Center">
                <b-button pill class="my-2 mx-5" size="sm">CTG</b-button>
                <b-button pill class="my-2 mx-5" size="sm">CM</b-button>
                <b-button pill class="my-2 mx-5" size="sm">BB</b-button>
              </b-tab>
              <b-tab title="Zero Clubfoot">
                <b-button pill class="my-2 mx-5" size="sm">Zero Clubfoot</b-button>
                <b-button pill class="my-2 mx-5" size="sm">Physiotherapy</b-button>
              </b-tab>
            </b-tabs>
          </form> -->
        </div>
        <div class="target-content">
          <h2 class="content-title">Treatment Phase (TP)</h2>
          <ul>
            <li
                v-for="(service, service_index) in services"
                :key="service_index"
            >
              <div
                  v-if="
                  ['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'registration_fee', 'Plaster Material',
                  'therapy_session', 'therapy_appointment', 'assistive_device', 'Dental', 'dental_appointment'].includes(service.product_type)
                  && (checkTargetExists(service.product_type) || filter.patientType == 'Plaster Material')
                "
              >
                <div class="l-label">
                  <label>{{ labelUpdate(service.product_type) }}</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{ getServiceCount(service.product_type, service.patient_count) }}</span> /
                  <span class="target">{{ getTarget(service.product_type) }}</span>
                </div>
                <b-progress
                    :max="max"
                    height="25px"
                    :variant="calculateProgress(getServiceCount(service.product_type, service.patient_count), getTarget(service.product_type))>=100?'success':'warning'"
                    :precision="0"
                    show-progress
                    class="mb-3 brd-15 ftb-15 w-100"
                >
                  <b-progress-bar :value="calculateProgress(getServiceCount(service.product_type, service.patient_count), getTarget(service.product_type))">
                    <span>
                      <strong>{{ calculateProgress(getServiceCount(service.product_type, service.patient_count), getTarget(service.product_type)) }}%</strong></span
                    >
                  </b-progress-bar>
                </b-progress>
              </div>
            </li>
          </ul>

          <h2 class="content-title">Follow-up Phase (FP)</h2>
          <ul>
            <li
                v-for="(service, service_index) in services"
                :key="service_index"
            >
              <div
                  v-if="
                  ['clubfoot_appointment', 'brace', 'therapy_session_fp', 'therapy_appointment_fp', 'assistive_device_fp', 'Dental_fp', 'dental_appointment_fp'].includes(service.product_type) && (checkTargetExists(service.product_type) || filter.patientType == 'Dental' || filter.patientType == 'Physiotherapy')
                "
              >
                <div class="l-label">
                  <label>{{ labelUpdate(service.product_type) }}</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{ service.patient_count }}</span> <span v-if="getTarget(service.product_type)">/</span>
                  <span class="target" v-if="getTarget(service.product_type)">{{ getTarget(service.product_type) }}</span>
                </div>
                <b-progress
                    :max="max"
                    height="25px"
                    :variant="calculateProgress(service.patient_count, getTarget(service.product_type))>=100?'success':'warning'"
                    :precision="0"
                    show-progress
                    class="mb-3 brd-15 ftb-15 w-100"
                >
                <b-progress-bar :value="calculateProgress(service.patient_count, getTarget(service.product_type))">
                    <span>
                      <strong>{{ calculateProgress(service.patient_count, getTarget(service.product_type)) }}%</strong></span
                    >
                  </b-progress-bar>
                </b-progress>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="c-card p-7">
        <div class="patient-income-table tableFixHead">
          <table class="timeline-tbl">
            <thead>
            <tr>
              <th>Timeline</th>
              <th>New Patient</th>
              <th>TP Income</th>
              <th>FP Income</th>
              <th>Total Income</th>
            </tr>
            </thead>

            <tbody v-for="(data, index) in table_data" :key="index" :class="data.expand?'expanded-row':''">
            <tr v-if="!data.expand">
              <td @click="handleRowClicked(index, data.date)" style="cursor:pointer;">
                {{ data.isToday && filter.period == 'monthly'?'Today':dateFormat(data.date) }}
              </td>
              <td>{{ data.new_patient }}</td>
              <td>{{ numberFormat(data.tp_income) }}</td>
              <td>{{ numberFormat(data.fp_income) }}</td>
              <td>{{ numberFormat(data.tp_income + data.fp_income) }}</td>
            </tr>

            <tr v-if="data.expand && data.services && data.services.length" v-for="(service, sIndex) in data.services" :key="sIndex">
              <td :rowspan="data.services.length" v-if="sIndex == 0" style="vertical-align: top !important; cursor:pointer;" @click="handleRowClicked(index, data.date)">
                <strong>{{ data.isToday && filter.period == 'monthly'?'Today':dateFormat(data.date) }}</strong>
              </td>
              <td>{{ labelUpdate(service.product_type) }}</td>
              <td>
                <span v-if="['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'registration_fee', 'therapy_appointment', 'therapy_session', 'dental_appointment', 'Dental', 'Plaster Material'].includes(service.product_type)">{{ numberFormat(service.total_income) }}</span>
              </td>
              <td>
                <span v-if="!['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'registration_fee', 'therapy_appointment', 'therapy_session', 'dental_appointment', 'Dental', 'Plaster Material'].includes(service.product_type)">{{ numberFormat(service.total_income) }}</span>
              </td>
              <td>{{ numberFormat(service.total_income) }}</td>
            </tr>
            <tr v-if="data.expand && data.services && data.services.length" class="expanded-total-row">
              <td>
                <ul>
                  <li>
                    <strong>Total</strong>
                  </li>
                  <li>{{ (filter.period == 'monthly')?'Yesterday':'Last Month' }}</li>
                </ul>
              </td>
              <td></td>
              <td>
                <ul>
                  <li>
                    <strong>{{ numberFormat(data.tp_income) }}</strong>
                  </li>
                  <li>
                    <span :class="calculatePercentage(data.tp_income, data.prev_day.tp_income) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(data.tp_income, data.prev_day.tp_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(data.tp_income, data.prev_day.tp_income) }}%</span
                  >
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <strong>{{ numberFormat(data.fp_income) }}</strong>
                  </li>
                  <li>
                    <span :class="calculatePercentage(data.fp_income, data.prev_day.fp_income) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(data.fp_income, data.prev_day.fp_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(data.fp_income, data.prev_day.fp_income) }}%</span
                  >
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <strong>{{ numberFormat(data.tp_income + data.fp_income) }}</strong>
                  </li>
                  <li>
                    <span :class="calculatePercentage(data.tp_income + data.fp_income, data.prev_day.tp_income + data.prev_day.fp_income) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(data.tp_income + data.fp_income, data.prev_day.tp_income + data.prev_day.fp_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(data.tp_income + data.fp_income, data.prev_day.tp_income + data.prev_day.fp_income) }}%</span
                  >
                  </li>
                </ul>
              </td>
            </tr>

            <tr v-if="data.expand && data.services && !data.services.length">
              <td>
                <strong>{{ data.isToday && filter.period == 'monthly'?'Today':dateFormat(data.date) }}</strong>
              </td>
              <td colspan="4">
                <strong>Data not available</strong>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>

        <div class="summary-wrapper">
          <table class="summary-tbl">
            <thead>
            <tr>
              <th style="width: 14%;">{{ (filter.period == 'monthly')?filter.month+' '+filter.year: 'Q'+filter.quarter+' '+filter.year }} </th>
              <th style="width: 20%; text-align: center;">{{  incomeCalculate('new_patient') }}</th>
              <th style="width: 26%; text-align: center;">{{  numberFormat(incomeCalculate('tp_income')) }}</th>
              <th style="width: 20%; text-align: center;">{{  numberFormat(incomeCalculate('fp_income')) }}</th>
              <th style="width: 20%; text-align: center;">{{ numberFormat(incomeCalculate('tp_income') + incomeCalculate('fp_income')) }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width: 14%;">{{ (filter.period == 'monthly')?'Last Month': 'Last Quarter'}}</td>
              <td style="width: 20%; text-align: center;">
                  <span :class="calculatePercentage(incomeCalculate('new_patient'), lastMonthData.new_patient) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(incomeCalculate('new_patient'), lastMonthData.new_patient) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(incomeCalculate('new_patient'), lastMonthData.new_patient) }}%</span
                  >
              </td>
              <td style="width: 18%; text-align: center;">
                  <span :class="calculatePercentage(incomeCalculate('tp_income'), lastMonthData.tp_income) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(incomeCalculate('tp_income'), lastMonthData.tp_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(incomeCalculate('tp_income'), lastMonthData.tp_income) }}%</span
                  >
              </td>
              <td style="width: 20%; text-align: center;">
                  <span :class="calculatePercentage(incomeCalculate('fp_income'), lastMonthData.fp_income) <= 0?'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(incomeCalculate('fp_income'), lastMonthData.fp_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(incomeCalculate('fp_income'), lastMonthData.fp_income) }}%</span
                  >
              </td>
              <td style="width: 28%; text-align: center;">
                  <span :class="calculatePercentage(incomeCalculate('tp_income') + incomeCalculate('fp_income'), lastMonthData.fp_income + lastMonthData.tp_income) <= 0? 'down-text':'up-text'"
                  ><i class="fas" :class="calculatePercentage(incomeCalculate('tp_income') + incomeCalculate('fp_income'), lastMonthData.fp_income + lastMonthData.tp_income) <= 0? 'fa-chevron-down':'fa-chevron-up'"></i>
                  {{ calculatePercentage(incomeCalculate('tp_income') + incomeCalculate('fp_income'), lastMonthData.fp_income + lastMonthData.tp_income) }}
                  %</span
                  >
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "OCCReportView",
  data() {
    let all_months = [
      {
        text: "Q1",
        value: "1"
      },
      {
        text: "Jan",
        value: "January",
      },
      {
        text: "Feb",
        value: "February",
      },
      {
        text: "Mar",
        value: "March",
      },
      {
        text: "Q2",
        value: "2"
      },
      {
        text: "Apr",
        value: "April",
      },
      {
        text: "May",
        value: "May",
      },
      {
        text: "Jun",
        value: "June",
      },
      {
        text: "Q3",
        value: "3"
      },
      {
        text: "Jul",
        value: "July",
      },
      {
        text: "Aug",
        value: "August",
      },
      {
        text: "Sep",
        value: "September",
      },
      {
        text: "Q4",
        value: "4"
      },
      {
        text: "Oct",
        value: "October",
      },
      {
        text: "Nov",
        value: "November",
      },
      {
        text: "Dec",
        value: "December",
      },
    ];
    return {
      loading: false,
      value: 88,
      max: 100,
      months: all_months,
      filter: {
        period: 'monthly',
        month: '',
        center: '',
        patientType: 'Zero Clubfoot',
        year: new Date().getFullYear(),
        quarter: ''
      },
      month: all_months[new Date().getMonth()].value,
      start_year: 2023,
      year: new Date().getFullYear(),
      years: [],
      table_data: [],
      services: [],
      targets: [],
      lastMonthData: {},
      showMonthFilter: false,
      showCenterFilter: false,
      showPatientFilter: false,
      centers: [],
    };
  },
  created() {
    this.filter.month = moment().format('MMM');
    this.setYears();
    this.getReports();
    this.centerList();
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
  },
  methods: {
    selectPeriod(type, value) {
      this.filter.period = type;
      if(type == 'monthly') {
        this.filter.quarter = '';
        this.filter.month = value;
      } else {
        this.filter.month = '';
        this.filter.quarter = value;
      }
    },
    getYearLabel(year) {
        return year?String(year).substring(2,4):''
      },
      setYears() {
      let cYear = new Date().getFullYear();
      while ( this.start_year <= cYear ) {
        this.years.push(this.start_year++);
      }   
    },
    numberFormat(num) {
      return num?(Math.round(num * 100) / 100).toFixed(2):0;
    },
    calculateProgress(achieved, target) {
      if(target > 0) {
        return Math.floor((achieved*100)/target);
      }
      return 0;
    },
    checkTargetExists(product) {
      return this.targets.some(item => item.product_type == product);
    },
    getTarget(product) {
      if(product == 'therapy_appointment') {
        product = 'therapy_consultation'
      } else if(product == 'clubfoot_appointment') {
        product = 'clubfoot_consultation'
      } else if(product == 'dental_appointment') {
        product = 'dental_consultation'
      }
      let target = this.targets.find(item => item.product_type == product);
      if(target) {
        return target.total_target;
      }
      return 0;
    },
    getServiceCount(product, patientCount) {
      if(product == 'therapy_appointment' || product == 'therapy_session') {
        let service = this.services.find(item => item.product_type == product+'_fp');
        if(service) {
          return service.patient_count + patientCount;
        }
      }
      return patientCount;
    },
    handleRowClicked(index, date) {
      this.loading = true;
      let selectedCenter = null;
      if(this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }

      axios
          .get(
              `${
                  process.env.VUE_APP_API_URL
              }/api/v2/report/orkocenter/occ/service-detail/by-date?api_token=${localStorage.getItem(
                  "api_token"
              )}${this.filter.patientType?'&patient_type='+this.filter.patientType:''}${selectedCenter?'&institute_id='+selectedCenter.id:''}
              &date=${date}&period=${this.filter.period}`
          )
          .then((res) => {
            this.table_data = this.table_data.map(((item, key) => {
                          if(index == key) {
                            item.expand = !item.expand;
                            item.services = res.data.data.filter((product) => {
                               if(product.total_income) {
                                return product;
                               }
                            });
                            item.prev_day = res.data.prev_day;
                          } else {
                            item.expand = false;
                          }
                          return item;
                        }));


            this.loading = false;
          });


    },
    calculatePercentage(currentMonth, prevMonth) {
      let diff = currentMonth - prevMonth;
      if(diff != 0 && prevMonth != 0) {
        return Math.round((diff / prevMonth * 100) * 100) / 100
        // return Math.floor(diff / prevMonth * 100);
      } else if (diff != 0 && prevMonth == 0) {
        return Math.round(diff * 100) / 100
        // return Math.floor(diff);
      } else {
        return 0;
      }
    },
    incomeCalculate(type) {
      let total = 0;
      this.table_data.forEach((item) => {
        total += item[type]
      });
      return total;
    },
    dateFormat(date) {
      if(this.filter.period == 'monthly') {
        return date?moment(date).format('D MMM'):'';
      } else {
        return date?moment(date).format('MMM YYYY'):'';
      }
    },
    labelUpdate(label) {
      if(label == 'Ponseti (Plaster)') { return 'Casting'}
      else if(label == 'Ponseti (Surgery)') { return 'Surgery' }
      else if(label == 'Ponseti (Tenotomy)') { return 'Tenotomy' }
      else if(label == 'registration_fee') { return 'Registration' }
      else if(label == 'brace') { return 'Bracing' }
      else if(label == 'appointment') { return 'Consultation' }
      else if(label == 'therapy_appointment') { return 'Consultation' }
      else if(label == 'therapy_appointment_fp') { return 'Consultation' }
      else if(label == 'clubfoot_appointment') { return 'Consultation' }
      else if(label == 'dental_appointment') { return 'Consultation' }
      else if(label == 'dental_appointment_fp') { return 'Consultation' }
      else if(label == 'Dental') { return 'Procedure' }
      else if(label == 'Dental_fp') { return 'Procedure' }
      else if(label == 'therapy_session') { return 'Session' }
      else if(label == 'therapy_session_fp') { return 'Session' }
      else if(label == 'assistive_device') { return 'Assistive Device' }
      else if(label == 'assistive_device_fp') { return 'Assistive Device' }
      else if(label == 'Plaster Material') { return 'Plaster Material' }
      else {
        return 'Label not matched';
      }
    },
    selectFilter(type) {
      if (type == "Month") {
        this.showCenterFilter = false;
        this.showPatientFilter = false;
        this.showMonthFilter = !this.showMonthFilter;
      } else if (type == "Center") {
        this.showMonthFilter = false;
        this.showPatientFilter = false;
        this.showCenterFilter = !this.showCenterFilter;
      } else if (type == "Patient") {
        this.showMonthFilter = false;
        this.showCenterFilter = false;
        this.showPatientFilter = !this.showPatientFilter;
      }
    },
    getReports() {
      this.loading = true;
      let selectedCenter = null;
      if(this.filter.center) {
        selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
      }
      axios
          .get(
              `${
                  process.env.VUE_APP_API_URL
              }/api/v2/report/orkocenter/occ/service?api_token=${localStorage.getItem(
                  "api_token"
              )}${this.filter.month?'&month='+this.filter.month:''}${selectedCenter?'&institute_id='+selectedCenter.id:''}
              ${this.filter.patientType?'&patient_type='+this.filter.patientType:''}
              &period=${this.filter.period}&year=${this.filter.year}${this.filter.quarter?'&quarter='+this.filter.quarter:''}`
          )
          .then((res) => {
            this.table_data = res.data.data.data.reverse();
            this.table_data = this.table_data.map((item => {
              item.prev_day = {};
              item.services = [];
              if(moment(item.date).isSame(moment(), 'day')) {
                item.expand = false; //TODO
                item.isToday = true;
              } else {
                item.expand = false;
                item.isToday = false;
              }
              return item;
            }))

            this.services = res.data.service_counts;
            this.lastMonthData = res.data.last_month;
            this.targets = res.data.target_counts;
            this.loading = false;

            let servArr = [];
            if(this.filter.patientType == 'Zero Clubfoot') {
              servArr = ['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'registration_fee', 'brace', 'clubfoot_appointment'];  
            } else if (this.filter.patientType == 'Dental') {
              servArr = ['Dental', 'dental_appointment', 'Dental_fp', 'dental_appointment_fp'];  
            } else if (this.filter.patientType == 'Plaster Material') {
              servArr = ['Plaster Material'];  
            } else {
              servArr = ['therapy_appointment', 'therapy_session', 'assistive_device', 'therapy_appointment_fp', 'therapy_session_fp', 'assistive_device_fp'];  
            }
            
            servArr.forEach(serv => {
              if(!this.services.some(item => item.product_type == serv)) {
                this.services.push({ product_type: serv, patient_count: 0, total_income: 0 })
              }
            });

            this.services = this.sortService(this.services, ['registration_fee', 'Ponseti (Plaster)', 'Ponseti (Tenotomy)', 'Ponseti (Surgery)', 'clubfoot_appointment', 'brace', 'therapy_appointment', 'Plaster Material', 'therapy_appointment_fp', 'therapy_session','therapy_session_fp', 'assistive_device', 'dental_appointment', 'dental_appointment_fp'])
          
          });
    },
    sortService(services, orders)
    {
        let newArr = [];
        for (let i=0; i<orders.length; i++) {
          if (services.some(e => e.product_type === orders[i])) {
            newArr.push(services.find(e => e.product_type === orders[i]))
          }
        }

        
        for (let k=0; k<services.length; k++) {
          if (!newArr.some(e => e.product_type === services[k].product_type)) {
            newArr.push(services.find(e => e.product_type === services[k].product_type))
          }
        }
        return newArr;
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              // this.loading = false
              this.centers = response.data.data
            }
          })
    },
  },
};
</script>
<style lang="scss" scoped>
.c-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #fff;
  width: 100%;
  float: left;

  .filter-list {
    width: 100%;
    float: left;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      width: 33.333333%;
      float: left;
      padding: 0px 10px 0;
      border-right: 2px solid #002170;

      &:last-of-type {
        border-right: none;
        padding: 0;
      }

      select {
        border: none;
        outline: none;
        scroll-behavior: smooth;
        -webkit-appearance: listbox !important;

        color: #002170;
        font-size: 15px;
        font-weight: 600;
        font-family: Arial;
        padding: 0 12px 0 8px;
        height: 22px;
      }
    }
  }

  .target-content {
    width: 100%;
    float: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #00579b;
    padding: 25px;

    h2 {
      color: #f9a91a;
      font-family: Arial;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: none;
      margin-bottom: 20px;
      width: 100%;
      float: left;

      li {
        width: 100%;
        float: left;

        .l-label {
          width: 45%;
          float: left;
        }

        .r-label {
          text-align: right;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          font-family: Arial;

          .achieved {
            color: #f9a91a;
          }
        }

        label {
          color: #fff;
          font-size: 18px;
          font-family: Arial;
          font-weight: 600;
        }
      }
    }
  }

  .timeline-tbl {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    float: left;
    .expanded-row {
      box-shadow: 0 0 0 1px #AAA;
      border-radius: 20px;
    }

    tr {
      th {
        color: #002147;
        border-bottom: 1px solid #a0a0a1;
        padding-bottom: 10px;
        text-align: center;
        font-size: 16px;
      }
    }

    tbody {
      tr {
        &.expanded-total-row {
          border-top: 1px solid #AAA;
         td {
            ul {
              margin: 6px 0;
              list-style-type: none;
              li {
                line-height: 1.6;
                &:last-of-type {
                  font-size: 13px;
                  span {
                    font-size: 13px;
                    i {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
         }
        }
        td {
          text-align: center;
          font-size: 16px;
          line-height: 2.5;
          color: #002170;
        }

        &:first-of-type {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .summary-wrapper {
    width: 100%;
    float: left;
    background-color: #00579b;
    padding: 8px 30px;
    border-radius: 20px;
    margin-top: 30px;

    .summary-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;

      tr {
        th {
          color: #fff;
          font-size: 17px;
          padding-bottom: 5px;
        }

        td {
          font-size: 15px;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
}

.brd-15 {
  border-radius: 15px;
}

.ftb-15 {
  font-size: 15px;
  font-weight: 600;
}

.up-text {
  color: #00cd32;
  font-size: 15px;
  font-weight: 600;

  i {
    color: #00cd32;
    font-size: 13px;
  }
}

.down-text {
  color: red;
  font-size: 15px;
  font-weight: 600;

  i {
    color: red;
    font-size: 13px;
  }
}

.coll-btn {
  color: #002170;
  font-size: 15px;
  font-weight: 600;
  font-family: Arial;
  padding: 0 12px 0 8px;
  height: 22px;

  background-color: transparent;
  border: none;

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.month-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}

.year-selection-list {
  width: 100%;
 
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  li {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      background-color: #d9d4d4;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;
      margin: 5px;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }

    &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
      a {
        background-color: #AAAAAA;
        color: #fff;
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
}

.center-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}

.patient-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 50%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #EBEBEB;
      padding: 8px 30px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #F9A81A;
        font-weight: 600;
      }
    }
  }
}


.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.patient-income-table {
  height: 475px;
  overflow: scroll;
  // padding: 2px;
}
</style>
