<template>
    <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <!-- <div class="row mt-5 align-items-center justify-content-between">
        <div class="col-md-4">
          <label for="">From</label>
          <input type="date" v-model="filter.start_date" class="form-control">
        </div>
        <div class="col-md-4">
          <label for="">To</label>
          <input type="date" v-model="filter.end_date" class="form-control">
        </div>
        <div class="col-md-2 mt-7">
          <button class="btn btn-success" @click="getItems()">Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>

      </div> -->
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              tbody-tr-class="rowClass"
              class="mt-5"
          >
              

            <template #cell(image)="row">
              <div v-if="row.item.image">
                <b-img :src="userPhoto(row.item.image)" class="user_image" :alt="row.item.image"
                           v-if="row.item.image"></b-img>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              class="my-0"
              @change="getItems"
          ></b-pagination>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>
import axios from 'axios'
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
    name: 'BlogCategory',
    data(){
        return {
            items: [],
            fields: [
                {
                    key: 'name',
                    label: 'Name'
                },
                {
                    key: 'type',
                    label: 'Type'
                },
                {
                    key: 'image',
                    label: 'Image'
                }
            ],
            filter: {},
            currentPage: 1,
            perPage: 20,
            total_count: 0,
            loading: false,
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Blog  Category", route: "/blogs/category"},
        ]);
    },
    created(){
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.getItems();
    },
    methods: {
        getItems() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/v2/blogs/categories?api_token=${localStorage.getItem('api_token')}`).then(res => {
                this.items = res.data.data;
            })
        },
        clear(){
            this.filter = {};
        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
    }
}
</script>