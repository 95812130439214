<template>
    <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <button type="button" class="btn btn-primary" @click="addNewTarget">Add New service</button>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            <template #cell(image)="row">
              <img :src="userPhoto(row.item.image)" alt="" class="img-fluid user-image">
            </template>
            <template #cell(status)="row">
              <b-badge v-if="row.item.status==1" variant="success">Active</b-badge>
              <b-badge v-else variant="danger">In active</b-badge>
            </template>
            <template #cell(institute_name)="row">
              {{  row.item.institute_name ? row.item.institute_name : 'Global Service' }}
            </template>
            <template #cell(max_age)="row">
              {{  row.item.max_age ? row.item.max_age : 'n/a' }}
            </template>
            <template #cell(action)="row">
              <div v-if="row.item.institute_id">
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <div>
                    <b-dropdown-item href="#" @click="editAction(row.item)">
                      <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <b-modal
          id="addService"
          ref="modal"
          :title="`${this.form.id ? 'Update' : 'Add new'} Service`"
          @hidden="resetModal"
          @ok="handleOk"
          size="lg">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="6">
              <label>Select center</label>
              <b-form-select v-model="form.institute_id" value-field="id" text-field="name" :options="centers"></b-form-select>
            </b-col>
            <b-col cols="6">
              <label>Name</label>
              <b-form-input v-model="form.name"></b-form-input>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Group name</label>
              <b-form-select v-model="form.group_name" :options="groups"></b-form-select>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Select status</label>
              <b-form-select v-model="form.status" :options="status"></b-form-select>
            </b-col>
            
            <b-col cols="6" class="my-5">
              <label>Price</label>
              <b-form-input v-model="form.price"></b-form-input>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Min Price</label>
              <b-form-input v-model="form.min_price"></b-form-input>
            </b-col>

            <b-col cols="12" class="my-5">
              <label>Description</label>
              <b-form-textarea v-model="form.description"></b-form-textarea>
            </b-col>
            
          </b-row>
        </form>
      </b-modal>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
    name : 'Services',
    title: 'Services',
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'institute_name',
          label: 'Center',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'group_name',
          label: 'Group',
        },
        {
          key: 'price',
          label: 'Price',
        },
        {
          key: 'min_price',
          label: 'Min price',
        },
        {
          key: 'max_age',
          label: 'Max age',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      loading: false,
      form: {
        id: '',
        status: '',
        name: '',
        group_name: 'dental',
        price: '',
        max_price: '',
        institute_id: '',
        description: '',
      },
      status: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'In active',
          value: 0,
        },
      ],
      types: [
        {
          text: 'About',
          value: 'about',
        },
        {
          text: 'Physio',
          value: 'physio',
        },
        {
          text: 'CF',
          value: 'cf',
        },
        {
          text: 'Both',
          value: 'both',
        },
      ],
      centers: [],
      groups: [
        {
          text: 'Dental',
          value: 'dental',
        },
      ]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Services", route: "/manage/services"},
    ]);
  },
  created() {
    this.getItems();
    this.centerList();
    localStorage.setItem('tabIndex', 7)
    this.$root.$emit('call-active-menu', 7);
  },
  methods: {
    getItems() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/orko-services?api_token=${localStorage.getItem('api_token')}`).then(res => {
        this.items = res.data.data;
        this.loading = false;
      })
    },

    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.loading = false
              this.centers = response.data.data
            }
          })
    },

    addNewTarget() {
      this.$bvModal.show('addService')
    },
    
    editAction(item) {
    //   console.log('template-id', item)
      this.form = item;
      this.form.template_id = item.id;
      this.$bvModal.show('addService')
    },
    resetModal() {
      this.form = {
        id: '',
        status: '',
        name: '',
        group_name: 'dental',
        price: '',
        max_price: '',
        institute_id: '',
        description: '',
      };
      this.getItems();
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },

    handleSubmit() {
      this.$nextTick(() => {
        let url = '';
        if(this.form.id) {
            url = `${process.env.VUE_APP_API_URL}/api/v2/orko-services/${this.form.id}?api_token=${localStorage.getItem('api_token')}&_method=put`
        }else {
             url = `${process.env.VUE_APP_API_URL}/api/v2/orko-services?api_token=${localStorage.getItem('api_token')}`;
        }
           

        axios.post(url, this.form).then(res => {
          if (res.data.status_code == 200) {
            this.getItems();
            this.$bvModal.hide('addService')
            this.$snotify.success('Successfully saved');
          } else {
            this.$snotify.error(res.data.message);
          }
        })
      })
    },
  }
}
</script>