<template>
  <div class="row mt-60">
    <loader-component v-if="loading" />
    <div class="col-md-4">
      <div class="c-card">
        <div class="filter-section">
          <form>
            <ul class="filter-list">
              <li>
                <b-button @click="selectFilter('Month')" class="coll-btn"
                  >{{
                    filter.quarter
                      ? "Q" + filter.quarter
                      : filter.month
                      ? filter.month
                      : "Month"
                  }}
                  {{ getYearLabel(filter.year) }}
                  <i class="fas fa-chevron-down"></i
                ></b-button>

                <!-- Element to collapse -->
              </li>
              <li>
                <b-button @click="selectFilter('Center')" class="coll-btn"
                  >{{ filter.center || "All Center" }}
                  <i class="fas fa-chevron-down"></i
                ></b-button>
              </li>
            </ul>

            <b-collapse id="collapse-1" v-model="showMonthFilter">
              <ul class="month-selection-list">
                <li v-for="(item, key) in months" :key="key">
                  <a
                    v-if="!['Q1', 'Q2', 'Q3', 'Q4'].includes(item.text)"
                    href="javascript:void(0)"
                    :class="item.text == filter.month ? 'active' : ''"
                    @click="
                      selectPeriod('monthly', item.text);
                      showMonthFilter = false;
                      getReports();
                    "
                    >{{ item.text }}</a
                  >
                  <a
                    v-else
                    href="javascript:void(0)"
                    :class="item.value == filter.quarter ? 'active' : ''"
                    @click="
                      selectPeriod('quarterly', item.value);
                      showMonthFilter = false;
                      getReports();
                    "
                    >{{ item.text }}</a
                  >
                </li>
              </ul>
              <ul class="year-selection-list">
                <li>
                  <a
                    href="javascript:void(0)"
                    :class="filter.year == year ? 'active' : ''"
                    @click="
                      filter.year = year;
                      getReports();
                    "
                    v-for="(year, index) in years"
                    :key="index"
                    >{{ year }}</a
                  >
                </li>
              </ul>
            </b-collapse>

            <b-collapse id="collapse-2" v-model="showCenterFilter">
              <ul class="center-selection-list">
                <li>
                  <a
                    href="javascript:void(0)"
                    :class="filter.center == '' ? 'active' : ''"
                    @click="
                      filter.center = '';
                      showCenterFilter = false;
                      getReports();
                    "
                    >All</a
                  >
                </li>
                <li v-for="(item, key) in centers" :key="key">
                  <a
                    href="javascript:void(0)"
                    :class="item.short_form == filter.center ? 'active' : ''"
                    @click="
                      filter.center = item.short_form;
                      showCenterFilter = false;
                      getReports();
                    "
                    >{{ item.short_form }}</a
                  >
                </li>
              </ul>
            </b-collapse>
          </form>
        </div>
        <div class="target-content">
          <h2 class="content-title">No of Service</h2>
          <ul>
            <li
              v-for="(service, service_index) in services"
              :key="service_index"
            >
              <div v-if="checkTargetExists(service.product_type)">
                <div class="l-label">
                  <label>{{ service.product_type }}</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{ service.patient_count }}</span> /
                  <span class="target">{{
                    getTarget(service.product_type, "total_target")
                  }}</span>
                </div>
                <b-progress
                  :max="max"
                  height="25px"
                  :variant="
                    calculateProgress(
                      service.patient_count,
                      getTarget(service.product_type, 'total_target')
                    ) >= 100
                      ? 'success'
                      : 'warning'
                  "
                  :precision="0"
                  show-progress
                  class="mb-3 brd-15 ftb-15 w-100"
                >
                  <b-progress-bar
                    :value="
                      calculateProgress(
                        service.patient_count,
                        getTarget(service.product_type, 'total_target')
                      )
                    "
                  >
                    <span>
                      <strong
                        >{{
                          calculateProgress(
                            service.patient_count,
                            getTarget(service.product_type, "total_target")
                          )
                        }}%</strong
                      ></span
                    >
                  </b-progress-bar>
                </b-progress>
              </div>
              <div
                class="my-4"
                v-else-if="
                  (service.product_type == 'Sp. Consultation' ||
                    service.product_type == 'Home Service') &&
                    service.patient_count
                "
              >
                <div class="l-label">
                  <label>{{ service.product_type }}</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{ service.patient_count }}</span>
                </div>
              </div>
            </li>
            <li class="total">
              <div>
                <div class="l-label">
                  <label>Total</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{
                    serviceTotal("patient_count")
                  }}</span>
                  /
                  <span class="target">{{ targetTotal("total_target") }}</span>
                </div>
                <b-progress
                  :max="max"
                  height="25px"
                  :variant="
                    calculateProgress(
                      serviceTotal('patient_count'),
                      targetTotal('total_target')
                    ) >= 100
                      ? 'success'
                      : 'warning'
                  "
                  :precision="0"
                  show-progress
                  class="mb-3 brd-15 ftb-15 w-100"
                >
                  <b-progress-bar
                    :value="
                      calculateProgress(
                        serviceTotal('patient_count'),
                        targetTotal('total_target')
                      )
                    "
                  >
                    <span>
                      <strong
                        >{{
                          calculateProgress(
                            serviceTotal("patient_count"),
                            targetTotal("total_target")
                          )
                        }}%</strong
                      ></span
                    >
                  </b-progress-bar>
                </b-progress>
              </div>
            </li>
          </ul>

          <h2 class="content-title">Income</h2>
          <ul>
            <li
              v-for="(service, service_index) in services"
              :key="service_index"
            >
              <div v-if="checkTargetExists(service.product_type)">
                <div class="l-label">
                  <label>{{
                    service.product_type == "Dental"
                      ? "Dental (Income)"
                      : service.product_type
                  }}</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{
                    service.product_type == "Dental"
                      ? numberFormat(
                          Math.round((service.total_income * 15) / 100)
                        )
                      : numberFormat(service.total_income)
                  }}</span>
                  /
                  <span class="target">{{
                    getTarget(service.product_type, "total_income")
                  }}</span>
                </div>
                <b-progress
                  :max="max"
                  height="25px"
                  :variant="
                    calculateProgress(
                      service.product_type == 'Dental'
                        ? Math.round((service.total_income * 15) / 100)
                        : service.total_income,
                      getTarget(service.product_type, 'total_income')
                    ) >= 100
                      ? 'success'
                      : 'warning'
                  "
                  :precision="0"
                  show-progress
                  class="mb-3 brd-15 ftb-15 w-100"
                >
                  <b-progress-bar
                    :value="
                      calculateProgress(
                        service.product_type == 'Dental'
                          ? Math.round((service.total_income * 15) / 100)
                          : service.total_income,
                        getTarget(service.product_type, 'total_income')
                      )
                    "
                  >
                    <span>
                      <strong
                        >{{
                          calculateProgress(
                            service.product_type == "Dental"
                              ? Math.round((service.total_income * 15) / 100)
                              : service.total_income,
                            getTarget(service.product_type, "total_income")
                          )
                        }}%</strong
                      ></span
                    >
                  </b-progress-bar>
                </b-progress>
              </div>
              <div
                class="my-4"
                v-else-if="
                  (service.product_type == 'Sp. Consultation' ||
                    service.product_type == 'Home Service') &&
                    service.total_income
                "
              >
                <div class="l-label">
                  <label>{{ service.product_type }}</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{
                    numberFormat(service.total_income)
                  }}</span>
                </div>
              </div>
            </li>

            <li class="total">
              <div>
                <div class="l-label">
                  <label>Total (Orko)</label>
                </div>
                <div class="r-label">
                  <span class="achieved">{{
                    numberFormat(serviceTotal("total_income"))
                  }}</span>
                  /
                  <span class="target">{{ targetTotal("total_income") }}</span>
                </div>
                <b-progress
                  :max="max"
                  height="25px"
                  :variant="
                    calculateProgress(
                      serviceTotal('total_income'),
                      targetTotal('total_income')
                    ) >= 100
                      ? 'success'
                      : 'warning'
                  "
                  :precision="0"
                  show-progress
                  class="mb-3 brd-15 ftb-15 w-100"
                >
                  <b-progress-bar
                    :value="
                      calculateProgress(
                        serviceTotal('total_income'),
                        targetTotal('total_income')
                      )
                    "
                  >
                    <span>
                      <strong
                        >{{
                          calculateProgress(
                            serviceTotal("total_income"),
                            targetTotal("total_income")
                          )
                        }}%</strong
                      ></span
                    >
                  </b-progress-bar>
                </b-progress>
              </div>
            </li>
            <li class="mt-3">
              <div>
                <div class="l-label">
                  <label>Total (Collection)</label>
                </div>
                <div class="r-label">
                  <span class="target">{{
                    numberFormat(incomeCalculate("total_income"))
                  }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="c-card p-7">
        <div class="patient-income-table tableFixHead">
          <table class="timeline-tbl">
            <thead>
              <tr>
                <th>Timeline</th>
                <th>Service</th>
                <th>New Patient</th>
                <th>No of service</th>
                <th>Income (Tk.)</th>
              </tr>
            </thead>

            <tbody
              v-for="(data, index) in table_data"
              :key="index"
              :class="data.expand ? 'expanded-row' : ''"
            >
              <tr v-if="!data.expand">
                <td
                  @click="handleRowClicked(index, data.date)"
                  style="cursor:pointer;"
                >
                  {{
                    data.isToday && filter.period == "monthly"
                      ? "Today"
                      : dateFormat(data.date)
                  }}
                </td>
                <td>{{ data.label }}</td>
                <td>{{ data.new_patient }}</td>
                <td>{{ data.patient_count }}</td>
                <td>{{ numberFormat(data.total_income) }}</td>
              </tr>

              <tr
                v-if="data.expand && data.services && data.services.length"
                v-for="(service, sIndex) in data.services"
                :key="sIndex"
              >
                <td
                  :rowspan="data.services.length"
                  v-if="sIndex == 0"
                  style="vertical-align: top !important; cursor:pointer;"
                  @click="handleRowClicked(index, data.date)"
                >
                  <strong>{{
                    data.isToday && filter.period == "monthly"
                      ? "Today"
                      : dateFormat(data.date)
                  }}</strong>
                </td>
                <td>{{ service.product_type }}</td>
                <td>
                  {{ service.new_patient }}
                </td>
                <td>
                  {{ service.patient_count }}
                </td>
                <td>{{ numberFormat(service.total_income) }}</td>
              </tr>
              <tr
                v-if="data.expand && data.services && data.services.length"
                class="expanded-total-row"
              >
                <td>
                  <ul>
                    <li>
                      <strong>Total</strong>
                    </li>
                    <li>
                      {{
                        filter.period == "monthly" ? "Yesterday" : "Last Month"
                      }}
                    </li>
                  </ul>
                </td>
                <td></td>
                <td>
                  <ul>
                    <li>
                      <strong>{{ data.new_patient }}</strong>
                    </li>
                    <li>
                      <span
                        :class="
                          calculatePercentage(
                            data.new_patient,
                            data.prev_day.new_patient
                          ) <= 0
                            ? 'down-text'
                            : 'up-text'
                        "
                        ><i
                          class="fas"
                          :class="
                            calculatePercentage(
                              data.new_patient,
                              data.prev_day.new_patient
                            ) <= 0
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                          "
                        ></i>
                        {{
                          calculatePercentage(
                            data.new_patient,
                            data.prev_day.new_patient
                          )
                        }}%</span
                      >
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      <strong>{{ data.patient_count }}</strong>
                    </li>
                    <li>
                      <span
                        :class="
                          calculatePercentage(
                            data.patient_count,
                            data.prev_day.patient_count
                          ) <= 0
                            ? 'down-text'
                            : 'up-text'
                        "
                        ><i
                          class="fas"
                          :class="
                            calculatePercentage(
                              data.patient_count,
                              data.prev_day.patient_count
                            ) <= 0
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                          "
                        ></i>
                        {{
                          calculatePercentage(
                            data.patient_count,
                            data.prev_day.patient_count
                          )
                        }}%</span
                      >
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      <strong>{{ numberFormat(data.total_income) }}</strong>
                    </li>
                    <li>
                      <span
                        :class="
                          calculatePercentage(
                            data.total_income,
                            data.prev_day.total_income
                          ) <= 0
                            ? 'down-text'
                            : 'up-text'
                        "
                        ><i
                          class="fas"
                          :class="
                            calculatePercentage(
                              data.total_income,
                              data.prev_day.total_income
                            ) <= 0
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                          "
                        ></i>
                        {{
                          calculatePercentage(
                            data.total_income,
                            data.prev_day.total_income
                          )
                        }}%</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>

              <tr v-if="data.expand && data.services && !data.services.length">
                <td>
                  <strong>{{
                    data.isToday && filter.period == "monthly"
                      ? "Today"
                      : dateFormat(data.date)
                  }}</strong>
                </td>
                <td colspan="4">
                  <strong>Data not available</strong>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="summary-wrapper">
          <table class="summary-tbl">
            <thead>
              <tr>
                <th style="width: 36%;">
                  {{
                    filter.period == "monthly"
                      ? filter.month + " " + filter.year
                      : "Q" + filter.quarter + " " + filter.year
                  }}
                </th>
                <th style="width: 22%; text-align: center;">
                  {{ incomeCalculate("new_patient") }}
                </th>
                <th style="width: 20%; text-align: center;">
                  {{ incomeCalculate("patient_count") }}
                </th>
                <th style="width: 22%; text-align: right;">
                  {{ numberFormat(incomeCalculate("total_income")) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 36%;">
                  {{
                    filter.period == "monthly" ? "Last Month" : "Last Quarter"
                  }}
                </td>
                <td style="width: 22%; text-align: center;">
                  <span
                    :class="
                      calculatePercentage(
                        incomeCalculate('new_patient'),
                        lastMonthData.new_patient
                      ) <= 0
                        ? 'down-text'
                        : 'up-text'
                    "
                    ><i
                      class="fas"
                      :class="
                        calculatePercentage(
                          incomeCalculate('new_patient'),
                          lastMonthData.new_patient
                        ) <= 0
                          ? 'fa-chevron-down'
                          : 'fa-chevron-up'
                      "
                    ></i>
                    {{
                      calculatePercentage(
                        incomeCalculate("new_patient"),
                        lastMonthData.new_patient
                      )
                    }}%</span
                  >
                </td>
                <td style="width: 20%; text-align: center;">
                  <span
                    :class="
                      calculatePercentage(
                        incomeCalculate('patient_count'),
                        lastMonthData.patient_count
                      ) <= 0
                        ? 'down-text'
                        : 'up-text'
                    "
                    ><i
                      class="fas"
                      :class="
                        calculatePercentage(
                          incomeCalculate('patient_count'),
                          lastMonthData.patient_count
                        ) <= 0
                          ? 'fa-chevron-down'
                          : 'fa-chevron-up'
                      "
                    ></i>
                    {{
                      calculatePercentage(
                        incomeCalculate("patient_count"),
                        lastMonthData.patient_count
                      )
                    }}%</span
                  >
                </td>
                <td style="width: 22%; text-align: right;">
                  <span
                    :class="
                      calculatePercentage(
                        incomeCalculate('total_income'),
                        lastMonthData.total_income
                      ) <= 0
                        ? 'down-text'
                        : 'up-text'
                    "
                    ><i
                      class="fas"
                      :class="
                        calculatePercentage(
                          incomeCalculate('total_income'),
                          lastMonthData.total_income
                        ) <= 0
                          ? 'fa-chevron-down'
                          : 'fa-chevron-up'
                      "
                    ></i>
                    {{
                      calculatePercentage(
                        incomeCalculate("total_income"),
                        lastMonthData.total_income
                      )
                    }}%</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "CenterReportView",
  data() {
    let all_months = [
      {
        text: "Q1",
        value: "1",
      },
      {
        text: "Jan",
        value: "January",
      },
      {
        text: "Feb",
        value: "February",
      },
      {
        text: "Mar",
        value: "March",
      },
      {
        text: "Q2",
        value: "2",
      },
      {
        text: "Apr",
        value: "April",
      },
      {
        text: "May",
        value: "May",
      },
      {
        text: "Jun",
        value: "June",
      },
      {
        text: "Q3",
        value: "3",
      },
      {
        text: "Jul",
        value: "July",
      },
      {
        text: "Aug",
        value: "August",
      },
      {
        text: "Sep",
        value: "September",
      },
      {
        text: "Q4",
        value: "4",
      },
      {
        text: "Oct",
        value: "October",
      },
      {
        text: "Nov",
        value: "November",
      },
      {
        text: "Dec",
        value: "December",
      },
    ];
    return {
      loading: false,
      value: 88,
      max: 100,
      months: all_months,
      filter: {
        period: "monthly",
        month: "",
        center: "",
        year: new Date().getFullYear(),
        quarter: "",
      },
      month: all_months[new Date().getMonth()].value,
      start_year: 2023,
      year: new Date().getFullYear(),
      years: [],
      table_data: [],
      services: [],
      targets: [],
      lastMonthData: {},
      showMonthFilter: false,
      showCenterFilter: false,
      showPatientFilter: false,
      centers: [],
    };
  },
  created() {
    this.filter.month = moment().format("MMM");
    this.setYears();
    this.getReports();
    this.centerList();

    localStorage.setItem("tabIndex", 4);
    this.$root.$emit("call-active-menu", 4);
  },
  methods: {
    selectPeriod(type, value) {
      this.filter.period = type;
      if (type == "monthly") {
        this.filter.quarter = "";
        this.filter.month = value;
      } else {
        this.filter.month = "";
        this.filter.quarter = value;
      }
    },
    getYearLabel(year) {
      return year ? String(year).substring(2, 4) : "";
    },
    setYears() {
      let cYear = new Date().getFullYear();
      while (this.start_year <= cYear) {
        this.years.push(this.start_year++);
      }
    },
    numberFormat(num) {
      return num ? (Math.round(num * 100) / 100).toFixed(2) : 0;
    },
    calculateProgress(achieved, target) {
      if (target > 0) {
        return Math.floor((achieved * 100) / target);
      }
      return 0;
    },
    checkTargetExists(product) {
      return this.targets.some((item) => item.product_type == product);
    },
    getTarget(product, fieldName) {
      let target = this.targets.find((item) => item.product_type == product);
      if (target) {
        return target[fieldName];
      }
      return 0;
    },
    handleRowClicked(index, date) {
      this.loading = true;
      let selectedCenter = null;
      if (this.filter.center) {
        selectedCenter = this.centers.find(
          (item) => item.short_form == this.filter.center
        );
      }

      axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v2/report/orkocenter/occ/service-detail/by-date?type=center&api_token=${localStorage.getItem(
            "api_token"
          )}${selectedCenter ? "&institute_id=" + selectedCenter.id : ""}
              &date=${date}&period=${this.filter.period}`
        )
        .then((res) => {
          this.table_data = this.table_data.map((item, key) => {
            if (index == key) {
              item.expand = !item.expand;
              // item.services = res.data.data;
              item.services = res.data.data.filter((product) => {
                               if(product.total_income) {
                                return product;
                               }
                            });
              item.prev_day = res.data.prev_day;
            } else {
              item.expand = false;
            }
            return item;
          });

          this.loading = false;
        });
    },
    calculatePercentage(currentMonth, prevMonth) {
      prevMonth = prevMonth ? prevMonth : 0;
      let diff = currentMonth - prevMonth;
      if (diff != 0 && prevMonth != 0) {
        return Math.round((diff / prevMonth) * 100 * 100) / 100;
        // return Math.floor(diff / prevMonth * 100);
      } else if (diff != 0 && prevMonth == 0) {
        return Math.round(diff * 100) / 100;
        // return Math.floor(diff);
      } else {
        return 0;
      }
    },
    incomeCalculate(type) {
      let total = 0;
      this.table_data.forEach((item) => {
        total += item[type];
      });
      return total;
    },
    serviceTotal(type) {
      let total = 0;
      this.services.forEach((item) => {
        if (type == "total_income" && item.product_type == "Dental") {
          total += Math.round((item[type] * 15) / 100);
        } else {
          total += item[type];
        }
      });
      return total;
    },
    targetTotal(type) {
      let total = 0;
      this.targets.forEach((item) => {
        total += item[type];
      });
      return total;
    },
    dateFormat(date) {
      if (this.filter.period == "monthly") {
        return date ? moment(date).format("D MMM") : "";
      } else {
        return date ? moment(date).format("MMM YYYY") : "";
      }
    },
    selectFilter(type) {
      if (type == "Month") {
        this.showCenterFilter = false;
        this.showMonthFilter = !this.showMonthFilter;
      } else if (type == "Center") {
        this.showMonthFilter = false;
        this.showCenterFilter = !this.showCenterFilter;
      }
    },
    getReports() {
      this.loading = true;
      let selectedCenter = null;
      if (this.filter.center) {
        selectedCenter = this.centers.find(
          (item) => item.short_form == this.filter.center
        );
      }
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v2/report/orkocenter/occ/center?api_token=${localStorage.getItem(
            "api_token"
          )}${this.filter.month ? "&month=" + this.filter.month : ""}${
            selectedCenter ? "&institute_id=" + selectedCenter.id : ""
          }
              &period=${this.filter.period}&year=${this.filter.year}${
            this.filter.quarter ? "&quarter=" + this.filter.quarter : ""
          }`
        )
        .then((res) => {
          this.table_data = res.data.data.data.reverse();
          this.table_data = this.table_data.map((item) => {
            item.prev_day = {};
            item.services = [];
            if (moment(item.date).isSame(moment(), "day")) {
              item.expand = false; //TODO
              item.isToday = true;
            } else {
              item.expand = false;
              item.isToday = false;
            }
            return item;
          });

          this.services = res.data.service_counts;
          this.lastMonthData = res.data.last_month;
          this.targets = res.data.target_counts;
          this.loading = false;

          // let servArr = [];
          // if(this.filter.patientType == 'Zero Clubfoot') {
          //   servArr = ['Ponseti (Plaster)', 'Ponseti (Surgery)', 'Ponseti (Tenotomy)', 'registration_fee', 'brace', 'appointment'];
          // } else {
          //   servArr = ['therapy_appointment', 'therapy_session'];
          // }

          // servArr.forEach(serv => {
          //   if(!this.services.some(item => item.product_type == serv)) {
          //     this.services.push({ product_type: serv, patient_count: 0, total_income: 0 })
          //   }
          // });
        });
    },
    centerList() {
      axios
        .get(
          `${
            process.env.VUE_APP_URL
          }/api/v1/institute/list?type=orko&api_token=${localStorage.getItem(
            "api_token"
          )}`
        )
        .then((response) => {
          if (response.data.status_code == "500") {
            this.$router.push("/access-denied");
          } else {
            // this.loading = false
            this.centers = response.data.data;
          }
        });
    },
    calculateDentalOrko(services) {
      const serviceObj = services.find((item) => item.product_type == "Dental");
      if (serviceObj) {
        return serviceObj.total_income
          ? (serviceObj.total_income * (15 / 100)).toFixed(2)
          : 0;
      }
      return 0;
      // console.log('serviceObj', serviceObj.total_income);
    },
  },
};
</script>
<style lang="scss" scoped>
.c-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #fff;
  width: 100%;
  float: left;

  .filter-list {
    width: 100%;
    float: left;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      width: 50%;
      float: left;
      padding: 0px 10px 0;
      border-right: 2px solid #002170;
      text-align: center;

      &:last-of-type {
        border-right: none;
        padding: 0;
      }

      select {
        border: none;
        outline: none;
        scroll-behavior: smooth;
        -webkit-appearance: listbox !important;

        color: #002170;
        font-size: 15px;
        font-weight: 600;
        font-family: Arial;
        padding: 0 12px 0 8px;
        height: 22px;
      }
    }
  }

  .target-content {
    width: 100%;
    float: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #00579b;
    padding: 25px;

    h2 {
      color: #44b3e6;
      font-family: Arial;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: none;
      margin-bottom: 20px;
      width: 100%;
      float: left;

      li {
        width: 100%;
        float: left;

        .l-label {
          width: 45%;
          float: left;
        }

        .r-label {
          text-align: right;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          font-family: Arial;

          .achieved {
            color: #f9a91a;
          }
        }

        label {
          color: #fff;
          font-size: 18px;
          font-family: Arial;
          font-weight: 600;
        }

        &.total {
          label {
            font-size: 20px;
          }
          .r-label {
            font-size: 20px;
            .achieved {
              color: #44b3e6;
            }
          }
          .progress-bar {
            &:not([class*="bg-success"]) {
              background-color: #44b3e6 !important;
            }
          }
        }
      }
    }
  }

  .timeline-tbl {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    float: left;
    .expanded-row {
      box-shadow: 0 0 0 1px #aaa;
      border-radius: 20px;
    }

    tr {
      th {
        color: #002147;
        border-bottom: 1px solid #a0a0a1;
        padding-bottom: 10px;
        text-align: center;
        font-size: 16px;
      }
    }

    tbody {
      tr {
        &.expanded-total-row {
          border-top: 1px solid #aaa;
          td {
            ul {
              margin: 6px 0;
              list-style-type: none;
              li {
                line-height: 1.6;
                &:last-of-type {
                  font-size: 13px;
                  span {
                    font-size: 13px;
                    i {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }
        td {
          text-align: center;
          font-size: 16px;
          line-height: 2.5;
          color: #002170;
        }

        &:first-of-type {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .summary-wrapper {
    width: 100%;
    float: left;
    background-color: #00579b;
    padding: 8px 30px;
    border-radius: 20px;
    margin-top: 30px;

    .summary-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;

      tr {
        th {
          color: #fff;
          font-size: 17px;
          padding-bottom: 5px;
        }

        td {
          font-size: 15px;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
}

.brd-15 {
  border-radius: 15px;
}

.ftb-15 {
  font-size: 15px;
  font-weight: 600;
}

.up-text {
  color: #00cd32;
  font-size: 15px;
  font-weight: 600;

  i {
    color: #00cd32;
    font-size: 13px;
  }
}

.down-text {
  color: red;
  font-size: 15px;
  font-weight: 600;

  i {
    color: red;
    font-size: 13px;
  }
}

.coll-btn {
  color: #002170;
  font-size: 15px;
  font-weight: 600;
  font-family: Arial;
  padding: 0 12px 0 8px;
  height: 22px;

  background-color: transparent;
  border: none;

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.month-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9),
    &:nth-child(13) {
      a {
        background-color: #aaaaaa;
        color: #fff;
        &.active {
          background-color: #f9a81a;
          font-weight: 600;
        }
      }
    }
  }
}
.year-selection-list {
  width: 100%;

  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  li {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      background-color: #d9d4d4;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;
      margin: 5px;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9),
    &:nth-child(13) {
      a {
        background-color: #aaaaaa;
        color: #fff;
        &.active {
          background-color: #f9a81a;
          font-weight: 600;
        }
      }
    }
  }
}
.center-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }
  }
}

.patient-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 50%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 8px 30px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }
  }
}

.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.patient-income-table {
  height: 475px;
  overflow: scroll;
  // padding: 2px;
}
</style>
