<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <div class="row mt-5 align-items-center justify-content-between">

        <div class="col-md-4">
          <label for="">From</label>
          <input type="date" v-model="filter.start_date" class="form-control">
        </div>
        <div class="col-md-4">
          <label for="">To</label>
          <input type="date" v-model="filter.end_date" class="form-control">
        </div>
        <div class="col-md-2 mt-7">
          <button class="btn btn-success" @click="getItems()">Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            <template #cell(fullname)="row">
              {{ row.item.patient ? row.item.patient.fullname : '' }}
            </template>
            <template #cell(invoice_date)="row">
              {{ row.item.invoice ? row.item.invoice.invoice_date : '' }}
            </template>
            <template #cell(product_type)="row">
              {{ row.item.invoice ? row.item.invoice.product_type : '' }}
            </template>
            <template #cell(phone_number)="row">
              {{ row.item.patient ? row.item.patient.phone_number : '' }}
            </template>
            <template #cell(invoice)="row">
              {{ row.item.invoice ? row.item.invoice.uid : '' }}
            </template>
          </b-table>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              class="my-0"
              @change="getItems"
          ></b-pagination>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ReferralPatientByEmployee",
  data() {
    return {
      loading: false,
      filter: {
        start_date: this.$route.query.start_date ? this.$route.query.start_date : null,
        end_date: this.$route.query.end_date ? this.$route.query.end_date : null,
      },
      items: [],
      fields: [
        {
          key: 'fullname',
          label: 'Fullname',
        },
        {
          key: 'phone_number',
          label: 'Phone number',
        },
        {
          key: 'product_type',
          label: 'Service type',
        },
        {
          key: 'invoice',
          label: 'Invoice ID',
        },
        {
          key: 'invoice_date',
          label: 'Invoice date',
        },
        {
          key: 'referral_amount',
          label: 'Referral amount',
        },
      ],
      total_count: 0,
      perPage: 20,
      currentPage: 1,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Referral patient by employee", route: "/employee-referral-list"},
    ]);
  },
  created() {
    this.getItems();
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
  },
  methods: {

    getItems(value) {
      this.loading = true;
      if (Number(value)) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      let url = `${process.env.VUE_APP_API_URL}/api/v2/employee/${this.$route.params.id}/referral-patients?api_token=${localStorage.getItem('api_token')}${this.filter.start_date ? '&start_date=' + this.filter.start_date : ''}${this.filter.end_date ? '&end_date=' + this.filter.end_date : ''}&limit=20&offset=${correctValue ? correctValue : 0}`

      axios.get(url).then(res => {
        this.items = res.data.data;
        this.total_count = res.data.total_count;
        this.loading = false;
      })
    },
    clear() {
      this.filter = {};
      this.getItems();
    }
  }
}
</script>

<style scoped>

</style>
