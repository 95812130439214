<template>
   <div class="mt-60">
    <QualityControlPrintPreview />
   </div>
</template>
<script>

import QualityControlPrintPreview from './QualityControlPrintPreview.vue';
export default {
    name: 'ViewQualityControls',
    components: {
        QualityControlPrintPreview,
    },
    
}
</script>