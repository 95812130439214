<template>
  <div class="card">
    <loader-component v-if="loading" />
    <div class="card-body">
      <div class="row mt-5">
        <div class="col-md-3 ">
          <label for="">From</label>
          <input type="date" v-model="filter.start_date" class="form-control">
        </div>
        <div class="col-md-3">
          <label for="">To</label>
          <input type="date" v-model="filter.end_date" class="form-control">
        </div>

        <div class="col-md-2 mt-7">
          <button
            class="btn btn-success"
            @click="getItems()"
            v-if="filter.start_date && filter.end_date"
          >
            Filter
          </button>
          <button class="btn btn-success" disabled v-else>Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
            striped
            hover
            responsive
            :items="items"
            :fields="fields"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-5"
          >
            <template #cell(patient)="row">
              <p>
                <strong>{{ row.item.patient_name }}</strong> <br />
                {{ row.item.patient_number }} <br />
                {{ row.item.patient_location }}
              </p>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              @change="getItems"
              class="my-0"
              aria-controls="doctorList"
          ></b-pagination>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ServiceFeedbacks",
  data() {
    return {
      items: [],
      fields: [
        {
          key: "patient",
          label: "Patient",
        },
        {
          key: "feedback_type",
          label: "Feedback Type",
        },
        {
          key: "rating",
          label: "Rating",
        },
        {
          key: "service_improvement",
          label: "Service Improvement",
        },
        {
          key: "comment",
          label: "Comment",
        },
        {
          key: "created_at",
          label: "Created At",
        }
      ],
      loading: false,
      total_count: 0,
      currentPage: 1,
      perPage: 20,
      filter: {
        start_date: null,
        end_date: null,
      },
    };
  },
  computed: {
    currentUser: {
      get() {
        return this.$store.state.auth.userData;
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Service Feedbacks", route: "/service/feedbacks" },
    ]);
  },
  created() {
    this.getItems();
    if(this.currentUser.is_top_executive) {
      localStorage.setItem("tabIndex", 5);
      this.$root.$emit("call-active-menu", 5);
    } else {
      localStorage.setItem("tabIndex", 4);
      this.$root.$emit("call-active-menu", 5);
    }
    
  },

  methods: {
    getItems(value) {
      this.loading = true;
      if (value) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v2/user/service/feedbacks?api_token=${localStorage.getItem(
            "api_token"
          )}${
            this.filter.start_date ? "&start_date=" + this.filter.start_date : ""
          }${this.filter.end_date ? "&end_date=" + this.filter.end_date : ""}
          &limit=${this.perPage}${correctValue ? '&offset=' + correctValue : ''}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.total_count = res.data.total_count;
          this.loading = false;
        });
    },
    clear() {
      this.filter.start_date = null;
      this.filter.end_date = null;
      this.getItems();
    },
   
  },
};
</script>

<style scoped>
table th {
  color: #002147;
  border-bottom: 0px;
  padding-bottom: 10px;
  /* text-align: center; */
  font-size: 15px;
}
table td {
  padding: 5px;
  text-transform: capitalize;
}
.custom-height {
  height: 30px !important;
}
</style>
